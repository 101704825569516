import React from "react";

interface IProps {
  value: string | number | boolean | undefined | JSX.Element;
  label?: string;
  fullWidth?: boolean;
  end?: JSX.Element;
}

export function ReviewInput({ label, value, fullWidth = false, end }: IProps) {
  let width = fullWidth ? fullWidth : (fullWidth = false);
  return (
    <div className={`${width && "col-span-full"}`}>
      {label ? (
        <span className="text-slate-600 font-semibold text-xs">{label}</span>
      ) : (
        <></>
      )}
      <div className="text-lg my-2 flex justify-between ">
        {value || "-"}
        {end ? <div>{end}</div> : <></>}
      </div>
      <div className="flex-1 h-[1.5px] bg-slate-200"></div>
    </div>
  );
}
