import { Badge, Button, Loader, Modal, SimpleGrid } from '@mantine/core'
import { GoDiffAdded } from 'react-icons/go'
import React, { useState } from 'react'
import UploadImageModal from '../../screens/UploadedImage/component/UploadImageModal'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import UploadeImage from '../../screens/ImageUploader/component/UploadeImage'
import { showToast } from '../../utils/utility'
import { GrAdd } from 'react-icons/gr'
import { EButton } from '../../libs/ui'
import useCarouselImageUploader from './hooks/useCarouselImageUploader'
import { API_URL } from '../../utils/constants'

export default function CarouselImageUploader() {

    const {
        carouselImages,
        setCarouselImages,
        opened,
        setOpened,
        selectedFile,
        setSelectedFile,
        handleDelete,
        getMeta,
        handelAddCarouselImage,
        isUploading
    } = useCarouselImageUploader()
    return (
        <div className='p-8'>
            <h2 className='text-center p-3'>Add carousel image from here..</h2>
            <div className='flex flex-wrap'>
                {carouselImages.map((e, index) =>
                    <div className='carousel-listing-container'>
                        <div className='carousel-list-item overflow-hidden h-[462px] w-[275px]'>
                            <div className="overlay">
                                <EButton
                                    className="btn-outline-red"
                                    onClick={() => handleDelete(e?.directus_files_id?.id, index)}
                                >
                                    Remove This Image
                                </EButton>
                            </div>
                            <div className=''>
                                <img
                                    className='image'
                                    src={`${API_URL}assets/${e?.directus_files_id?.id}`}
                                    alt="" />
                            </div>
                        </div>
                    </div>
                )}
                {carouselImages.length !== 5 &&
                    <div className='bg-gray-100 h-[462px] m-2 w-[290px] rounded-lg shadow-md flex items-center justify-center'
                    >
                        {<GoDiffAdded
                            size={60}
                            className='text-gray-300 cursor-pointer'
                            onClick={() => {
                                setOpened(true)
                            }}
                        />}
                    </div>}
            </div>

            <Modal
                opened={opened}
                onClose={() => {
                    setOpened(false);
                }}
                centered
                size={860}
                // overlayBlur={6}
                withCloseButton={false}
            >
                <div className='flex'>
                    <div className='image-modal'>
                        {selectedFile === undefined ?
                            <div className=" flex items-center justify-center bg-slate-100 border-2 h-[652px]  ">
                                <h5 className="text-black opacity-30"> Image Preview</h5>
                            </div>
                            : <div>
                                <img src={URL.createObjectURL(selectedFile)} className='' alt="Thumb" />
                            </div>
                        }
                    </div>
                    <div className='px-5 flex-1 flex flex-col justify-between'>
                        <div>{
                            isUploading ?
                                <div className='flex justify-center mt-16'>
                                    <Loader />
                                </div>
                                :
                                <UploadeImage onSelect={(file) => {
                                    // setSelectedFile(file);
                                    getMeta(URL.createObjectURL(file), file);

                                }}
                                />}
                        </div>
                        <div className="flex gap-2 justify-end">
                            <EButton
                                className="btn-outline-blue"
                                onClick={() => {
                                    setSelectedFile(undefined)
                                    setOpened(false)
                                }}
                            >
                                Cancel
                            </EButton>
                            <EButton
                                className="btn-blue"
                                // loading={isUploading}
                                disabled={!selectedFile}
                                onClick={() => {
                                    selectedFile &&
                                        handelAddCarouselImage()
                                    // setCarouselImages([...carouselImages, selectedFile])
                                    setSelectedFile(undefined)
                                    // handleClick();
                                }}
                            >
                                Add <GrAdd className='ml-2 text-red-600' />
                            </EButton>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
