import { Badge, Button } from "@mantine/core";
import moment from "moment";
import { useContext } from "react";
import newImg from "../assets/news_background.png";
import { API_URL } from "../utils/constants";
import AppContext from "../utils/context/AppContext";
import { InewsDetail, TypeUser } from "../utils/types";
type Props = {
  data: InewsDetail;
  status: string;
  onClick?: () => void;
  createdBy: String;
  user: any;
  openDetailModal?: () => void;
};

const NewsItem = ({
  data,
  status,
  onClick,
  user,
  createdBy,
  openDetailModal,
}: Props) => {
  return (
    <div className="news-list-item">
      <div className="overlay">
        {
        status === "approved" ? (
          <></>
        ) :
         user?.id === createdBy || user?.role === "editor" ? (
          <Button
            className="text-white hover:text-blue-600"
            variant="default"
            onClick={openDetailModal}
          >
            Edit This News
          </Button>
        ) : (
          <></>
        )}
        <Button className="bg-blue-600" variant="filled" onClick={onClick}>
          Update Status
        </Button>
      </div>
      <div className="">
        <img
          className="image"
          src={`${API_URL}assets/${data?.urlToImage}`}
          alt=""
        />
      </div>
      <div className="body">
        <div className="-mt-7 z-0">
          <Badge
            variant="filled"
            size="lg"
            className={`${
              status === "approved"
                ? "bg-green-600"
                : status === "pending"
                ? "bg-amber-500"
                : "bg-red-600"
            } ring-4 ring-white`}
          >
            {status}
          </Badge>
        </div>

        <div className="">
          <small className="text-xs opacity-40 font-medium">
            Created on{" "}
            {data?.publishedAt &&
              moment(data?.publishedAt).format("DD/MM/YYYY")}
          </small>
          <div className="text-lg font-semibold">{data?.title}</div>
        </div>
      </div>
      {/* <div
        style={{
          backgroundImage: `url(${API_URL}assets/${data?.urlToImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
        onClick={onClick}
      > 
      </div> */}
    </div>
  );
};

export default NewsItem;
