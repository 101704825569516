import { Card } from '@mantine/core'
import React from 'react'
import { MdDeleteForever } from 'react-icons/md'
type IProps = {
    label: string;
    status: string;
    handleDelete: () => void;
}
export default function OptionsCard({ label, status, handleDelete }: IProps) {
    return (
        <Card className='w-[49%]' shadow='xs'>
            <div className='flex pb-3 justify-between items-center'>
                <p className='text-md'>Status : <span className='text-slate-400'>{status}</span></p>
                {<MdDeleteForever
                    size={28}
                    color='red'
                    className='cursor-pointer'
                    onClick={() => handleDelete()} />}
            </div>
             
            <p>Option Text :  <span className='text-slate-500'>{label}</span></p>
        </Card>
    )
}
