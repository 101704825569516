import React, { useState } from 'react'
import { EButton, EDrawer, EReview } from '../../libs/ui'
import { Button, Container, Grid, Select, Table, SegmentedControl, Center, Box } from '@mantine/core';
import AddNewSurvey from './components/AddNewSurveyDetails';
import useSurvey from './hooks/useSurvey';
import AddSurveyQuestion from './components/AddSurveyQuestion';
import AddSurveyQuestionOptions from './components/AddSurveyQuestionOptions';
import OptionsCard from '../../libs/ui/src/Cards/OptionsCard';
import { BiListOl } from 'react-icons/bi';
import { BsFillCircleFill } from 'react-icons/bs';
import { title } from 'process';
import SurveyCard from '../../libs/ui/src/Cards/SurveyCard';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { MdDrafts, MdPublish } from 'react-icons/md';
import ButtonGroup from '../../components/ButtonGroup';

export default function Survey() {
  const navigate = useNavigate()
  const {
    surveyList,
    setSurveyStatus,
    isOpenDrawer,
    setIsOpenDrawer,
    selectedSurvey,
    setSelectedSurvey
  } = useSurvey()
  return (
    <Container fluid className="py-10">
      <div className="flex mb-6 items-center justify-between">
        <div className="">
          <h1 className="">Survey List</h1>
          <p className="opacity-60">20 items found</p>
        </div>

        <div className="flex justify-end">
          <EButton
            onClick={() => navigate('/survey/newSurvey')}
          >+ Add New Survey</EButton>
        </div>
      </div>
      <ButtonGroup
          label=""
          options={["published","archived"]}
          onClick={(i: any) => setSurveyStatus(i)}
        />
      <div className='flex mt-8 gap-4 flex-wrap'>
        {
          surveyList.length ?
            surveyList.map((each) =>
              <SurveyCard
                description={each?.description || ""}
                id={each?.id}
                status={each?.status}
                title={each?.title}
                dateCreated={moment(each?.date_created).format("DD-MM-YYYY")}
                startDate={each?.start_date}
                dueDate={each?.due_date}
                userName={each?.user_created?.first_name + " " + (each?.user_created?.last_name || "")}
                onClick={(id)=>{
                  
                  setIsOpenDrawer(true)
                }}
                />
              ) :
            <div className='flex flex-col justify-center items-center'>
              <img src={require('../../assets/noData.png')} className=' w-1/2 h-3/4 opacity-30' alt='no data' />
              <p className='-mt-7'>No any survey found</p>
            </div>}
      </div>

      <EDrawer
       opened={isOpenDrawer}
        title='123'
        position='right'
        onClose={()=>setIsOpenDrawer(!isOpenDrawer)}
        drawerContent={<div></div>}/>
    </Container>
  )
}
