import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';

import { INewsTimeline } from '../../../utils/types';
import { showToast } from '../../../utils/utility';
import { changeStatus, deleteNewsById, getNewsTimelineStatus, UploadNewsTimelineStatus } from '../../../utils/api/api';

export default function useNewsDeatilDrawer() {

    const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
    const [opened, setOpened] = useState(false);
    const [isCheckedMobilePreview,setIsCheckedMobilePreview ] = useState(false);
    const [approvedOrReject, setApprovedOrReject] = useState<string>("");
    const [comment, setComment] = useState<string[]>([]);
    const [textArea, setTextArea] = useState<string>("");
    const [check, setCheck] = useState<boolean>(false)

    const [timeline, setTimeline] = useState<INewsTimeline[]>([]);
    const [approvedByInfo, setApprovedByInfo] = useState<INewsTimeline | undefined>(undefined);

    const [selectedStatus, setSelectedStatus] = useState<string>("");
    const { t } = useTranslation();

    // HANDLER FUNCTIONS
    const handleChange = (e: any) => {
        setTextArea(e.target.value);
        // console.log("onchange", e.target.value);
    };

    const handleCommentsAndFeedback = () => {
        setComment(prev => [...prev, textArea]);
        setTextArea('');
    }

    // API CALLS
    const handleUpdateStatus = async (id: number, comment: string, handleClick: () => void, NewsStatus: string) => {
        if (NewsStatus === approvedOrReject && approvedOrReject === "rejected") {
            showToast({
                type: 'warning',
                title: "Status Warning",
                message: "News status is already Rejected "
            })
        }
        // else if(approvedOrReject===""){
        //     showToast({
        //         type: 'warning',
        //         title: "Status Warning",
        //         message: "Please select approve or reject status"

        //     })
        // }
        else {
            await changeStatus(id, approvedOrReject).then((res) => {
                showToast({
                    message: `${t("News status has been changed to")} ${approvedOrReject} `,
                    type: "success",
                });
                setOpened(false)
                handleClick()
                setSelectedStatus(approvedOrReject)
                UploadNewsTimelineStatus({
                    "news": id,
                    "comment": comment,
                    "status": approvedOrReject
                });
                handleGetTimelineData(String(id));
            })
                .catch(error => {
                    console.log("error approving news", error);
                    showToast({
                        title: t("Cannot approve news"),
                        message: t("Try again later."),
                        type: "error",
                    });
                })
        }
    };

    const handleDeleteNews = (id: string, handleClick: () => void) => {
        deleteNewsById(Number(id))
            .then((res) => {
                showToast({
                    type: "success",
                    title: "News Deleted",
                    message: "News has been deleted successful",
                });
                // refresh page after delete 
                handleClick()
            })
            .catch((err) => {
                showToast({
                    type: "error",
                    title: "Something went wrong",
                    message: "could not delete the selected News",
                });
            });
    };

    const handleGetTimelineData = (id: string) => {
        if (id) {
            getNewsTimelineStatus(+id).then((res) => {
                const timeline: INewsTimeline[] = res.data?.data || [];
                setTimeline(timeline);
                const approvedBy = timeline?.find((each) =>
                    each.status === "approved"
                );
                setApprovedByInfo(approvedBy);
            }, error => {
                showToast({
                    type: "error",
                    title: "Something Went Wrong!",
                    message: "Could not fet h timeline for this news"
                });
            })
        }
        // else {
        //     showToast({
        //         type: "warning",
        //         message: "News could not be found to search timeline!"
        //     });
        // }
    }

    return {
        isFirstLoad,
        setIsFirstLoad,
        opened,
        setOpened,
        check,
        setCheck,
        handleUpdateStatus,
        handleChange,
        approvedOrReject,
        setApprovedOrReject,
        comment,
        handleDeleteNews,
        setComment,
        handleGetTimelineData,
        timeline,
        handleCommentsAndFeedback,
        textArea,
        setTextArea,
        approvedByInfo,
        isCheckedMobilePreview,
        setIsCheckedMobilePreview
    }
}
