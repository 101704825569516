import React, { useContext, useEffect, useState } from 'react'
import { showToast } from '../../../utils/utility';
import { uploadFile, deleteImage } from '../../../utils/api/api';
import axios from 'axios';
import { AddNewsContext } from '../../../utils/context/AddNewsContext';
import { ICarouselImage } from '../../../utils/types';
import { EditNewsContext } from '../../../utils/context/EditNewsContext';


export default function useCArouselImageEditor() {

    const {
        updateEditNewsInfo,
        newsType,
        carouselImage,
        carouselImageToCreate,
        carouselExistingImage,
        carouselImageToDelete
    } = useContext(EditNewsContext);

    const [carouselImages, setCarouselImages] = useState<ICarouselImage>([]);
    const [opened, setOpened] = useState<boolean>(false);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    // const [selectedIndex, setSelectedIndex] = useState<number>(-1);
    const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);



    //   useEffect(()=>{
    //         updateEditNewsInfo('carouselImage',carouselImages)
    //   },[carouselImages])


    const handelAddCarouselImage = async () => {
        if (selectedFile) {
            setIsUploading(true);
            const res = await uploadFile(selectedFile).catch((err) => {
                showToast({
                    type: "warning",
                    title: "Image not uploaded!",
                    message: "",
                })
            })
            if (res?.data) {
                const newImageId = res?.data?.data?.id
                updateEditNewsInfo('carouselImageToCreate', [...carouselImageToCreate, newImageId])
                carouselImage?.push(newImageId)
                //   updateEditNewsInfo('carouselImage',)
                showToast({
                    type: 'success',
                    title: "Image added successfully",
                    message: "",
                })
                setSelectedFile(undefined)
                setOpened(false)
            }
        }
        setIsUploading(false);
    }

    const handleDelete = async (item: any, selectedIndex: number) => {

        //  delete remove from on singel click it create issu
        if (!carouselExistingImage?.filter(allItems => allItems === item).length) {
            const res = await deleteImage(item).catch((err) => {
                showToast({
                    type: 'warning',
                    title: "Image not deleted",
                    message: "Please try again",
                })
            })
            if (res) {
                //   setCarouselImages(prevImages => prevImages.filter(image => image.directus_files_id.id !== item));
                updateEditNewsInfo('carouselImage', carouselImage?.filter(allItems => allItems !== item))
                updateEditNewsInfo('carouselImageToCreate', carouselImageToCreate?.filter(allItems => allItems !== item))
                
                showToast({
                    type: 'success',
                    title: "Image deleted successfully",
                    message: "",
                })
            }
        }
        if (carouselExistingImage?.filter(allItems => allItems == item).length) {
            updateEditNewsInfo('carouselImageToDelete', [...carouselImageToDelete, item])
            updateEditNewsInfo('carouselImage', carouselImage?.filter(allItems => allItems !== item))
            updateEditNewsInfo('carouselImageToCreate', carouselImageToCreate?.filter(allItems => allItems !== item))
        }

    }

    const getMeta = (url: string, file: any) => {
        const img = new Image();
        img.addEventListener("load", function () {
            // alert( this.naturalWidth +'hfhryry'+ this.naturalHeight );
            if (this.naturalWidth === 720 && this.naturalHeight === 1125) {
                setSelectedFile(file);
            } else {
                showToast({
                    type: "warning",
                    title: "Image can't be upload!",
                    message: "Please select image of 720px & 1125px!",
                })
            }
        });
        img.src = url;
    }
    return {
        carouselImages,
        setCarouselImages,
        opened,
        setOpened,
        selectedFile,
        setSelectedFile,
        handleDelete,
        getMeta,
        handelAddCarouselImage,
        isUploading
    }
}

