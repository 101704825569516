import { Button, Container, Grid, Table } from '@mantine/core'
import React from 'react'
import useCreateNewsSurvey from './hooks/useCreateNewsSurvey'
import AddSurveyQuestionOptions from './components/AddSurveyQuestionOptions'
import AddNewSurvey from './components/AddNewSurveyDetails';
import { BsFillCircleFill } from 'react-icons/bs';

export default function CreateNewSurvey() {
 
    const {
        isSurveyCreating,
        setIsSurveyCreating,
        newSurvey,
        setNewSurvey,
        handleSubmitSurvey,
      } =useCreateNewsSurvey()

  return (
    <Container fluid className="py-10">
      <Grid >
        <Grid.Col span={6} >
          <div className="flex items- justify-between">
            <div className="">
              <h1 className="">Add New Survey</h1>
              {/* <p className="opacity-60">{"List of Survey created by you"}</p> */}
            </div>
            <Button
              disabled={newSurvey?.title && newSurvey?.start_date && newSurvey?.due_date && newSurvey?.question_list.length ? false : true}
              className='bg-blue-400 mr-10'
              onClick={() => handleSubmitSurvey()}
            >Submit Survey</Button>
          </div>
          <div>
            {/* <div className={`${!isSurveyCreating ? "hidden" : ""}`}> */}
            <div>
              <AddNewSurvey handleSurveyDetail={(e) =>
                setNewSurvey(
                  {
                    ...newSurvey,
                    title: e?.title,
                    description: e?.description,
                    status: e?.status,
                    start_date: e?.start_date,
                    due_date: e?.due_date
                  })}
              />
              <AddSurveyQuestionOptions
                handleSubmitQuestion={(e) => setNewSurvey({ ...newSurvey, question_list: [e] })}
              />
            </div>
          </div>
          <div className='flex gap-3'>
            {/* <EReview
          size='xl'
          question={'Question for the review'}
          options={[{ option: 'Option 1', value: 33 }, { option: 'Option 2', value: 67 }, { option: 'option 3', value: 55 }, { option: 'Option 4', value: 42 }]} />
        <EReview
          size="xl"
          question={'Question for the review'}
          options={[{ option: 'Option 1', value: 33 }, { option: 'Option 2', value: 67 }, { option: 'option 3', value: 55 }, { option: 'Option 4', value: 42 }]} /> */}
          </div>
        </Grid.Col>
        <Grid.Col span={6} >
          {/* <EReview
          size='2xl'
          question={'Question for the review'}
          options={[{ option: 'Option 1', value: 33 }, { option: 'Option 2', value: 67 }, { option: 'option 3', value: 55 }, { option: 'Option 4', value: 42 }]} />
         */}
          <h2 className='mt-4'>Question List </h2>
          {/* <span>2 items found</span> */}
          {
           newSurvey?.question_list.length ?
            <div className='flex flex-wrap gap-4 px-3 justify-between'>
              <Table>
                <tr>
                  {newSurvey?.question_list.map((e, index) => (
                    <>
                      <tr className='text-xl font-semibold'> {index + 1}.  {e?.question_text}</tr>
                      {e?.option_list.map((list, index) => <tr ><div className='flex items-center gap-3 '> {<BsFillCircleFill size={10} />} {list?.option_text} </div></tr>)}
                    </>
                  ))}
                </tr>
              </Table>
            </div>
            :
            <div className='flex flex-col text-slate-300 mt-4 items-center justify-center'>
              <p> No any Question </p>
              <p> Please add question for survey</p>
              </div>
          }
        </Grid.Col>
      </Grid>
    </Container>
  )
}

