import { Badge } from '@mantine/core'
import React from 'react'
import { ISurveyList } from '../../../../utils/types'
import moment from 'moment'
type IProps = {
  id: number,
  title: string
  dateCreated:string
  status: string
  startDate: string
  dueDate: string
  userName:string 
  description?: string
  onClick:(e:number)=>void
}

export default function SurveyCard({id,title,description,status,dateCreated,startDate,dueDate,userName,onClick}:IProps) {
  return (
<div 
className='shadow-sm cursor-pointer rounded-lg border bg-white w-[48%] p-6 flex flex-col gap-4' 
onClick={()=> onClick(id)}
  >
  <div className='flex items-center justify-between'>
    {/* <p className='text-2xl font-bold text-indigo-400'>ID: {id}</p> */}
      <p className='text-2xl font-semibold capitalize text-indigo-700'>{title}</p>
    <Badge color='green' size='lg'>{status}</Badge>
  </div>
    <div>
      {description &&
      <p className='text-gray-600'><span className='text-lg font-semibold text-gray-800'>Description:</span> {description}</p>
      }</div>
  <div className='grid grid-cols-2 gap-2 '>
      <p className='text-gray-600'><span className='text-lg font-semibold text-gray-800'>Created by: </span>{userName}</p>
      <p className='text-lg font-semibold text-gray-800'>Start Date: <span className='text-gray-600 text-base'>{moment(startDate).format("DD-MM-YYYY")}</span></p>
      <p className='text-lg font-semibold text-gray-800'>Date Created:  <span className='text-gray-600 text-base'>{dateCreated}</span></p>
      <p className='text-lg font-semibold text-gray-800'>Due Date: <span className='text-gray-600 text-base'>{moment(dueDate).format("DD-MM-YYYY")}</span></p> 
  </div>
</div>
  )
}
