import axios from "axios";
import { INewSurvey, INewTagToBeAdded, TypePostAddNews } from "../types";

export const getUserRole = () => {
  return axios.get(
    "users/me?fields=id,first_name,last_name,email,role.id,role.name"
  );
};
export const getNewsFilterByCreator = (id: string) => {
  return axios.get(
    `items/news?filter[created_by][_eq]=${id}&sort=-publishedAt&limit=-1`
  );
};
export const getNewsList = () => {
  return axios.get(`items/news?sort=-publishedAt&limit=-1`);
};

export const getNewsByNewsID = (id: number) => {
  return axios.get(`items/news?&filter[id][_eq]=${id}&?single=1&fields=*.*`);
};

export const getImagesByUploader = (limit:number,page:number) => {
  return axios.get(
    `/files?sort=-uploaded_on&limit=${limit}&offset=${(page-1)*limit}&meta=filter_count&filter[tags][_nnull]=true&fields=id,storage,filename_disk,filename_download,title,type,folder,uploaded_on,modified_on,charset,filesize,width,height,duration,embed,description,location,tags,metadata,uploaded_by.id,uploaded_by.first_name,uploaded_by.last_name,modified_by.id,modified_by.first_name,modified_by.last_name,image_news_url`
  );
};
export const getImageList = (
  page: number,
  limit: number,
  query?: string
) => {
  let url = `/files?sort=-uploaded_on&limit=${limit}&offset=${(page-1)*limit}&meta=filter_count&filter[tags][_nnull]=true&fields=id,storage,filename_disk,filename_download,title,type,folder,uploaded_on,modified_on,charset,filesize,width,height,duration,embed,description,location,tags,metadata,uploaded_by.id,uploaded_by.first_name,uploaded_by.last_name,modified_by.id,modified_by.first_name,modified_by.last_name,image_news_url`;
  if (query) {
    url += `&filter[tags][_contains]=${query}`;
  };
  return axios.get(url);
};
export const getAllCategories = () => {
  return axios.get("items/category?limit=-1");
};
export const getAllProvider = () => {
  return axios.get("items/providers?fields=*.*&limit=-1");
};

export const getAllLanguage = () => {
  return axios.get("items/languages")
}
export const uploadFile = (file: File | Blob) => {
  const formData = new FormData();
  formData.append("file", file);
  return axios.post("files", formData);
};

export const draftNews = (body: TypePostAddNews) => {
  return axios.post("items/news", body);
};

export const PatchNews = (id: number, body: TypePostAddNews) => {
  return axios.patch(`items/news/${id}`, body);
};

export const addFeedback = (body: any) => {
  return axios.post("items/news_feedback", body);
};
export const getNewsFeedBackByNewsId = (id: number) => {
  return axios.get(
    `/items/news_feedback?filter[news_id][_eq]=${id}&sort[]=-date_created&fields=date_created,text,user_created.id,user_created.first_name,user_created.last_name,user_created.email,user_created.role.name&limit=-1`
  );
};

export const feedbackReply = (body: any) => {
  return axios.post("items/news_feedback", body);
};
export const changeStatus = (id: number, status: string) => {
  return axios.patch(`items/news/${id}`, { status: status });
};
export const getNewsByPandingStatus = (status: string) => {
  return axios.get(`items/news?filter[status][_eq]=${status}`);
};
export const addTagsToAnImage = (id: string, body: any) => {
  let tags = {
    "tags": body
  }
  return axios.patch(`files/${id}`, tags);
}

export const getAllNews = (
  language: number,
  status: string,
  limit: number,
  page: number
) => {

  // console.group("");
  // console.log("status: ", status);
  // console.log("page: ", page);
  // console.log("limit: ", limit);
  // console.groupEnd();

  if (status === "all") {
    return axios.get(`/items/news?limit=${limit}&offset=${(page - 1) * limit}&meta=filter_count&fields=*,categories.category_id.*,provider_id.*,carousel_image.*,created_by.*,created_by.role.name&sort=-publishedAt&filter[language][_eq]=${language}`); // &filter[created_by][_eq]=${id}
  } else {
    return axios.get(`/items/news?limit=${limit}&offset=${(page - 1) * limit}&meta=filter_count&fields=*,categories.category_id.*,provider_id.*,carousel_image.*,created_by.*,created_by.role.name&sort=-publishedAt&filter[language][_eq]=${language}&filter[status][_eq]=${status}`); // &filter[created_by][_eq]=${id}
  };

};

export const getNewsByCreatorId = (
  language: number,
  status: string,
  id: string,
  limit: number,
  page: number
) => {

  if (status === "all") {
    return axios.get(`/items/news?limit=${limit}&offset=${(page - 1) * limit}&meta=filter_count&fields=*,categories.category_id.*,provider_id.*,carousel_image.*,created_by.*,created_by.role.name&sort=-publishedAt&filter[language][_eq]=${language}&filter[created_by][_eq]=${id}`); // &filter[created_by][_eq]=${id}
  } else {
    return axios.get(`/items/news?limit=${limit}&offset=${(page - 1) * limit}&meta=filter_count&fields=*,categories.category_id.*,provider_id.*,carousel_image.*,created_by.*,created_by.role.name&sort=-publishedAt&filter[language][_eq]=${language}&filter[status][_eq]=${status}&filter[created_by][_eq]=${id}`);
  };

};

export const getNewsTags = () => {
  return axios.get(`items/tags?fields=name&limit=-1&sort=date_created`);
}

export const addTags = (id: string, body: string[]) => {
  return axios.patch(`files/${id}`, {
    tags: body,
  });
};

export const UploadNewsUrlToImage = (id: string, newsUrl: string) => {
  return axios.patch(`files/${id}`, {
    image_news_url: newsUrl
  })
}
export const uploadNewTags = (tagList: INewTagToBeAdded[]) => {
  return axios.post("items/tags", tagList);
};

export const getSelectedNewsDetails = (id: number) => {
  return axios.get(`items/news/${id}`);
};

export const getBadgedataCount = (status: string, language: number) => {
  if (status === "all") {
    return axios.get(`items/news?&filter[language][_eq]=${language}&aggregate[count]=*`)
  }
  else {
    return axios.get(`items/news?&filter[language][_eq]=${language}&filter[status][_eq]=${status}&aggregate[count]=*`)
  }
}

export const getBadgedataCountForCreatorID = (status: string, language: number, id: string) => {
  if (status === "all") {
    return axios.get(`items/news?&filter[created_by][_eq]=${id}&filter[language][_eq]=${language}&aggregate[count]=*`)
  }
  else {
    return axios.get(`items/news?&filter[created_by][_eq]=${id}&filter[language][_eq]=${language}&filter[status][_eq]=${status}&aggregate[count]=*`)
  }
}

export const deleteNewsById = (id: number) => {
  return axios.delete(`items/news/${id}`)
}
export const deleteImage = (id: string) => {
  return axios.delete(`files/${id}`)
}

export const getPsdTemplate = () => {
  return axios.get(`files/d2505850-0e0d-4b20-b876-81b5b1faf361`, { responseType: 'blob' })
}

export const UploadNewsTimelineStatus = (body: any) => {
  return axios.post(`items/news_timeline`, body)
}

export const getNewsTimelineStatus = (news: number) => {
  return axios.get(`items/news_timeline?filter[news][_eq]=${news}&sort=-date_created&fields=id,news,user_created.first_name,user_created.last_name,user_created.role.name,status,date_created,comment`)
}


// Survey API

export const postSurvey = (body: INewSurvey) => {
  return axios.post("custom/survey/create-survey", body)
}

export const fetchSurveyForUser = (userId: string) => {
  return axios.get(`custom/survey/basedOn/UserId?UserId=${userId}`)
}
export const fetchSurvey = (userId: string, status: string) => {
  return axios.get(`items/survey?limit=25&fields[]=id,status,date_created,date_updated,title,description,start_date,due_date,user_created.id,user_created.first_name,user_created.last_name&sort[]=start_date&filter[status][_eq]=${status}`)
}
// custom/survey/basedOn/UserId?UserId=2fc36392-13f9-4554-b812-0e1100f25180