import React, { useState } from 'react'
import { IAddNewSurvey, INewQuestionOptions, IQuestionOption } from '../../../utils/types'
import { Button, Card, Group, Radio, Select, Table, TextInput } from '@mantine/core'
import { EButton, EModal, StatCard } from '../../../libs/ui'
import { MdAdd, MdDeleteForever } from 'react-icons/md'
import OptionsCard from '../../../libs/ui/src/Cards/OptionsCard'
import useAddSurveyQuestionOptions from './hooks/useAddSurveyQuestionOptions'
type IProps = {

    handleSubmitQuestion: (e: INewQuestionOptions) => void
}
export default function AddSurveyQuestionOptions({ handleSubmitQuestion }: IProps) {

    const { isOptionModalOpen,
        setIsOptionModalOpen,
        newOption,
        setNewOption,
        handleAddOption,
        handleRemoveOption,
        newQuestionOptions,
        setNewQuestionOptions
    } = useAddSurveyQuestionOptions()


    return (
        <div className='mt-8 border-t p-2 bg-red-50'>
            <div className='flex justify-between '>
                <h2> New Question</h2>
                {/* <EButton
             onClick={()=>{}}>
                Add New Question
            </EButton> */}
            </div>
            <TextInput
                label="Question"
                withAsterisk
                value={newQuestionOptions?.question_text || ""}
                onChange={(e) => setNewQuestionOptions({ ...newQuestionOptions, question_text: e?.target?.value })}
            />
            <div className='flex gap-5'>
                <Select
                    label='Question Status'
                    className='w-1/2'
                    data={["published", "draft", "archived"]}
                    value={newQuestionOptions?.status}
                    onChange={(e) => e && setNewQuestionOptions({ ...newQuestionOptions, status: e })}
                />

                <Radio.Group
                    label={"Option Type"}
                    className='mt-2'
                    value={newQuestionOptions?.option_type}
                    onChange={(e) => setNewQuestionOptions({ ...newQuestionOptions, option_type: e })}
                >
                    <Group >
                        <Radio
                            name='optionType'
                            value={"multiple_choice"}
                            label="Multiple Choice"
                        />
                        <Radio
                            name='optionType'
                            label="Single Choice"
                            value={"single_choice"}
                        />
                    </Group>
                </Radio.Group>
            </div>

            <div className='mt-8'>
                <h2> Options <span className='text-xs'>( Please add al least 2 options )</span></h2>
                {newQuestionOptions?.option_list
                    &&
                    <div className='flex flex-wrap gap-3 justify-between'>
                        {newQuestionOptions?.option_list.map((e) =>
                            <OptionsCard
                                status={e?.status}
                                label={e?.option_text}
                                handleDelete={() => handleRemoveOption(e)} />
                        )}

                        {newQuestionOptions?.option_list?.length !== 4 &&
                            <Card
                                shadow='xs'
                                className=' flex w-[49%] h-28 justify-center items-center' >
                                <div
                                    className='cursor-pointer flex flex-col items-center'
                                    onClick={() => setIsOptionModalOpen(true)}
                                    
                                    >
                                    <MdAdd
                                        size={32}
                                    />
                                    <p>Add New Option</p>
                                </div>
                            </Card>
                        } 
                    </div>
                }
            <div className='flex border-b pb-2 justify-end mt-10'>
                <EButton
                 disabled={newQuestionOptions?.option_list.length <= 1 || !newQuestionOptions?.question_text ? true :false}
                    onClick={() => {
                        handleSubmitQuestion(newQuestionOptions)
                    }}>
                    + Add Question
                </EButton>
            </div>
            </div>


            <EModal
                isOpen={isOptionModalOpen}
                setIsOpen={() => setIsOptionModalOpen(!isOptionModalOpen)}
                size='xl'
                title='Add New Option'
                children={
                    <div>
                        <TextInput
                            label="Option"
                            withAsterisk
                            value={newOption?.option_text || ""}
                            autoFocus
                            onChange={(e) => setNewOption({ ...newOption, option_text: e?.target?.value })}
                        />
                        <div className='mt-3 p-2'>
                            <div className='flex items-center gap-4 '>
                                <Radio.Group
                                    label='Option Status'
                                    value={newOption?.status}
                                    onChange={(e) => setNewOption({ ...newOption, status: e })}
                                >
                                    <Group >
                                        <Radio
                                            name='status'
                                            value={"published"}
                                            label="Published"
                                        />
                                        <Radio
                                            name='status'
                                            label="Draft"
                                            value={"draft"}
                                        />
                                        <Radio
                                            name='status'
                                            label="Archived"
                                            value={"archived"}
                                        />
                                    </Group>
                                </Radio.Group>
                            </div>
                        </div>
                        <div className='flex justify-end'>
                            <Button
                                className="bg-blue-500"
                                disabled={!newOption?.status || !newOption?.option_text}
                                onClick={() => handleAddOption()}>
                                Add Option
                            </Button>
                        </div>
                    </div>
                } />
        </div>
    )
}
