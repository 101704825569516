import React, { useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "../screens/Login/Login";
import { LoginContext } from "../utils/context/AppContext";
import { ROUTES } from "./routes";

export default function MainRoutes() {
  const { user, isLoggedIn } = useContext(LoginContext);

  return (
    <Routes>
      {isLoggedIn ? (
        <>
          {user?.role === "creator" ? (
            <>
              {ROUTES.CREATOR_ROUTES.map((each, index) => (
                <Route
                  key={index + "cc"}
                  path={each?.path}
                  element={<each.element />}
                />
              ))}
              <Route path="*" element={<Navigate to={"/new"} replace />} />
            </>
          ) : user?.role === "editor" ? (
            <>
              {ROUTES.EDITOR_ROUTES.map((each, index) => (
                <Route
                  key={index + "ed"}
                  path={each?.path}
                  element={<each.element />}
                />
              ))}
              <Route path="*" element={<Navigate to={"/newsList"} replace />} />
            </>
          ) : user?.role === "uploader" ? (
            <>
              {ROUTES.UPLOADER_ROUTES.map((each, index) => (
                <Route
                  key={index + "up"}
                  path={each?.path}
                  element={<each.element />}
                />
              ))}
              <Route
                path="*"
                element={<Navigate to={"/uploaded-image"} replace />}
              />
            </>
          ) : (
            <Route path="/login" element={<Login />} />
          )}
        </>
      ) : (
        <Route path="/login" element={<Login />} />
      )}
    </Routes>
  );
}
