import { Button, Divider, FileButton, FileInput } from "@mantine/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { BsImage, BsImageFill, BsUpload } from "react-icons/bs";
import { FiFilePlus, FiUpload } from "react-icons/fi";
import { ImImage } from "react-icons/im";
import { IoIosClose, IoMdClose } from "react-icons/io";
import { getPsdTemplate } from "../../../utils/api/api";
import { handleFormatUploadedImage, showToast } from "../../../utils/utility";

type TypeUploadImage = {
  onSelect: (file: File) => void;
  carouselNews? :boolean
};

const psdTemplateDownload = () => {
  getPsdTemplate()
      .then((response) => {
          // console.log("data fetched from psd",response?.data)
          var a = document.createElement("a");
          a.href = window.URL.createObjectURL(new Blob([response?.data]));
          a.download = "PSD_Template.psd";
          a.click();
      }).catch(
          error => {
              console.log("Error for Psd Template", error);
              showToast({
                  type: "error",
                  title:"Something Went Wrong!",
                  message: "Could not Download PSD Template"
              });
          }
      )
}


export default function UploadeImage({ onSelect, carouselNews }: TypeUploadImage) {
  const { t } = useTranslation();
  return (
    <>
      <h2 className="mb-3 flex items-center gap-2">
        <FiUpload /> {t("Upload Image")}
      </h2>
      <p className="mb-4 opacity-60">
        {carouselNews ?  "Please select image to carousel news" :t(
          "Please select image to upload and enter relevant tags. You can add more than one tag. "
        )}
      </p>
      <div className="mb-8">
        <Button variant="subtle" leftIcon={<FiFilePlus />}
        onClick={() => psdTemplateDownload()}
        >
          Download PSD Template
        </Button>
      </div>

      {/* <Divider /> */}
      <div className="bg-blue-50 border-2 border-blue-500 border-dashed rounded-xl p-8 flex flex-col items-center justify-center">
        <BsImageFill size={48} className="text-blue-800" />
        

        <FileButton
          onChange={(files) => { files && onSelect(files)
          }}
          accept="image/jpeg"
        >
          
          {(props) => (

            <Button
            variant="subtle"
              size="md"
              // className="text-blue-600 hover:bg-blue-100 my-2"
              {...props}
            
            >
              {t("Browse file to upload")}
            </Button>
          )}
        </FileButton>
        <p className="text-xs leading-5 text-center px-5 opacity-50">
          {t(
            "You can only upload one file at a time. Image must be in JPG format and 720px by 1125px."
          )}
        </p>

        {/* <div className="flex flex-col items-center justify-center gap-1">
          <p className="font-medium">filename.png</p>
          <Button
            variant="white"
            size="md"
            color={"red"}
            leftIcon={<IoMdClose />}
          >
            Clear
          </Button>
        </div> */}

        {/* <FileInput
          accept="image/*"
          onChange={(files) => files && onSelect(files)}
          placeholder="Select Image to Upload"
          className="rounded-lg p-4 flex-1"
          clearButtonLabel="Clear"
        /> */}
      </div>
    </>
  );
}
