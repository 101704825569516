import {
  Modal,
  MultiSelect,
  CloseButton,
  Button,
  TextInput,
  SelectItem,
  Checkbox,
} from "@mantine/core";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsTagFill, BsTrash, BsTrash2, BsTrashFill } from "react-icons/bs";
import { IoMdAddCircleOutline } from "react-icons/io";
import { UploadNewsUrlToImage, deleteImage } from "../../../utils/api/api";

import { API_URL } from "../../../utils/constants";
import { IImageData } from "../../../utils/types";
import { showToast } from "../../../utils/utility";
import useUpdateUploadedImage from "../Hooks/useUpdateUploadedImage";

type Props = {};

export default function SectionImage({
  url,
  id,
  tags,
  fileName,
  uploadedOn,
  uploadedBy,
  handleClick,
  imageNewsUrl,
}: IImageData) {
  const {
    firstLoad,
    setFirstLoad,
    handleFetchTags,
    handleAddNewTag,
    handleRemoveTag,
    AddTagToApi,
    availableTags,
    handleAddSelectedTags,
    selectedTags,
    newTags,
    isAddingNewTag,
    setIsAddingNewTag,
    tag,
    setTag,
    handleRemoveNewTags,
    isUploading,
  } = useUpdateUploadedImage({ id, tags, imageNewsUrl, handleClick });
  const { t } = useTranslation();
  useEffect(() => {
    firstLoad ? setFirstLoad(false) : handleFetchTags();
  }, [firstLoad]);
  const [newsUrlForImage, setNewsUrlForImage] = useState<string>("")
  const [isDisable, setIsDisable] = useState<boolean>(true)
  useEffect(() => {
    setNewsUrlForImage(imageNewsUrl ?? "")
  }, [imageNewsUrl])
  const [check, setCheck] = useState<boolean>(true);
  useEffect(() => console.log(selectedTags), [selectedTags]);

  function isUrlValid(str: string) {
    const pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', // fragment locator
      'i'
    );
    return pattern.test(str);
  }

  const handleEditNewsUrl = async () => {
    if (imageNewsUrl !== newsUrlForImage) {
      if (!isUrlValid(newsUrlForImage)) {
        showError("Please provide valid News url");
      }
      else {
        UploadNewsUrlToImage(id, newsUrlForImage)
          .then((e) => {
            console.log("uploaded url ")
          })
      }
    }

  }

  const deleteImageFromModal = (id: string) => {
    // console.log("delete Image function ", id);
    deleteImage(id)
      .then((Response) => {
        showToast({
          type: "success",
          title: "Delete Successfully",
          message: "Image has been deleted form Repository",
        });
        handleClick();
        // console.log("delete image", Response?.data);
      })
      .catch((err) => {
        showToast({
          type: "error",
          title: "Something went Wrong",
          message: "Could not delete the Image",
        });
      });
  };

  return (
    <div className="flex gap-6">
      <div className="image-modal">
        <img src={`${API_URL}assets/${url}`} alt="" />
      </div>
      <div className="flex flex-1 flex-col">
        <div className="flex flex-col flex-1 ">
          <div className="flex flex-col flex-1">
            <h2 className="mb-3 flex items-center gap-2">
              <BsTagFill /> {t("Edit Image")}
            </h2>
            <p className="mb-12 opacity-50">
              {t(
                "Add more than one tags, this will make it easy to find the correct image"
              )}
              .
            </p>
            {!isAddingNewTag ? (
              <>
                <MultiSelect
                  label={t("Tags")}
                  searchable
                  size="md"
                  limit={6}
                  // creatable
                  nothingFound
                  placeholder="Select a Tag"
                  value={selectedTags}
                  data={availableTags?.map((each) => {
                    return { label: each?.name, value: each?.name };
                  })}
                  // getCreateLabel={(query) => `+ Create ${query}`}
                  // onCreate={(query) => {
                  //   const item = { value: query, label: query };
                  //   handleAddNewTag(item.value);
                  //   return item;
                  // }}
                  onChange={(tagList) => {
                    setIsDisable(false)
                    handleAddSelectedTags(tagList);
                    console.log(tagList);
                  }}
                />
                {/* ADD NEW TAGS */}
                <Button
                  // fullWidth
                  variant="outline"
                  className="my-2"
                  onClick={() => {
                    setIsDisable(false)
                    setIsAddingNewTag(true)
                  }}
                >
                  {t("Add New tag")}
                </Button>
              </>
            ) : (
              <>
                <TextInput
                  label={t("Add New tag")}
                  placeholder={t("Enter tag name (ex: anime)")}
                  value={tag}
                  onChange={(e) => setTag(e.target.value)}
                />
                <div className="my-2 flex justify-end gap-2">
                  <Button
                    variant="outline"
                    size="xs"
                    onClick={() => {
                      setIsAddingNewTag(false);
                      setTag("");
                    }}
                  >
                    {t("Cancel")}
                  </Button>
                  <Button
                    size="xs"
                    className="bg-blue-600"
                    disabled={tag.length === 0 ? true : false}
                    onClick={() => handleAddNewTag(handleClick)}
                  >
                    {t("Save")}
                  </Button>
                </div>
              </>
            )}
            {newTags.length > 0 ? (
              <>
                <p className="font-semibold text-sm my-1">{t("New Tags")}</p>
                <div className="tag-container">
                  {newTags?.map((each, index) => (
                    <div className="tag tag--lg" key={index + "tag"}>
                      {each}
                      <div className="close-btn">
                        <CloseButton
                          size="sm"
                          iconSize={16}
                          onClick={() => handleRemoveNewTags(each)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
          {imageNewsUrl && <TextInput
            label="News url for image"
            type="url"
            value={newsUrlForImage ?? ""}
            onChange={(e) => {
              setIsDisable(false)
              setNewsUrlForImage(e?.target?.value)
            }}
          />}
          <div>
            <p className="opacity-60 text-xs border-t mt-10 pt-3">
              {t("Uploaded by")}{" "}
              {uploadedBy?.first_name + " " + uploadedBy?.last_name} {t("on")}{" "}
              {uploadedOn ? moment(uploadedOn).format("DD/MM/YYYY") : ""}
            </p>
            {/* Delete Box */}
            <div className="flex flex-row items-center justify-between my-4 bg-red-50 p-4 rounded-lg border border-red-100">
              <Checkbox
                onChange={(value) => {
                  setCheck(!check);
                }}
                label="I confirm and want to delete this image"
                className="opacity-80"
                color={'red'}
              // size="xs"
              />
              <div className="ml-3">
                <Button
                  variant="filled"
                  // size="xs"
                  leftIcon={<BsTrashFill />}
                  className="bg-red-500 hover:bg-red-600"
                  onClick={() => deleteImageFromModal(id)}
                  disabled={check}
                >
                  Delete Image
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="flex gap-2 justify-end">
          <Button
            variant="outline"
            onClick={() => {
              handleClick();
            }}
          >
            {t("Cancel")}
          </Button>
          <Button
            loading={isUploading}
            variant="filled"
            disabled={isDisable}
            className="bg-primary"
            onClick={() => {
              handleEditNewsUrl()
              AddTagToApi(handleClick);
            }}
          >
            {t("Save")}
          </Button>
        </div>
      </div>
    </div>
  );
}
function showError(arg0: string) {
  throw new Error("Function not implemented.");
}

