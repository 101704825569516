import React, {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { InewsDetail } from "../types";

type Props = {};
type TypeSelecetdNews = {
  selectedNews: InewsDetail;
  handleSetSelecetdNews: (selectedNews: InewsDetail) => void;
};

export const SelectedNewsContext = createContext<TypeSelecetdNews>({
  selectedNews: {} as InewsDetail,
  handleSetSelecetdNews: () => {},
});

const SelectedNews: FC<PropsWithChildren<Props>> = ({ children }) => {
  const [selectedNews, setSelectedNews] = useState<InewsDetail>(
    {} as InewsDetail
  );

  useEffect(() => {
    const selecetdNews = JSON.parse(
      localStorage.getItem("selectedNews") || "{}"
    ) as InewsDetail;
    handleSelecetdNews(selecetdNews);
  }, []);

  const handleSelecetdNews = useCallback(
    (selectedNews: InewsDetail) => {
      setSelectedNews(selectedNews);
      localStorage.setItem("selectedNews", JSON.stringify(selectedNews));
    },
    [selectedNews]
  );

  const handleSetSelecetdNews = (selectedNews: InewsDetail) => {
    console.log("Selecetd News", selectedNews);
    setSelectedNews(selectedNews);
  };

  return (
    <SelectedNewsContext.Provider
      value={{
        selectedNews,
        handleSetSelecetdNews,
      }}
    >
      {children}
    </SelectedNewsContext.Provider>
  );
};

export default SelectedNews;
