import { useEffect, useState } from "react";

import { showToast } from "./../../utils/utility";
import { UploadNewsUrlToImage, addTags, getNewsTags, uploadFile, uploadNewTags } from "../../utils/api/api";
import { INewTagToBeAdded, ITags } from "../../utils/types";
import { useTranslation } from "react-i18next";

const useImagUploader = () => {
  
  const { t } = useTranslation()

  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [availableTags, setAvailableTags] = useState<ITags[]>([]);
  
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [newTags, setNewTags] = useState<string[]>([]);
  const [selectedFile, setSelectedFile] = useState<File| undefined>(undefined);
  const [newsUrl ,setNewsUrl] =useState<string>("");

  const [tag, setTag] = useState<string>("");
  const [isUploading, setIsUploading] = useState<boolean>(false);

  
  useEffect(() => {
    if (firstLoad) setFirstLoad(false);
    else handleFetchTags();
  }, [firstLoad]);

  // HANDLER FUNCTIONS
  const handleSelectTag = () => {
    setSelectedTags((pr) => [...pr, tag]);
  };
  const handleRemoveTag = (tag: string) => {
    setSelectedTags((pr) => pr.filter((f) => f !== tag));
    newTags.includes(tag) && setNewTags(tg => tg.filter(t => t !== tag));
  };

  const handleAddSelectedTags = (tags: string[]) => {
    setSelectedTags(tags);
  };
  const handleAddNewTag = (tag: string) => {
    newTags.filter(t => t === tag).length > 0
      ?
      showToast({
        type: "warning",
        message: "Tag already added!"
      })
      :
      setNewTags(prev => ([...prev, tag]));
  };
  

  const clearInputs = () => {
    setSelectedFile(undefined);
    setSelectedTags([]);
    setNewTags([]);
  };

  // API CALLS
  const handleFetchTags = () => {
    getNewsTags().then(
      response => {
        // console.log("Response for tags: ", response.data?.data);
        setAvailableTags(response.data?.data);
      }, error => {
        console.log("Error for tags fetch: ", error?.response);
        showToast({
          type: "error",
          title: t("Something Went Wrong!"),
          message: t("Could not fetch tags")
        });
      }
    );
  };

  const uploadImage = (handleClick?: any) => {
    // let oldSelectedTags = selectedTags.filter(e => !newTags.some(t => t === e));
    // console.log("Selected tags: ", selectedTags);
    // console.log("Old tags: ", oldSelectedTags);
    // console.log("Handle Image upload Function call");
    //   handleClick()
    if (newTags.length > 0) {
      handleUploadNewTags(handleClick);
    } 
    else
    { 
      handleUploadNewImage(handleClick)
    }
  };

  const handleUploadNewTags = (handleClick :()=>void) => {
    const newTagsPayload: INewTagToBeAdded[] = newTags.map(each => Object.assign({
      name: each,
      categories: [{ category_id: 36 }]
    }));
    uploadNewTags(newTagsPayload).then(
      response => {
        console.log("New tags has been added successfully!");
        handleUploadNewImage(handleClick);
      }, error => {
        console.log("Error in add new tags: ", error?.response);
        showToast({
          type: "error",
          title: t("Something Went Wrong!"),
          message: t("Could not create new tags! Try again later.")
        });
      }
    );
  };


  const getMeta = (url: string, file: any) => {
    const img = new Image();
    img.addEventListener("load", function () {
      // alert( this.naturalWidth +'hfhryry'+ this.naturalHeight );
      if (this.naturalWidth == 720 && this.naturalHeight == 1125) {
        setSelectedFile(file);
      } else {
        setSelectedFile(undefined)
        showToast({
          type: "warning",
          title: t("Image not uploaded!"),
          message: t("Please select image of 720px & 1125px!"),
        })
      }
    });
    img.src = url;
  }


  const handleUploadNewImage = async (handleClick: ()=>void) => {
    try {
      setIsUploading(true);
      if (selectedFile) {
        const res = await uploadFile(selectedFile);
        if (res?.data) {
          const fileId = res?.data?.data?.id;
          const response = await addTags(fileId, selectedTags);
          const urlResp = await UploadNewsUrlToImage (fileId , newsUrl)
          
          if (response?.data) {
            showToast({
              type: "success",
              message: t("Successfully uploaded image."),
            });
            handleFetchTags();
            clearInputs();
            setSelectedTags([]);
            setNewTags([]);
          }
        }
        handleClick()
      }
    } catch (error) { }
    setIsUploading(false);
  };

  return {
    tag,
    setTag,
    newsUrl,
    setNewsUrl,
    handleSelectTag,
    selectedTags,
    setSelectedTags,
    newTags,
    getMeta,
    handleRemoveTag,
    selectedFile,
    setSelectedFile,
    uploadImage,
    isUploading,
    clearInputs,
    availableTags,
    handleAddNewTag,
    handleAddSelectedTags
  };
};

export default useImagUploader;
