import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { blob } from "stream/consumers";
import { getImagesByUploader, getNewsTags, getPsdTemplate } from "../../../utils/api/api";

import { LoginContext } from "../../../utils/context/AppContext";
import { IImageData, ITags } from "../../../utils/types";
import { handleFormatUploadedImage, showToast } from "../../../utils/utility";

export default function useUploadedImage() {

    const navigate = useNavigate();
    const { user } = useContext(LoginContext);

    const [opened, setOpened] = useState(false);
    const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
    const [allImages, setAllImages] = useState<IImageData[]>([]);
    const [filteredData, setFilteredData] = useState<IImageData[]>([]);
    const [imagesLoading, setImagesLoading] = useState<boolean>(false);
    const [imageModal, setImageModal] = React.useState<boolean>(false);
    const [selectedIndex, setSelectedIndex] = React.useState<number>(-1);
    const [availableTags, setAvailableTags] = useState<string[]>([]);
    const [selectedTags, setSelectedTags] = useState<string[]>([]);
    const { t } = useTranslation();
    const LIMIT = 100;
    const [page, setPage] = useState<{
        totalAvailablePages: number;
        currentPage: number;
    }>({
        totalAvailablePages: 1,
        currentPage: 1
    });

    useEffect(() => {
        if (isFirstLoad) {
          setIsFirstLoad(false);
        } else {
          getAllAvailableImages(1);
          handleFetchTags();
          // psdTemplaeDownload(id);
        }
      }, [user, isFirstLoad]);

    // HANDLER FUNCTIONS
    const handleAddSelectedTags = (tagList: string[]) => {
        setSelectedTags(tagList);
        handleImageSearchBasedOnSelectedTags(tagList);
    };

    const handleImageSearchBasedOnSelectedTags = (tagList: string[]) => {
        let query = tagList;
        let filtered = allImages?.filter((img) => {
            let tg = img.tags;
            let n = tg?.filter((t) => query.some((q) => q?.toLowerCase() === t?.toLowerCase()));
            return n?.length && img;
        });
        setFilteredData(filtered);
    };

    //   API CALLS

    const psdTemplateDownload = () => {
        getPsdTemplate()
            .then((response) => {
                // console.log("data fetched from psd",response?.data)
                var a = document.createElement("a");
                a.href = window.URL.createObjectURL(new Blob([response?.data]));
                a.download = "PSD_Template.psd";
                a.click();
            }).catch(
                error => {
                    console.log("Error for Psd Template", error);
                    showToast({
                        type: "error",
                        title: t("Something Went Wrong!"),
                        message: t("Could not Download PSD Template")
                    });
                }
            )
    }

    const handleFetchTags = () => {
        getNewsTags().then(
            response => {
                // console.log("Response for tags: ", response.data?.data);
                const tagData: string[] = response?.data?.data?.map((e: ITags) => e.name);
                // setAvailableTags(response.data?.data);
                const uniqueArray: string[] = tagData.filter(function (item, pos) {
                    return tagData.indexOf(item) == pos;
                })

                // console.log("Unique tags: ", uniqueArray);
                setAvailableTags(uniqueArray);
            }, error => {
                console.log("Error for tags fetch: ", error?.response);
                showToast({
                    type: "error",
                    title: t("Something Went Wring!"),
                    message: t("Could not fetch tags")
                });
            }
        );
    };

    const getAllAvailableImages = async (selectedPage:number) => {
        try {
            setImagesLoading(true);
            if (user) {
                const res = await getImagesByUploader(LIMIT, selectedPage);
                if (res?.data) {
                    // console.log("GetAllAvelabeImages  : ", res.data?.data);
                    const totalPages = Math.ceil((res?.data?.meta?.filter_count || 1) / LIMIT);
                    setPage({currentPage:selectedPage, totalAvailablePages: totalPages});
                
                    const formattedData = handleFormatUploadedImage(res?.data?.data);
                    setAllImages(formattedData || []);
                    setFilteredData(formattedData || []);
                }
            }
        } catch (error) {
            console.log("error getting images", error);
            showToast({
                type: "error",
                title: t("Something Went Wrong!"),
                message: t("Could not fetch available images.")
            });
        }
        setImagesLoading(false);
    };

    return {
        user,
        navigate,
        isFirstLoad,
        setIsFirstLoad,
        getAllAvailableImages,
        allImages,
        opened,
        setOpened,
        handleImageSearchBasedOnSelectedTags,
        imageModal,
        setImageModal,
        filteredData,
        imagesLoading,
        setSelectedIndex,
        selectedIndex,
        handleFetchTags,
        psdTemplateDownload,
        availableTags,
        handleAddSelectedTags,
        selectedTags,
        LIMIT,
        page,
        setPage
    };
}
