import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BsLockFill } from "react-icons/bs";
import { FiCheckCircle, FiX } from "react-icons/fi";

type StatusButtonProps = {
  type: "approve" | "reject";
  isSelected?: Boolean;
  onClick(): void;
  selected: boolean;
};

const StatusButton = ({
  type,
  isSelected,
  onClick,
  selected,
}: StatusButtonProps) => {
  // const [selected, setSelected] = useState(false);
  return (
    <>
      <div
        className={`status-btn solid ${type === "approve" ? " approve" : " reject"} ${selected ? "selected" : ""
          }`}
        onClick={() => onClick()}
      >
        {type === "approve" ? (
          <>
            {" "}
            <FiCheckCircle size={20} /> Approve
          </>
        ) : (
          <>
            {" "}
            <FiX size={20} /> Reject
          </>
        )}
      </div>
    </>
  );
};

export default StatusButton;
