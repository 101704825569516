import {
  Button,
  Chip,
  CloseButton,
  Container,
  Grid,
  Image,
  Modal,
  MultiSelect,
  Pagination,
  Skeleton,
  TextInput,
} from "@mantine/core";
import { BsSearch } from "react-icons/bs";
import { FiFilePlus, FiUpload } from "react-icons/fi";
import {  showToast } from "../../utils/utility";
import SectionImage from "./component/SectionImage";
import UploadImageModal from "./component/UploadImageModal";
import { ImageSection } from "../../components/ImageSection";
import "./UploadedImage.scss";
import { AvailableImageLoader } from "../../components/AvailableImageLoader";
import useUploadedImage from "./Hooks/useUploadedImage";
import { useTranslation } from "react-i18next";
type Props = {};

const UploadedImage = (props: Props) => {
  const {
    user,
    navigate,
    getAllAvailableImages,
    psdTemplateDownload,
    allImages,
    handleFetchTags,
    opened,
    setOpened,
    handleImageSearchBasedOnSelectedTags,
    imageModal,
    setImageModal,
    filteredData,
    imagesLoading,
    setSelectedIndex,
    selectedIndex,
    availableTags,
    handleAddSelectedTags,
    selectedTags,
    LIMIT,
    page,
    setPage
  } = useUploadedImage();
  const { t } = useTranslation();
  

  return (
    <Container fluid className="py-10 pr-10">
      <div className="flex flex-row gap-4 justify-between">
        <div>
          <h1 className="">{t("Image Repository")}</h1>
          <p className="opacity-60 text-sm">
          {`page: ${page?.currentPage}/${page?.totalAvailablePages}`}
          </p>
        </div>
        <div className="">
          <Button
            variant="subtle"
            leftIcon={<FiFilePlus />}
            onClick={() => psdTemplateDownload()}
          >
            Download PSD Template
          </Button>
          <Button
            variant="filled"
            className="bg-primary ml-1"
            leftIcon={<FiUpload />}
            onClick={() => setOpened(true)}
          >
            {t("Upload Image")}
          </Button>
        </div>
      </div>
      <div className="mt-6">
          <MultiSelect
            searchable
            nothingFound
            radius={"md"}
            // size={"lg"}
            icon={<BsSearch />}
            placeholder={t("Search here (eg: cricket, virat kohli etc...)")}
            value={selectedTags}
            data={availableTags?.map((each) => {
              return { label: each, value: each };
            })}
            onChange={(tagList) => {
              handleAddSelectedTags(tagList);
            }}
          />
        </div>

      {/* IMAGES */}
      <div>
      {
        imagesLoading ? 
          <AvailableImageLoader />
         : filteredData?.length > 0 ?
         <div>
          <div className="image-repository-container  mt-12 ">
            {filteredData?.map((item, index) => (
              <ImageSection
                key={index}
                url={item?.url}
                tags={item?.tags}
                uploadedBy={item?.uploadedBy}
                uploadedOn={item?.uploadedOn}
                fileName={item?.fileName}
                id={item?.id}
                handleClick={() => {
                  if (
                    user?.role === "editor" ||
                    user?.id === item?.uploadedBy?.id
                  ) {
                    setImageModal(true);
                    setSelectedIndex(index);
                  } else {
                    showToast({
                      type: "warning",
                      time: 5000,
                      title: t("You Can Not Edit This Image!"),
                      message: t(
                        "This image was uploaded by some other Graphic Designer"
                      ),
                    });
                  }
                }}
              />
            ))}
            </div>
             <div className="mt-6 w-full flex justify-center">
            <Pagination
                total={page.totalAvailablePages}
                siblings={2}
                boundaries={2}
                defaultValue={page.currentPage}
                styles={(theme) => ({
                  control: {
                    '&[data-active]': {
                      backgroundImage: theme.fn.gradient({ from: 'red', to: 'yellow' }),
                      border: 0,
                    },
                  },
                })}
                onChange={(value) => getAllAvailableImages(value)}
              />
              </div>
          </div>
         :
          <div className="image-repository-container mt-12 ">
            {allImages?.map((item, index) => (
              <ImageSection
                key={index}
                url={item?.url}
                tags={item?.tags}
                uploadedBy={item?.uploadedBy}
                uploadedOn={item?.uploadedOn}
                fileName={item?.fileName}
                id={item?.id}
                handleClick={() => {
                  if (
                    user?.role === "editor" ||
                    user?.id === item?.uploadedBy?.id
                  ) {
                    setImageModal(true);
                    setSelectedIndex(index);
                  } else {
                    showToast({
                      type: "warning",
                      time: 5000,
                      title: t("You Can Not Edit This Image!"),
                      message: t(
                        "This image was uploaded by some other Graphic Designer"
                      ),
                    });
                  }
                }}
              />
            ))}
          </div>}
          </div>


      {/* UPLOAD NEW IMAGE */}
      <Modal
        opened={opened}
        onClose={() => {
          setOpened(false);
        }}
        centered
        size={860}
        // overlayBlur={6}
        withCloseButton={false}
      >
        <UploadImageModal
          handleClick={() => {
            getAllAvailableImages(1);
            setOpened(false);
          }}
        />
      </Modal>

      {/* EDIT EXISTING IMAGE TAGS */}
      <Modal
        opened={imageModal}
        onClose={() => setImageModal(false)}
        centered
        size={860}
        // overlayBlur={6}
        withCloseButton={false}
      >
        <SectionImage
          url={filteredData[selectedIndex]?.url}
          tags={filteredData[selectedIndex]?.tags}
          fileName={filteredData[selectedIndex]?.fileName}
          uploadedBy={filteredData[selectedIndex]?.uploadedBy}
          uploadedOn={filteredData[selectedIndex]?.uploadedOn}
          id={filteredData[selectedIndex]?.id}
          handleClick={() => {
            setImageModal(false);
            getAllAvailableImages(1);
          }}
          imageNewsUrl={filteredData[selectedIndex]?.imageNewsUrl}
        />
      </Modal>
    </Container>
  );
};

export default UploadedImage;
