import React, {
  RefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { canvasNewsPreview } from "../utils/CanvasNewsPreview";
import { AddNewsContext } from "../utils/context/AddNewsContext";
import Draggable, { DraggableCore } from "react-draggable";
import { LoadingOverlay } from "@mantine/core";
import { IProvider } from "../utils/types";
import { getAllProvider } from "../utils/api/api";
import { showToast } from "../utils/utility";
import { API_URL } from "../utils/constants";

type Props = {
  backgroundImageRef: RefObject<HTMLCanvasElement>;
  titleRef: RefObject<HTMLDivElement>;
  isCreatingNews: boolean;
};
export default function AddNewsWithoutFrame({
  backgroundImageRef,
  titleRef,
  isCreatingNews,
}: Props) {

  // console.log("background in preview",backgroundImageRef.current)
  const imgRef = useRef<HTMLImageElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [allProvider, setAllProvider] = useState<IProvider[]>([]);
  const [position, setPosition] = useState({ x: 200, y: 200 });
  const [initialPosition, setInitialPosition] = useState({ x: 0, y: 0 });
  const [finalP, setFinalP] = useState({ x: 0, y: 0 });

  const {
    updateAddNewsInfo,
    backgroundUrl,
    newsHeadline,
    textPlacement,
    textColor,
    CanvasViewRef,
    newsUrl,
    provider,
    scaleRef,
    guidelineRef,
    headerLogoRef,
    headerProviderLogoRef,
    newsTitle,
  } = useContext(AddNewsContext);

  const [image64, setImage64] = useState(localStorage.getItem("providerImage") ?? "")

  useEffect(() => {
    if(provider){
    downloadAndStoreImage(provider);}
  }, [provider])

  useEffect(() => {
    handelFetchProvider()
  }, [])

  useEffect(() => {
    if (backgroundUrl) {
      setLoading(true);
      setTimeout(() => {
        handlePreview();
      }, 1500);
    }
  }, [backgroundUrl, imgRef, backgroundImageRef]);

  /*  Download and store the provider image in local storage for image to share */

  const downloadAndStoreImage = async (providerId: any) => {
    try {
      const response = await fetch(`${API_URL}assets/${allProvider.filter(e => e?.id === +providerId)[0]?.icon?.filename_disk}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();
      const reader = new FileReader();

      reader.onload = function () {
        const base64String = reader.result;
        localStorage.setItem("providerImage", `${base64String}`);
        setImage64(localStorage.getItem("providerImage") ?? "")
      };

      reader.readAsDataURL(blob);
    } catch (error) {
      console.error('Error downloading and storing image:', error);
    }
  };

  const handelFetchProvider = () => {
    getAllProvider().then(
      (response) => { setAllProvider(response.data?.data || []); },
      (error) => {
        showToast({
          type: "warning",
          title: "Fetch provider failed!",
          message: "could not fetch provider data"
        })
      }
    )
  };

  const handlePreview = () => {
    canvasNewsPreview(imgRef, backgroundImageRef);
    setLoading(false);
  };

  const handleInitalPosition = (event: any) => {
    setInitialPosition({ x: event.clientX, y: event.clientY });
  };

  const handleTotalMovedPosition = () => {
    const x = position.x - initialPosition.x;
    const y = position.y - initialPosition.y;
    setFinalP({ x, y });
  };

  const handleMouseMove = (event: any) => {
    // console.log(event);
    setPosition({
      x: event.clientX,
      y: event.clientY,
    });
  };

  return (
    <div className="flex items-center  justify-around flex-wrap  w-full gap-3 ">
      <div hidden>
        <img
          alt="img"
          ref={imgRef}
          className="w-[720px] h-[1125px]"
          src={backgroundUrl}
          crossOrigin="anonymous"
        />
      </div>

      {/* Loader  */}

      {/* NEW NEWS */}
      <div className="canvas-preview-reszie" ref={CanvasViewRef}>
        {isCreatingNews &&

          <div className="loading-new-news-canvas scale-50" ref={scaleRef}>
            <LoadingOverlay visible={isCreatingNews} overlayBlur={2} />
          </div>
        }
        <div className="relative break-words" ref={titleRef}>
          <div className="new-news-canvas scale-50" ref={scaleRef}>
            <div className=" absolute top-14 right-12 hidden" ref={headerLogoRef} >
              <img 
              width={100} 
              height={40} 
              alt="img" 
              src={require("../assets/hedr-logo.png")}
               />
            </div>
            <div className=" absolute bottom-14 left-12 hidden" ref={headerProviderLogoRef} >
              { provider ?
                <img 
                width={70} 
                height={70} 
                className="rounded-full" 
                alt="img" 
                src={image64} />
                : <></>}
            </div>
            <div className="safearea-guidelines" ref={guidelineRef}></div>
            <canvas
              className={`${backgroundUrl ? "" : "canvas"}`}
              ref={backgroundImageRef}
              width={720}
              height={1125}
            ></canvas>
            <LoadingOverlay visible={loading} overlayBlur={2} />
            <Draggable
              defaultPosition={{ x: 0, y: 0 }}
              // grid={[15, 15]}
              scale={1}
              bounds={"parent"}
              onStart={(e) => handleInitalPosition(e)}
              onDrag={(e) => {
                handleMouseMove(e);
              }}
              onStop={() => handleTotalMovedPosition()}
            >
              <div
                className={`absolute px-28 py-36 z-[99] cursor-pointer top-0`}
                style={{
                  color: textColor ? textColor : "white",
                }}
                // contentEditable
                // onInput={(e) => console.log(e.currentTarget.innerText)}
                dangerouslySetInnerHTML={{ __html: newsHeadline }}
              >
                {/* {formattedTitle.beforeHighLightText}
              <span className={`font-bold`} style={{ color: highLightColor }}>
                {formattedTitle.highLightText}
              </span>
              {formattedTitle.afterHighLightText} */}
              </div>
            </Draggable>
          </div>
        </div>
      </div>
    </div>
  );
}
