
import React, { useState } from 'react'
import { INewQuestion, INewQuestionOptions, IQuestionOption } from '../../../../utils/types'

export default function useAddSurveyQuestionOptions() {
    const [isOptionModalOpen, setIsOptionModalOpen] = useState<boolean>(false)
    const [newQuestionOptions,setNewQuestionOptions]= useState<INewQuestionOptions>(
        {
        status: "published",
        question_text: "",
        option_type: "single_choice",
        option_list:[]
        })
    const [newOption, setNewOption] = useState<IQuestionOption>({
        status: "published",
        option_text: ""
    })

     

    // handler function
    const handleAddOption = () => {
        setNewQuestionOptions({...newQuestionOptions , option_list : [...newQuestionOptions?.option_list , newOption]})
        setNewOption({
            status: "published",
            option_text: ""
        })
        setIsOptionModalOpen(false)
    }

    const handleRemoveOption = (e: IQuestionOption) => {
        setNewQuestionOptions({...newQuestionOptions , option_list : newQuestionOptions?.option_list.filter(allList => allList != e)})   
    }
  return{
    isOptionModalOpen,
    setIsOptionModalOpen,
    newOption,
    setNewOption,
    handleAddOption,
    handleRemoveOption,
    newQuestionOptions,
    setNewQuestionOptions
  }
}
