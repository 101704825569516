import React from 'react'
import CreateNewsItem from '../AddNews/component/CreateNewsItem'
import AddNewsWithoutFrame from '../../components/AddNewsWithoutFrame'
import useAddCarouselNews from './hooks/useAddCarouselNews'
import NewsContextWrapper, {
  AddNewsContext,
} from "../../utils/context/AddNewsContext";
import { Carousel } from '@mantine/carousel';
import CarouselImageUploader from '../../components/CarouselImage/CarouselImageUploader';
import { ScrollArea } from '@mantine/core';
import { useScrollIntoView } from '@mantine/hooks';
type Props = {};

const AddNewsWithContext = () => {
  const {
    backgroundImageRef,
    titleRef,
    handleSave,
    handleCancel,
    isClickedMore,
    setIsClickedMore,
    isCreatingNews ,
    targetRef,
    scrollableRef
  } = useAddCarouselNews()

  return (
    <div className=" flex lg:flex-row h-full flex-col ">
      {
      isCreatingNews && 
       <div className='absolute w-full h-full bg-slate-400 opacity-20'>

       </div> 
      }
      <CreateNewsItem
        onSave={() =>{
          handleSave()
        }}
        checkedCarousel={(e)=>setIsClickedMore(e)}
        onCancel={() => handleCancel()}
        isCreatingNews={isCreatingNews}
      /> 
      <div ref={scrollableRef} className={`w-full overflow-y-scroll h-screen`}>        
          <AddNewsWithoutFrame
            backgroundImageRef={backgroundImageRef}
            titleRef={titleRef}
            isCreatingNews={isCreatingNews}
          />
        <div
          ref={targetRef}
          className={`${!isClickedMore ? "hidden" : ""}`}
        >
          <CarouselImageUploader
          // allCarouselImage={(e)=>{}}
          />
        </div>
      </div>
    </div>
  );
};
export default function AddCarouselNews(props: Props) {
  return (
    <NewsContextWrapper>
      <AddNewsWithContext />
    </NewsContextWrapper>
  )
}
