import { useContext, useEffect, useState } from "react";
import { Button, Divider, Badge, Image, Select, Modal } from "@mantine/core";
import { ReviewInput } from "../../libs";
import moment from "moment";
import { useParams } from "react-router-dom";
import { changeStatus, getSelectedNewsDetails } from "../../utils/api/api";
import { showToast } from "../../utils/utility";
import { API_URL } from "../../utils/constants";
import { LoginContext } from "../../utils/context/AppContext";
import { InewsDetail } from "../../utils/types";
import { useTranslation } from "react-i18next";
import Chat from "../Chat/Chat";

const initialNewsDetails = {
  id: -1,
  name: "",
  title: "",
  url: "",
  publishedAt: "",
  description: "",
  provider_id: -1,
  urlToImage: "",
  status: "",
  created_by: "",
  updated_by: "",
  categories: [],
};

export default function NewsDetails() {
  const { user } = useContext(LoginContext);
  const { id } = useParams();

  const [selectedNews, setSelectedNews] =
    useState<InewsDetail>(initialNewsDetails);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [selectedStatus, setSelectedStatus] = useState<string>("");
  const { t } = useTranslation();
  const [isChangingStatus, setIsChangingStatus] = useState<boolean>(false);
  const [imageOpened, setImageOpened] = useState<boolean>(false);

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
    } else {
      fetchNewsDetails();
    }
  }, [firstLoad]);

  const fetchNewsDetails = () => {
    getSelectedNewsDetails(Number(id)).then(
      (response) => {
        // console.log("Response for news details: ", response.data?.data);
        setSelectedNews(response.data?.data);
      },
      (error) => {
        console.log("Error fetching news details: ", error?.response);
        showToast({
          type: "error",
          title: t("Something Went Wrong!"),
          message: t("Could not fetch news details!"),
        });
      }
    );
  };

  const sendPostApproval = async (id: number) => {
    setIsChangingStatus(true);
    try {
      const res = await changeStatus(id, selectedStatus);
      if (res?.data) {
        showToast({
          message: `${t("News is")} ${selectedStatus} ${t("for the app.")}`,
          type: "success",
        });
        setSelectedNews(res.data?.data);
        setSelectedStatus("");
      }
    } catch (error) {
      console.log("error approving news", error);
      showToast({
        title: t("Cannot approve news"),
        message: t("Try again later."),
        type: "error",
      });
    }
    setIsChangingStatus(false);
  };

  return (
    <div className="flex h-screen">
      <div className="h-full  lg:w-[554px] w-full  bg-white relative">
        <div className="flex justify-between w-full h-16 items-center px-4 py-6">
          <p className="font-bold text-xl">News Details</p>
          <Badge
            className={`${
              selectedNews?.status === "pending"
                ? "bg-primary text-white"
                : selectedNews?.status === "approved"
                ? "bg-green-400 text-white"
                : ""
            }`}
          >
            {selectedNews?.status}
          </Badge>
        </div>
        <Divider />
        <div className="p-2 bg-slate-100 ">
          <div className="">
            <ReviewInput label={"Title"} value={selectedNews?.title} />
            <ReviewInput
              label={"Published At"}
              value={
                selectedNews?.publishedAt
                  ? moment(selectedNews.publishedAt).format("MMM Do YYYYY")
                  : ""
              }
            />
            {/* <ReviewInput label={"Status"} value={selectedNews?.status} /> */}
            <ReviewInput
              label={"News Url"}
              value={
                selectedNews?.url ? (
                  <a
                    href={selectedNews?.url}
                    className={"text-blue-700"}
                    target="blank"
                  >
                    Link To News
                  </a>
                ) : (
                  <></>
                )
              }
            />
            <ReviewInput
              label={"View Image"}
              value={
                selectedNews?.urlToImage ? (
                  <span
                    className="text-blue-600 cursor-pointer"
                    onClick={() => setImageOpened(true)}
                  >
                    View Image
                  </span>
                ) : (
                  <></>
                )
              }
            />
          </div>
        </div>
        {user?.role === "editor" ? (
          <div className=" h-16 absolute bottom-0 w-full flex flex-col justify-center">
            <Divider className="mt-0 sticky " />
            <div className="flex items-center p-3  gap-3">
              {/* <Button
                variant="light"
                radius="md"
                className="ml-auto"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Go Back
              </Button> */}
              <Select
                // label="Your favorite framework/library"
                className="w-[80%]"
                placeholder="Change status"
                data={[
                  { value: "approved", label: "Approved" },
                  { value: "rejected", label: "Rejected" },
                ]}
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(String(e))}
              />
              <Button
                radius="md"
                disabled={isChangingStatus || !selectedStatus.length}
                className="bg-primary w-[20%]"
                onClick={() => {
                  sendPostApproval(selectedNews?.id);
                }}
              >
                Save
              </Button>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      {/* <Image
        src={`${API_URL}assets/${selectedNews?.urlToImage}`}
        className="rounded-xl overflow-hidden m-auto my-auto"
        height={600}
        width={300}
      /> */}

      {/* FEEDBACK SECTION */}
      {/* <Chat user={user} news={selectedNews} /> */}

      <Modal opened={imageOpened} onClose={() => setImageOpened(false)}>
        <Image
          src={`${API_URL}assets/${selectedNews?.urlToImage}`}
          className="rounded-xl overflow-hidden m-auto my-auto"
          // height={600}
          // width={300}
        />
      </Modal>
    </div>
  );
}
