import { API_URL } from "./../constants";
import { useContext, useState } from "react";
import { LoginContext } from "../context/AppContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { showToast } from "../utility";
import { useTranslation } from "react-i18next";

export default function useAuth() {
  const navigate = useNavigate();
  const [user, setUser] = useState<string[]>([]);
  const { t } = useTranslation();
  const { handleLoginSuccess, handleLogout } = useContext(LoginContext);
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  // const loginPromise = () => {
  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => {
  //       resolve("success");
  //     }, 2000);
  //   });
  // };
  const login = async (email: string, password: string) => {
    try {
      setIsLoggingIn(true);
      const response = await axios.post(`auth/login`, {
        email,
        password,
      });
      if (response?.data) {
        let token = response?.data?.data?.access_token;
        let refToken = response?.data?.data?.refresh_token;
        const ax = axios.create();
        const profileResponse = await ax.get(
          `${API_URL}users/me?fields=id,first_name,last_name,email,role.name`,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        if (profileResponse?.data) {
          handleLoginSuccess(
            token,
            {
              ...profileResponse?.data?.data,
              role: String(
                profileResponse?.data?.data?.role?.name
              ).toLowerCase(),
            },
            refToken
          );
        }
        setIsLoggingIn(false);
        return true;
      }
    } catch (error) {
      console.log("error", error);
      showToast({
        type: "error",
        title: t("Something Went Wrong!"),
        message: t("Could not authenticate user.")
      });
      setIsLoggingIn(false);
    }
  };
  const logout = () => {
    handleLogout();
  };
  return { login, isLoggingIn, logout };
}
