import moment from "moment";
import { useTranslation } from "react-i18next";
import { API_URL } from "../utils/constants";
import { IImageData } from "../utils/types";

export const ImageSection = ({
  url,
  id,
  tags,
  categories,
  fileName,
  uploadedOn,
  uploadedBy,
  handleClick,
  setLoadedImageCount
}: IImageData) => {
  const { t } = useTranslation();
  let category: any = categories;
  return (
    <div className="image-item" onClick={() => handleClick()}>
      <div className="image">

        <img
          src={`${API_URL}assets/${url}`}
          alt="Failed to load..."
          onLoad={() => setLoadedImageCount ? setLoadedImageCount(prev => prev += 1) : {}}
          onError={(err) => setLoadedImageCount ? setLoadedImageCount(prev => prev += 1) : console.log("Error while loading image: ", err)}
        />

        <div className="overlay">
          {/* <div></div> */}
          <div className="tag-container">
            {tags?.map((each, index) => (
              <div className="tag" key={index + "cat"}>
                {each}
              </div>
            ))}
            {/* {category?.map((each: any, index: any) => (
              <div className="tag" key={index + "cat"}>
                {each?.category_id?.name}
              </div>
            ))} */}
          </div>
          <div>
            <p className="text-xs font-semibold text-white opacity-80">
              {t("Uploaded by")}{" "}
              {(uploadedBy?.first_name || " ") +
                " " +
                (uploadedBy?.last_name || "")}{" "}
              {t("on")}{" "}
              {uploadedOn ? moment(uploadedOn).format("DD/MM/YYYY") : ""}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
