import React, { useContext, useState } from 'react'
import { showToast } from '../../../utils/utility';
import { LoginContext } from '../../../utils/context/AppContext';
import { INewSurvey } from '../../../utils/types';
import moment from 'moment';
import { postSurvey } from '../../../utils/api/api';
import { useNavigate } from 'react-router-dom';

const showError = (error: string) => {
    showToast({
      message: error,
      type: "error",
    });
  };
  
export default function useCreateNewsSurvey() {
    const navigate =useNavigate()
    const [isSurveyCreating, setIsSurveyCreating] = useState<boolean>(false)
    const { user } = useContext(LoginContext);
    // const [surveyList,setSurveyList]=useState<ISurveyList[]>([])
    const [newSurvey,setNewSurvey]=useState<INewSurvey>({
        status: "published",
        user_created: user?.id || "",
        date_created:moment().format("YYYY-MM-DD"),
        title: '',
        description: '',
        start_date: '',
        due_date: '',
        question_list: []
    })
  
    const handleSubmitSurvey = async()=>{
      const isVAlid = validateNewsFields()
          if(isVAlid){
            console.log("finally payload for Survey",newSurvey)
              let resp =await postSurvey(newSurvey).catch((err)=>
              showToast({
                type: "error",
                title: "Fail to post Survey!",
                message: "error while uploading you survey plz try again."
              })
              )
              if(resp){
                navigate('/survey') 
                showToast({
                  type: "success",
                  title: "post Successfully",
                  message: "Your survey uploaded successfully"
                })

              }
  
          }
    }
  
    const validateNewsFields = () => {
      let isValid = true;
      if (!newSurvey.title) {
        showError(("Survey title is required"));
        isValid = false;
      } else if (!newSurvey?.start_date) {
        showError("Survey Start date is required");
        isValid = false;
      } else if (!newSurvey?.due_date) {
        showError("Survey Due date is required");
        isValid = false;
      } else if (!newSurvey?.user_created) {
        showError("Could not found user id");
        isValid = false;
      } else if (!newSurvey?.question_list.length) {
      } else if (!newSurvey?.date_created) {
        showError("Could not found date Created");
        isValid = false;
      } else if (!newSurvey?.question_list.length) {
        showError("Please add at least one Question");
        isValid = false;
      } 
      return isValid;
    };
    return {
     isSurveyCreating,
     setIsSurveyCreating,
     setNewSurvey,
     newSurvey,
    //  surveyList,
     handleSubmitSurvey
    }
  }
  
