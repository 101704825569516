import {
  Badge,
  Button,
  Checkbox,
  ScrollArea,
  Textarea,
  Timeline,
} from "@mantine/core";
import { type } from "os";
import React, { useContext, useEffect, useRef } from "react";
import { BsArrowUpRightCircleFill, BsTrashFill } from "react-icons/bs";
import { FiCheckCircle } from "react-icons/fi";
import ListView from "../../../../components/ListView";
import StatusButton from "../../../../components/StatusButton";
import { API_URL } from "../../../../utils/constants";
import useNewsDeatilDrawer from "../../hooks/useNewsDeatilDrawer";
import phoneOverlay from "../../../../assets/phone-overlay2.png";
import { ISelectedNews } from "../../../../utils/types";
import moment from "moment";
import { LoginContext } from "../../../../utils/context/AppContext";
import { Navigate, useNavigate } from "react-router-dom";
import EditNews from "../../../EditNews/EditNews";
import { changeStatus } from "../../../../utils/api/api";
import { Carousel } from "@mantine/carousel";
import { GrNext } from "react-icons/gr";

interface IProps {
  selectedNews: ISelectedNews;
  handleClick: () => void;
  handleClose: () => void;
}
export default function NewsDetailDrawer({
  selectedNews,
  handleClick,
  handleClose,
}: IProps) {
  const {
    isFirstLoad,
    setIsFirstLoad,
    opened,
    setOpened,
    handleUpdateStatus,
    handleChange,
    handleDeleteNews,
    approvedOrReject,
    setApprovedOrReject,
    comment,
    check,
    setCheck,
    setComment,
    handleCommentsAndFeedback,
    textArea,
    handleGetTimelineData,
    timeline,
    setTextArea,
    approvedByInfo,
    isCheckedMobilePreview,
    setIsCheckedMobilePreview
  } = useNewsDeatilDrawer();

  const { user } = useContext(LoginContext);
  const navigate = useNavigate();

  const nextRef = useRef()
  useEffect(() => {
    isFirstLoad
      ? setIsFirstLoad(false)
      : handleGetTimelineData(selectedNews?.id);
    // setApprovedOrReject(selectedNews?.status);
    if (
      selectedNews?.status === "approved" ||
      selectedNews?.status === "rejected"
    ) {
      setApprovedOrReject(selectedNews?.status);
    }
    //  selectedNews?.status === "approved" || selectedNews?.status === "rejected" ? (setApprovedOrReject(selectedNews?.status)) : ("")
  }, [isFirstLoad]);

  return (
    <div>
      {/* Header */}
      <div className="flex items-center justify-between pb-2 px-5 bg-white shadow-lg">
        <h3 className="">{selectedNews?.title}</h3>
        <Badge
          variant="filled"
          size="lg"
          className={`${selectedNews?.status === "approved"
            ? "bg-green-600"
            : selectedNews?.status === "pending"
              ? "bg-amber-500"
              : "bg-red-600"
            } ring-4 ring-white`}
        >
          {selectedNews?.status}
        </Badge>
      </div>

      {/* BODY */}
      <ScrollArea type='never' >
        <div className="grid grid-cols-2 gap-2 py-6 pb-20">
          {/* PREVIEW */}
          {selectedNews?.news_type === "carousel_news"  ?
            <div className="phone-mockup-carousel-wrapper">
              {isCheckedMobilePreview && <div className="phone-carousel-overlay">
                <img src={phoneOverlay} onClick={() => { console.log("clickOVerlay") }} alt="" />
              </div>}
              {/* image class change from div to img tag */}
              <div>

                <Carousel
                  // maw={320}
                  height={700}
                  mx="auto"
                  withIndicators
                  withControls={!isCheckedMobilePreview}
                  className='carousel'
                ><Carousel.Slide>
                <img
                  src={`${API_URL}assets/${selectedNews?.urlToImage}`}
                  alt=""
                  className=""
                  // className="rounded-3xl pt-3 items-stretch h-full"
                /> 
                
                </Carousel.Slide>
                  {selectedNews?.carousel_image?.map((e) =>
                    <Carousel.Slide>
                      <img
                        src={`${API_URL}assets/${e?.directus_files_id}`}
                        alt=""
                        className=""
                        // className="rounded-3xl pt-3 items-stretch h-full"
                      /> 
                      
                      </Carousel.Slide>)}
                </Carousel>
              </div>
              <div className=" footnote">
                <p>
                  <span className=''>
                    <Checkbox
                      checked={isCheckedMobilePreview}
                      onChange={() => setIsCheckedMobilePreview(!isCheckedMobilePreview)}
                      label="Preview shown on iPhone 14 Pro"
                    /> </span> </p>
              </div>
            </div>
            :
            <div className="phone-mockup-wrapper">
              <div className="phone-overlay">
                <img src={phoneOverlay} onClick={() => { console.log("clickOVerlay") }} alt="" />
              </div>
              {/* image class change from div to img tag */}
              <div>
                <img
                  className="image"
                  src={`${API_URL}assets/${selectedNews?.urlToImage}`}
                  alt=""
                />
              </div>
              <div className=" footnote">
                <p>
                  Preview shown on iPhone 14 Pro </p>
              </div>
            </div>}

          {/* DETAILS */}
          <div>
            {/* STATUS */}
            <div>
              {selectedNews?.status === "approved" ? (
                <div className="mb-6 p-6 bg-gradient-to-tl  from-green-600 to-teal-600  rounded-xl flex">
                  <div className="mr-3">
                    <FiCheckCircle size={44} className="text-white" />
                  </div>
                  <div>
                    <h4 className="text-white font-bold mb-4 leading-6">
                      This news is approved and showing in the app
                    </h4>
                    <p className="text-xs text-white opacity-80">
                      If you want to remove this news, please contact your
                      superadmin.
                    </p>
                  </div>
                </div>
              ) : user?.role === "editor" ? (
                <div className="mb-3 p-6 bg-gradient-to-tr from-slate-900 to-gray-700 rounded-xl">
                  <div className="flex items-center">
                    <div className="mb-4 w-full">
                      {/* <div className="form-label">Select Status</div> */}
                      <div className="flex flex-1  gap-3">
                        <StatusButton
                          type="approve"
                          onClick={function (): void {
                            setApprovedOrReject("approved");
                          }}
                          selected={
                            approvedOrReject === "approved" ? true : false
                          }
                        />
                        <StatusButton
                          type="reject"
                          onClick={function (): void {
                            // if (textArea.length === 0) {
                            //   showToast({
                            //     title: t("Comment is required"),
                            //     message: t(
                            //       "Please write some comments / feedback."
                            //     ),
                            //   });
                            // }
                            setApprovedOrReject("rejected");
                          }}
                          selected={
                            approvedOrReject === "rejected" ? true : false
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    {/* <div className="form-label text-white">Comments</div> */}
                    <Textarea
                      value={textArea}
                      onChange={(e) => handleChange(e)}
                      placeholder="e.g.(Change the title of this news from Hi to 'Hello') "
                      description="You must provide comments for rejected items"
                    // size="md"
                    />
                  </div>
                  <div className="mt-3 flex gap-1 justify-end">
                    <Button
                      className=""
                      variant="white"
                      onClick={() => {
                        handleClose();
                        setTextArea("");
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="bg-blue-500"
                      disabled={
                        approvedOrReject === "" ||
                          (approvedOrReject === "rejected" && textArea === "")
                          ? true
                          : false
                      }
                      onClick={() => {
                        handleUpdateStatus(
                          +selectedNews?.id,
                          textArea,
                          handleClick,
                          selectedNews?.status
                        );
                        handleCommentsAndFeedback();
                      }}
                    >
                      Update Status
                    </Button>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>

            {/* NEWS INFO */}
            <div className="mb-6">
              <ListView
                label={"Created by"}
                description={
                  <>
                    {selectedNews?.created_by?.first_name}{" "}
                    {selectedNews?.created_by?.last_name} (
                    {selectedNews?.created_by?.role?.name}) on{" "}
                    {moment(selectedNews?.publishedAt).format("DD/MM/YYYY")}
                  </>
                }
              />
              <ListView
                label={"Approved by"}
                description={
                  approvedByInfo ? (
                    <>
                      {approvedByInfo?.user_created?.first_name} on{" "}
                      {moment(approvedByInfo?.date_created).format(
                        "DD/MM/YYYY"
                      )}
                    </>
                  ) : (
                    <> News is in Pending State</>
                  )
                }
              />

              <ListView
                label={"Provider"}
                description={
                  <div className="flex  justify-end gap-2 ">

                    <div className="font-semibold text-green-500 mt-2">
                      {selectedNews?.provider_id?.name}
                    </div>
                    <>
                      <img
                        height={35}
                        width={35}
                        src={`${API_URL}assets/${selectedNews?.provider_id?.icon}`} alt="img"
                      />
                    </>
                  </div>
                }
              />

              <ListView
                label={"News Article Link"}
                description={
                  <>
                    <a
                      href={selectedNews?.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 inline-flex items-center gap-1 font-medium ml-2"
                    >
                      News Link <BsArrowUpRightCircleFill />
                    </a>
                  </>
                }
                noBorder
              />

              {/* {selectedNews?.status === "approved"
                ?
                <></>
                :
                user?.id === selectedNews?.created_by?.id ||
                  user?.role === "editor"
                  ?
                  <div className="text-right">
                    <Button
                      variant="outline"
                      size="xs"
                      onClick={() =>
                        navigate(`/newsList/editNews/${selectedNews?.id}`)
                      }
                    >
                      Edit This News
                    </Button>
                  </div>
                  :
                  <></>} */}
            </div>

            {/* Timeline */}
            <div className="my-10">
              <div className="text-xs uppercase font-bold text-slate-300 mb-3">
                Timeline, latest first
              </div>
              <Timeline lineWidth={2} bulletSize={20}>
                {timeline?.map((each, index) => (
                  <Timeline.Item key={index + "timeline"}>
                    <div className="timeline-title">
                      <span
                        className={`font-bold 
                      ${each.status === "approved"
                            ? "text-green-600"
                            : each.status === "rejected"
                              ? "text-red-600"
                              : ""
                          }
                      inline-flex items-baseline capitalize`}
                      >
                        {each.status}
                      </span>{" "}
                      by{" "}
                      {each.user_created?.first_name
                        ? each.user_created?.first_name
                        : "" + each.user_created?.last_name
                          ? each.user_created?.last_name
                          : ""}{" "}
                      ({each.user_created.role.name})<p>{each.comment}</p>
                    </div>
                    <div className="timeline-date">
                      {moment(each.date_created).format("DD/MM/YYYY")}
                    </div>
                  </Timeline.Item>
                ))}
              </Timeline>
            </div>

            {/* Delete Box */}
            {
            selectedNews?.status === "apprgoved" ? (
              <></>
            ) :
             user?.id === selectedNews?.created_by?.id ||
              user?.role === "editor" ? (
              <div className="flex flex-row items-center justify-between my-3 bg-red-50 p-4 rounded-lg border border-red-100">
                <Checkbox
                  onChange={() => {
                    setCheck(!check);
                  }}
                  label="I confirm and want to delete this news"
                  className="opacity-80"
                  color={"red"}
                // size="xs"
                />
                <div className="ml-3">
                  <Button
                    variant="filled"
                    // size="xs"
                    leftIcon={<BsTrashFill />}
                    className="bg-red-500 hover:bg-red-600"
                    onClick={() =>
                      handleDeleteNews(selectedNews?.id, handleClick)
                    }
                    disabled={!check}
                  >
                    Delete News
                  </Button>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </ScrollArea>
    </div>
  );
}
