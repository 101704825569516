import React, { useContext, useEffect, useState } from 'react'
import {ISurveyList,  } from '../../../utils/types'
import { showToast } from '../../../utils/utility';
import { fetchSurvey, postSurvey } from '../../../utils/api/api';
import { LoginContext } from '../../../utils/context/AppContext';

export default function useSurvey() {
  const { user } = useContext(LoginContext);
  
  const [isOpenDrawer,setIsOpenDrawer]=useState<boolean>(false)
  const [surveyList,setSurveyList]=useState<ISurveyList[]>([])
  const [selectedSurvey,setSelectedSurvey]=useState<ISurveyList>()
  const [surveyStatus,setSurveyStatus]= useState<"published"|"archived">("published")

  useEffect(()=>{
    getSurvey()
  },[surveyStatus])

  const getSurvey = async()=>{
  if(user?.id){

    const res = await fetchSurvey(user?.id ,surveyStatus).catch(
      (err)=>
      showToast({
        type: 'error',
        message:err.message ,
        title:"Could not get survey list"
      }) )
      if(res?.data){
               setSurveyList(res?.data?.data)
      }
}
  }
  return {
   surveyList,
   setSurveyStatus,
   isOpenDrawer,
   setIsOpenDrawer,
   selectedSurvey,
   setSelectedSurvey
  }
}
