import { useState } from "react";
import { showToast } from './../../../utils/utility';
import { INewTagToBeAdded, ITags } from "../../../utils/types";
import { addTags, getNewsTags, uploadNewTags } from "../../../utils/api/api";
import { useTranslation } from "react-i18next";

interface IProps {
    id: string;
    tags: string[] | undefined;
    handleClick: () => void;
    imageNewsUrl? : string
}

export default function useUpdateUploadedImage({
    id,
    tags,
    handleClick,
    imageNewsUrl
}: IProps) {

    const [firstLoad, setFirstLoad] = useState<boolean>(true);

    const [availableTags, setAvailableTags] = useState<ITags[]>([]);
    const [selectedTags, setSelectedTags] = useState<string[]>(tags as string[]);
    const [newTags, setNewTags] = useState<string[]>([]);
    const [tag, setTag] = useState<string>("");
    const [combinedTags, setCombinedTags] = useState<string[]>([]);
    const { t } = useTranslation();
    const [isAddingNewTag, setIsAddingNewTag] = useState<boolean>(false);
    const [isUploading, setIsUploading] = useState<boolean>(false);

    //   HANDLER FUNCTIONS
    const handleAddSelectedTags = (tags: string[]) => {
        setSelectedTags(tags);
    };

    const handleRemoveTag = (tag: string) => {
        if (selectedTags.length < 2) {
            showToast({
                type: "warning",
                title: t("Not able to remove last tag!"),
                message: t("Please add another tag to remove it!"),
            });
        } else {
            let filteredTags = selectedTags?.filter(
                (each) => each.toLowerCase() !== tag.toLowerCase()
            );
            // newTags.includes(tag) && setNewTags(tg => tg.filter(t => t.toLowerCase() !== tag.toLowerCase()));
            // setExistingTags(filteredTags);
            setSelectedTags(filteredTags);
        }
    };

    // FOR NEW TAGS
    const handleAddNewTag = (handleClick?: () => void) => {
        !newTags.includes(tag) && setNewTags(prev => ([...prev, tag]));
        // !selectedTags.includes(tag) && setSelectedTags(prev => ([ ...prev, tag ]));
        setTag("");
    };

    const handleRemoveNewTags = (tag: string) => {
        let filteredTags = newTags?.filter(
            (each) => each.toLowerCase() !== tag.toLowerCase()
        );
        setNewTags(filteredTags);
    };

    //   API CALLS
    const handleFetchTags = () => {
        getNewsTags().then(
            response => {
                // console.log("Response for tags: ", response.data?.data);
                setAvailableTags(response.data?.data);
            }, error => {
                console.log("Error for tags fetch: ", error?.response);
                showToast({
                    type: "error",
                    title: t("Something Went Wrong!"),
                    message: t("Could not fetch tags")
                });
            }
        );
    };

    const AddTagToApi = (handleClick?: () => void) => {
        // let oldSelectedTags = selectedTags.filter(e => !newTags.some(t => t === e));
        // console.log("Selected tags: ", selectedTags);
        // console.log("Old tags: ", oldSelectedTags);
        // console.log("New tags: ", newTags);

        if (newTags.length > 0) {
            handleUploadNewTags();
        } else handleUpdateTagsForImage(handleClick);
    };

    const handleUploadNewTags = () => {
        const newTagsPayload: INewTagToBeAdded[] = newTags.map(each => Object.assign({
            name: each,
            categories: [{ category_id: 36 }]
        }));
        handleUpdateTagsForImage(handleClick);
        uploadNewTags(newTagsPayload).then(
            response => {
                console.log("New tags has been added successfully!");
                handleFetchTags();
                // handleUpdateTagsForImage();
            }, error => {
                console.log("Error in add new tags: ", error?.response);
                showToast({
                    type: "error",
                    title: t("Something Went Wrong!"),
                    message: t("Could not create new tags! Try again later.")
                });
            }
        );
    };

    const handleUpdateTagsForImage = async (handleClick?: () => void) => {
        let tagList = [...selectedTags, ...newTags];
        console.log(tagList);
        if (tagList.length === 0) {
            showToast({
                type: "warning",
                message: t("Tags can not be empty. Please select at least one tag.")
            });
            return;
        }
        setIsUploading(true);
        addTags(id, tagList).then(
            response => {
                showToast({
                    type: "success",
                    message: t("Tags have been updated successfully!")
                });
                
            handleClick && handleClick();
            }, error => {
                console.log("Error in updating tags: ", error?.response);
                showToast({
                    type: "error",
                    title: t("Something Went Wrong!"),
                    message: t("Could not update tags.")
                });
            }
        );
        setIsUploading(false);
    };

    return {
        firstLoad,
        setFirstLoad,
        availableTags,
        handleFetchTags,
        handleAddNewTag,
        // handleAddNewTags,
        handleRemoveTag,
        AddTagToApi,
        handleAddSelectedTags,
        selectedTags,
        newTags,
        isAddingNewTag,
        setIsAddingNewTag,
        tag,
        setTag,
        handleRemoveNewTags,
        isUploading
    };
}
