import { BsImages, BsNewspaper, BsPlusCircleFill } from "react-icons/bs";
import { HiViewGridAdd} from "react-icons/hi";
import { MdPreview} from "react-icons/md";
import AddNews from "../screens/AddNews/AddNews";
import CreatorNews from "../screens/AllNews/CreatorNews";
import EditNews from "../screens/EditNews/EditNews";
import NewsDetail from "../screens/NewsDetailScreen/NewsDetail";
import UploadedImage from "../screens/UploadedImage/UploadedImage";
import AddCarouselNews from "../screens/AddCarouselNews/AddCarouselNews";
import Survey from "../screens/Survey/Survey";
import CreateNewSurvey from "../screens/Survey/CreateNewSurvey";

export interface IRoute {
    path: string;
    label: string;
    element: any;
    icon?: any;
    roleAllowed?: string[];
};

export const ROUTES: {
    ALL_ROUTES: IRoute[];
    CREATOR_ROUTES: IRoute[];
    UPLOADER_ROUTES: IRoute[];
    EDITOR_ROUTES: IRoute[];
} = {
    ALL_ROUTES: [
        // {
        //     path: '/new',
        //     label: 'Create News',
        //     element: AddNews,
        //     icon: BsPlusCircleFill,
        //     roleAllowed: ["creator", "editor"]
        // },
        {
            path: '/new',
            label: 'Create News',
            element: AddCarouselNews,
            icon:  BsPlusCircleFill,
            roleAllowed: ["creator", "editor"]
        },
        {
            path: '/newsList',
            label: 'News list',
            element: CreatorNews,
            icon: BsNewspaper,
            roleAllowed: ["creator", "editor"]
        },
        {
            path: '/uploaded-image',
            label: 'Image Repository',
            element: UploadedImage,
            icon: BsImages,
            roleAllowed: ["uploader", "editor", "creator"]
        },
        {
            path: '/survey',
            label: 'Survey Preview',
            element: Survey,
            icon: MdPreview,
            roleAllowed: [""]
        },
        // {
        //     path: '/news',
        //     label: 'Available News',
        //     element: AllNews,
        //     icon: BsNewspaper,
        //     roleAllowed: ["editor"]
        // },
        {
            path: '/news/:id',
            label: '',
            element: NewsDetail,
            icon: "",
            roleAllowed: ["editor", "creator"]
        },
        {
            path: 'survey/newSurvey',
            label: '',
            element: CreateNewSurvey,
            icon: '',
            roleAllowed: ["editor"]
        },
    ],

    CREATOR_ROUTES: [
        // {
        //     path: '/new',
        //     label: 'Create News',
        //     element: AddNews,
        //     icon: BsNewspaper,
        // },
        {
            path: '/new',
            label: 'Create News',
            element: AddCarouselNews,
            icon:  BsPlusCircleFill,
        },
        {
            path: '/newsList',
            label: 'News list',
            element: CreatorNews,
            icon: BsImages,
        },
        {
            path: '/uploaded-image',
            label: 'Image Repository',
            element: UploadedImage,
            icon: "",
        },
        {
            path: '/survey',
            label: 'Survey Preview',
            element: Survey,
            icon: MdPreview
        },
      
        {
            path: '/news/:id',
            label: '',
            element: NewsDetail,
            icon: "",
        },
        {
            path: '/newsList/editNews/:id',
            label: '',
            element: EditNews,
            icon: "",
        },
    ],

    UPLOADER_ROUTES: [
        {
            path: '/uploaded-image',
            label: 'Image Repository',
            element: UploadedImage,
            icon: "",
        },
    ],

    EDITOR_ROUTES: [
        // {
        //     path: '/new',
        //     label: 'Create News',
        //     element: AddNews,
        //     icon: BsNewspaper,
        // },
        {
            path: '/new',
            label: 'Create News',
            element: AddCarouselNews,
            icon:  BsPlusCircleFill,
        },
        {
            path: '/newsList',
            label: 'News list',
            element: CreatorNews,
            icon: BsImages,
        },
        {
            path: '/survey',
            label: 'Survey Preview',
            element: Survey,
            icon: MdPreview
        },
        {
            path: 'survey/newSurvey',
            label: '',
            element: CreateNewSurvey,
            icon: ''
        },
        // {
        //     path: '/news',
        //     label: 'Available News',
        //     element: AllNews,
        //     icon: "",
        // },
        {
            path: '/news/:id',
            label: '',
            element: NewsDetail,
            icon: "",
        },
        {
            path: '/uploaded-image',
            label: 'Image Repository',
            element: UploadedImage,
            icon: "",
        },
        {
            path: '/newsList/editNews/:id',
            label: '',
            element: EditNews,
            icon: "",
        },
    ]
};