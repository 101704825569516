import React, { useEffect, useRef, useState } from "react";
import { Button, LoadingOverlay, Pagination } from "@mantine/core";
import { useTranslation } from "react-i18next";

import { EModal } from "../../../libs/ui";
import { ImageSection } from "../../../components/ImageSection";

import { API_URL } from "../../../utils/constants";
import { getImageList } from "../../../utils/api/api";
import { useOnLoadImages } from "../Hooks/useOnLoadImages";
import { IImageData, ISelectedBgImageFromModal } from "../../../utils/types";
import { handleFormatUploadedImage, showToast } from "../../../utils/utility";

type TypeBGImageModal = {
  isOpen: boolean;
  onSelect: (url: ISelectedBgImageFromModal) => void;
  onClose: () => void;
};

export default function BgImageModal({
  isOpen,
  onClose,
  onSelect,
}: TypeBGImageModal) {

  const { t } = useTranslation();

  const LIMIT = 100;

  const wrapperRef = useRef<HTMLDivElement>(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const [data, setData] = useState<IImageData[]>([]);
  const [page, setPage] = useState<{
    totalAvailablePages: number;
    currentPage: number;
  }>({
    totalAvailablePages: 1,
    currentPage: 1
  });
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [selectedImageUrl, setSelectedImageUrl] = useState<ISelectedBgImageFromModal>({
    imgUrl: "",
    imgId: "",
    newsUrlByImage: ""
  });
  // const [selectedImageId, setSelectedImageId] = useState<string>("");

  const [loadedImageCount, setLoadedImageCount] = useState<number>(0);


  useEffect(() => {
    if (isFirstLoad) {
      setIsFirstLoad(false);
    } else {
      if (isOpen) {
        getImageData(1);
      } else {
        handleResetStates();
      };
    };
    return () => {
      console.log("Unmounted!!!");
      handleResetStates();
    };

  }, [isFirstLoad, isOpen]);


  // HANDLER FUNCTION
  const handleResetStates = () => {
    setData([]);
    setLoadedImageCount(0);
    setPage({
      totalAvailablePages: 1,
      currentPage: 1
    });
  };

  // API CALLS
  const getImageData = async (selectedPage:number) => {

    try {

      setIsLoading(true);
      setData([]);
      setLoadedImageCount(0);
      const resp = await getImageList(selectedPage, LIMIT).catch((err) => {
        console.log("Error while fetching image files: ", err?.response);
        setIsLoading(false);
        showToast({
          type: "error",
          title: t("Something Went Wrong!"),
          message: t("Could not fetch image list"),
        });
      });

      // calculate and set total numbe rof available pages
      const totalPages = Math.ceil((resp?.data?.meta?.filter_count || 1) / LIMIT);
      setPage({currentPage:selectedPage ?? 1, totalAvailablePages: totalPages });

      // format and set data
      const formatImages = handleFormatUploadedImage(resp?.data?.data);
      const newImageData = formatImages;
      setData(newImageData || []);
      setIsLoading(false);

    } catch (error) {

      console.log("Error while fetching image files: ", error);

    };
  };
  return (
    <div>
      <EModal
        isOpen={isOpen}
        title={
          <div>
            <p className="font-bold text-2xl xl:mr-2 w-full">
              {t("Select Background Image")}
            </p>
            <p>
            {`page: ${page?.currentPage}/${page?.totalAvailablePages}`}
            </p>
          </div>}
        setIsOpen={() => onClose()}
        closeOnClickOutside
        size={"90vw"}
        children={
          <>
            <div className="flex w-full justify-between mb-6">

              {/* <TextInput
              placeholder="Search background..."
              className="ml-24"
              icon={<FiSearch />}
            /> */}
            </div>
            <div className="relative">
              <div className="image-repository-container pb-6">

                {loadedImageCount !== data.length
                  ?
                  <LoadingOverlay visible={true} overlayBlur={2} />
                  :
                  <></>}

                {data?.map((each, index) => {
                  return (
                    <div
                      key={index + "url"}
                      ref={wrapperRef}
                      className={`bg-slate-100 h-[460px] ${selectedImageUrl.imgUrl ===
                        `${API_URL}assets/${each?.url}`
                        ? "selected"
                        : ""
                        }  `}
                      onClick={() => {
                        console.log("loadedImageCount: ", loadedImageCount);
                        console.log("data.length: ", data.length);
                        if (loadedImageCount === data.length) {
                          setSelectedImageUrl({
                            imgUrl: `${API_URL}assets/${each?.url}`,
                            imgId: each?.id,
                            newsUrlByImage: each?.imageNewsUrl
                          });
                          // setSelectedImageUrl(`${API_URL}assets/${each?.url}`);
                        };
                      }}
                    >
                      <ImageSection
                        url={each?.url}
                        tags={each?.tags}
                        uploadedBy={each?.uploadedBy}
                        uploadedOn={each?.uploadedOn}
                        fileName={each?.fileName}
                        id={each?.id}
                        handleClick={() => { }}
                        setLoadedImageCount={setLoadedImageCount}
                        />
                    </div>
                  );
                })}

                </div>
                <div className="w-full flex justify-center mt-6">
                <Pagination
                total={page.totalAvailablePages}
                siblings={2}
                boundaries={2}
                defaultValue={page.currentPage}
                styles={(theme) => ({
                  control: {
                    '&[data-active]': {
                      backgroundImage: theme.fn.gradient({ from: 'red', to: 'yellow' }),
                      border: 0,
                    },
                  },
                })}
                onChange={(value) => getImageData(value)}
              />
                  {/* <Button
                    className="bg-blue-600"
                    loading={isLoading}
                    onClick={() => getImageData()}
                  >
                    {t("Fetch More... Page:")} {page.currentPage} {"/"} {page.totalAvailablePages}
                  </Button> */}
                </div>

              <div className="sticky bottom-0">
                <div className="flex justify-end p-3 bg-white gap-4">
                  <Button
                    variant="light"
                    className=" bg-slate-200 ml-auto "
                    onClick={() => {
                      setSelectedImageUrl({
                        imgUrl: "",
                        imgId: "",
                      })
                      onClose()
                    }}
                  >
                    {t("Cancel")}
                  </Button>
                  <Button
                    className="bg-blue-600"
                    disabled={selectedImageUrl?.imgUrl === ""}
                    onClick={() => onSelect(selectedImageUrl)}
                  >
                    {t("Use Selected Background")}
                  </Button>
                </div>
              </div>
            </div>
          </>}
      />
    </div>
  );
}
