import { Group, Radio, Select, TextInput ,Textarea } from '@mantine/core'
import { DateInput, DatePicker } from '@mantine/dates'
import moment, { now } from 'moment'
import React, { useEffect, useState } from 'react'
import { INewSurveyDetail } from '../../../utils/types'

type IProps ={
    handleSurveyDetail: (e:INewSurveyDetail)=>void ;
}
export default function AddNewSurveyDetails({handleSurveyDetail}:IProps) {
    const [newSurvey, setNewSurvey] = useState<INewSurveyDetail>({
        status: "published",
        user_created: '',
        title: '',
        description: '',
        start_date: '',
        due_date: '',
    })
  
     useEffect(()=>{
        handleSurveyDetail(newSurvey)
     },[newSurvey])
    return (
        <div className='flex flex-col gap-2' >
            {/* <h2>Add new Survey</h2> */}
            <TextInput
                label="Survey Title"
                withAsterisk
                value={newSurvey?.title || ""}
                onChange={(e) => setNewSurvey({ ...newSurvey, title: e?.target?.value })}
            />
            <Textarea
                label="Survey Description"
                withAsterisk
                value={newSurvey?.description || ""}
                onChange={(e) => setNewSurvey({ ...newSurvey, description: e?.target?.value })}
            />
                <div className='flex  gap-4'>
                    <DateInput 
                        className='grow'
                        label="Start Date"
                        onChange={(e: any) => setNewSurvey({ ...newSurvey, start_date: moment(e).format("YYYY-MM-DD") })}
                    />
                    <DateInput
                        label="Due Date"
                        className='grow'
                        onChange={(e: any) => setNewSurvey({ ...newSurvey, due_date: moment(e).format("YYYY-MM-DD") })}
                    />
                </div>
                 <Select
                  label='Survey Status'
                  className='w-1/2'
                  data={["published","draft","archived"]} 
                  value={newSurvey?.status}
                  onChange={(e)=> e && setNewSurvey({ ...newSurvey, status: e })}  
                                 />
                    {/* <Radio.Group
                        value={newSurvey?.status}
                        onChange={(e) => setNewSurvey({ ...newSurvey, status: e })}
                        label={"Survey Status"}
                    >
                        <Group >
                            <Radio
                                name='QuestionStatus'
                                value={"published"}
                                label="Published"
                            />
                            <Radio
                                name='QuestionStatus'
                                label="Draft"
                                value={"draft"}
                            />
                            <Radio
                                name='QuestionStatus'
                                label="Archived"
                                value={"archived"}
                            />
                        </Group>
                    </Radio.Group> */}
        </div>
    )
}
