import React, { useEffect, useState } from "react";
import {
  MultiSelect,
  CloseButton,
  Button,
  Badge,
  TextInput,
  ActionIcon,
} from "@mantine/core";
import { ImCross } from "react-icons/im";

import NewsContextWrapper from "../../../utils/context/AddNewsContext";
import { showToast } from "../../../utils/utility";
import UploadeImage from "../../ImageUploader/component/UploadeImage";
import useImagUploader from "../../ImageUploader/useImagUploader";
import { useTranslation } from "react-i18next";
import { image } from "html2canvas/dist/types/css/types/image";

type Props = {};
interface IImage {
  handleClick: () => void;
}
export default function UploadImageModal({ handleClick }: IImage) {
  const {
    handleSelectTag,
    selectedTags,
    setSelectedTags,
    newTags,
    handleRemoveTag,
    selectedFile,
    setSelectedFile,
    getMeta,
    uploadImage,
    isUploading,
    clearInputs,
    availableTags,
    handleAddNewTag,
    handleAddSelectedTags,
    newsUrl,
    setNewsUrl,
  } = useImagUploader();

  const { t } = useTranslation();
  const removeButton = (
    <ActionIcon size="xs" color="blue" radius="xl" variant="transparent">
      <ImCross size={10} />
    </ActionIcon>
  );

  function isUrlValid(str: string) {
    const pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', // fragment locator
      'i'
    );
    return pattern.test(str);
  }

  const handleSuccess = () => {
    if (newsUrl) {
      if (!isUrlValid(newsUrl)) {
        showToast({
          type: "error",
          message: "Please provide valid News url"
        });
      }
      else {
        uploadImage(handleClick);
        setSelectedTags([]);
      }
    } else {
      if (!selectedTags.length) {
        showToast({
          type: "warning",
          title: t("Incomplete information !"),
          message: t("Please Select Image with there tags!"),
        });
      }
      else {
        uploadImage(handleClick);
        setSelectedTags([]);
      }
    }
  };


  return (
    <NewsContextWrapper>
      <div className="flex gap-6">
        <div className="image-modal">
          {selectedFile ? (
            <img src={URL.createObjectURL(selectedFile)} alt="Thumb" />
          )
            :
            <div className=" flex h-full border rounded-xl items-center justify-center  ">
              <h5 className=" opacity-30">Image Preview</h5>
            </div>}
          {!selectedFile && (
            <div className=" flex items-center border-2 h-[652px] justify-center  ">
              <h5 className="text-black opacity-30">{t("Image Preview")} </h5>
            </div>
          )}


        </div>
        <div className="flex flex-1 h-[720px] flex-col">
          <div className="flex-1 justify-between">
            <UploadeImage onSelect={(file) => {
              getMeta(URL.createObjectURL(file), file);
            }}
            />
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSelectTag();
              }}
              className="pt-6 flex flex-col"
            >
              {/* <TextInput
                value={tag}
                placeholder="Enter a Tag"
                label="Tags"
                onChange={(e) => setTag(e.target.value)}
              /> */}
              <MultiSelect
                label="Tags"
                size="md"
                searchable
                creatable
                limit={8}
                nothingFound
                placeholder={t("Select a Tag")}
                // value={selectedTags}
                data={availableTags?.map((each) => {
                  return { label: each?.name, value: each?.name };
                })}
                getCreateLabel={(query) => `+ Create ${query}`}
                onCreate={(query) => {
                  const item = { value: query, label: query };
                  handleAddNewTag(item.value);
                  return item;
                }}
                onChange={(tagList) => {
                  handleAddSelectedTags(tagList);
                  console.log(tagList);
                }}
              />
              {/* <Button
                variant="outline"
                className="mt-4 ml-auto"
                disabled={tag.length === 0}
                type="submit"
              >
                Add Tag
              </Button> */}
              {/* <p className="text-sm font-semibold py-3">
                {t("Selected Tags:")}
              </p> */}
              <div className="flex flex-wrap gap-2 hidden">
                {selectedTags?.length > 0 ? (
                  <div className="tag-container">
                    {selectedTags?.map((each, index) => (
                      <div className="tag tag--lg" key={index + "tag"}>
                        {each}
                        <div className="close-btn">
                          <CloseButton
                            size="sm"
                            iconSize={16}
                            onClick={() => handleRemoveTag(each)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  // selectedTags?.map((tag, index) => (
                  //   <Badge
                  //     key={index}
                  //     className="flex flex-row rounded-md"
                  //     variant="outline"
                  //     sx={{ paddingRight: 3 }}
                  //     size="lg"
                  //     rightSection={removeButton}
                  //     onClick={() => handleRemoveTag(tag)}
                  //   >
                  //     {tag}
                  //   </Badge>
                  // ))
                  <p className="mx-auto text-gray-400">{t("No Tags Added")}</p>
                )}
              </div>
              <TextInput
                type="url"
                className="mt-3"
                placeholder="https://wwww.google.com"
                label="Image news url"
                value={newsUrl}
                onChange={(e) => setNewsUrl(e?.target.value)}
              />
            </form>
          </div>
          <div className="flex gap-2 justify-end">
            <Button
              variant="outline"
              onClick={() => {
                handleClick();
                clearInputs()
              }}
            >
              {t("Cancel")}
            </Button>
            <Button
              variant="filled"
              className="bg-primary"
              loading={isUploading}
              disabled={!selectedFile}
              onClick={() => {
                handleSuccess()
              }}
            >
              {t("Save")}
            </Button>
          </div>
        </div>
      </div>
    </NewsContextWrapper>
  );
}
