import { Button, PasswordInput, TextInput } from "@mantine/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { BsExclamationSquareFill } from "react-icons/bs";
import useAuth from "../../utils/hooks/useAuth";

const Login = () => {
  const { login, isLoggingIn } = useAuth();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [errorPass, setErrorPass] = useState("");
  const { t } = useTranslation();
  const valid = (email: string, password: string) => {
    var re = /\S+@\S+\.\S+/;
    var pas = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&^_-]{8,}$/;
    if (re.test(email) === false) {
      setError("Enter a valid email");
    } else if (password.length === 0) {
      setErrorPass("Enter a valid Password");
    } else {
      setError("");
      setErrorPass("");
      login(email, password);
    }
  };

  return (
    <div className="w-full h-screen bg-slate-900 flex items-center justify-center ">
      <div className="w-[500px] bg-white flex flex-col rounded-2xl shadow-2xl p-10">
        <h2 className="mb-3">Headlines Admin</h2>
        <p className="opacity-60">{t("Login with your credentials below.")}</p>

        <div className="my-6">
          <TextInput
            label={t("Username / Email Address")}
            placeholder={t("Enter username or email address")}
            className="mt-3 w-full"
            size="md"
            value={email}
            onChange={(event) => setEmail(event.currentTarget.value)}
            error={error}
          />
          <PasswordInput
            label={t("Password")}
            placeholder={t(" Password")}
            className="mt-3 w-full"
            size="md"
            value={password}
            error={errorPass}
            onChange={(event) => setPassword(event.target.value)}
          />
        </div>
        <div className="flex w-full gap-6 mt-3">
          <Button
            size="md"
            loading={isLoggingIn}
            className="bg-blue-600 w-full "
            onClick={() => valid(email, password)}
          >
            {t("Log In")}
          </Button>
        </div>
      </div>
    </div>
  );
};
export default Login;
