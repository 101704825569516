import { useContext, useEffect } from "react";
import { AppShell, Container, MantineProvider } from "@mantine/core";
import "./App.scss";
import { LoginContext } from "./utils/context/AppContext";
import Login from "./screens/Login/Login";
import Navbar from "./components/Navbar";
import axios from "axios";
import { API_URL } from "./utils/constants";
import SelectedNewsProvider from "./utils/context/SelectedNewsContext";
import MainRoutes from "./routes/MainRoutes";
import "./Translate";

function App() {
  const { token } = useContext(LoginContext);
  const { isLoggedIn, setSelectedLanguage } = useContext(LoginContext);
  const getToken = () => {
    return localStorage.getItem("token") || token;
  };
  axios.defaults.baseURL = API_URL;
  axios.interceptors.request.use((request) => {
    if (!request.url?.includes("auth")) {
      request.headers = {
        Authorization: `Bearer ${getToken()}`,
      };
    }
    return request;
  });
  useEffect(() => {
    const lang = localStorage.getItem("language") || "en";
    setSelectedLanguage(lang);
    setDefalutNewsLanguage();
  }, []);
  const setDefalutNewsLanguage = () => {
    localStorage.setItem("newsLanguage", String(1));
  };
  return (
    <MantineProvider withGlobalStyles withNormalizeCSS>
      <SelectedNewsProvider>
        {!isLoggedIn ? (
          <Login />
        ) : (
          <AppShell
            className="flex flex-1 overflow-x-hidden"
            padding={"md"}
            navbar={<Navbar />}
            styles={() => ({
              main: {
                padding: 0,
                paddingLeft: 320,
              },
            })}
          >
            <Container fluid className=" h-full overflow-hidden bg-slate-50 pr-3 pl-8">
              <MainRoutes />
            </Container>
          </AppShell>
        )}
      </SelectedNewsProvider>
    </MantineProvider>
  );
}

export default App;
