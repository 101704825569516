import React, { useContext, useEffect, useRef, useState } from 'react'
import { deleteFileById, showToast } from '../../../utils/utility';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import html2canvas from 'html2canvas';
import { TypePostAddNews } from '../../../utils/types';
import { UploadNewsTimelineStatus, deleteImage, draftNews, uploadFile } from '../../../utils/api/api';
import { AddNewsContext } from '../../../utils/context/AddNewsContext';
import { useScrollIntoView } from '@mantine/hooks';

const showError = (error: string) => {
  showToast({
    message: error,
    type: "error",
  });
};
export default function useAddCarouselNews() {

  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    clearContext,
    backgroundUrl,
    image,
    newsTitle,
    newsHeadline,
    language,
    provider,
    newsUrl,
    scaleRef,
    headerLogoRef,
    headerProviderLogoRef,
    CanvasViewRef,
    guidelineRef,
    categories,
    carouselImage,
    newsType,
    updateAddNewsInfo,
  } = useContext(AddNewsContext);

  // loading
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
  const [isCreatingNews, setIsCreatingNews] = useState<boolean>(false);
  const [isClickedMore, setIsClickedMore] = useState<boolean>(false)
  const [newsImageID, setNewsImageID] = useState<string>("")
  const [newsImageToShareID, setNewsImageToShareID] = useState<string>("")

  let titleRef = useRef<HTMLDivElement>(null);
  let backgroundImageRef = useRef<HTMLCanvasElement>(null);
  const { scrollIntoView, targetRef, scrollableRef } = useScrollIntoView<
    HTMLDivElement,
    HTMLDivElement
  >();

  useEffect(() => {
    if (isClickedMore) {
      scrollIntoView()
    }
  }, [isClickedMore])

  // HANDLER FUNCTIONS
  
  function isUrlValid(str:string) {
    const pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', // fragment locator
      'i'
    );
    return pattern.test(str);
   }

  const validateNewsFields = () => {
    let isValid = true;
    if (backgroundUrl.length === 0) {
      showError(t("Background image is required"));
      isValid = false;
    } else if (newsTitle.length === 0) {
      showError(t("News title is required"));
      isValid = false;
    } else if (newsHeadline.length === 0) {
      showError("News headline is required");
      isValid = false;
    } else if (newsUrl.length === 0) {
      showError("News url is required");
      isValid = false;
    } else if (!isUrlValid(newsUrl)) {
      showError("Please provide valid News url");
      isValid = false;
    }else if (categories.length === 0) {
      showError("Add atleast one category");
      isValid = false;
    } else if (language === -1) {
      showError("Please select Language");
      isValid = false;
    } else if (!provider) {
      showError("News provider is require");
      isValid = false;
    }

    return isValid;
  };

  // API CALLS
  const handleCancel = () => {
    updateAddNewsInfo("background", "");
    handleClearCarouselImage();
    clearContext();
  };

  
  const handleClearCarouselImage =()=>{
    if(!!carouselImage.length){
      carouselImage.map((e)=>{
        deleteFileById(e?.directus_files_id?.id)
      })
    }
  }
  const handleSave = async () => {
    const isValid = validateNewsFields();
    if (isValid) {
      setIsCreatingNews(true)
      console.log("start creating news")
      scaleRef.current.classList.remove("scale-50");
      guidelineRef.current.classList.remove("safearea-guidelines");
      CanvasViewRef.current.classList.remove("canvas-preview-reszie");

      // create image
      // backgroundImageRef is the canvas of background - created by react-image-crop
      // titleRef is the ref of div that contains the title html string
      //
      // we are converting the titleRef div to canvas then printing it on
      // background canvas and then converting it to blob to upload it.

      if (titleRef.current && backgroundImageRef.current) {
        const ele = titleRef.current;
        const textCanvas = await html2canvas(ele, {
          scale: 1,
        });
        if (backgroundImageRef.current) {
          // backgroundImageRef.current.height = 640;
          // backgroundImageRef.current.width = 380;
          const ctx = backgroundImageRef.current.getContext("2d");
          ctx?.drawImage(textCanvas, 0, 0);
          // const data = imgCanvas.toDataURL("image/jpeg");
          // return data;
          backgroundImageRef.current.toBlob((blob) => {
            if (blob) {
              // setIsCreatingNews(true);
              // updateAddNewsInfo("background", "");
              handlePostImage(blob);
            }
          },
          "image/jpeg",
           0.7);
        }
        else {
          setIsCreatingNews(false);
        }
      }
    }
  };

  const handlePostImage = async (newsImage: Blob) => {
    try {
      const res = await uploadFile(newsImage);
      if (res?.data) {
        const newsImage = res?.data?.data?.id
        setNewsImageID(newsImage)
        handleImageToShare(newsImage)
        
      console.log("blob post success")
      }
    }
    catch (error) {
      showToast({
        type: "error",
        title: "Something went wrong",
        message: "Cound not upload Image"
      });
      setIsCreatingNews(false)
    }
  }

  const handleImageToShare = async (newsImage:string) => {
    // const isValid = validateNewsFields();
    // if (isValid) {
      // scaleRef.current.classList.remove("scale-50");
      // guidelineRef.current.classList.remove("safearea-guidelines");
      // CanvasViewRef.current.classList.remove("canvas-preview-reszie");
      headerProviderLogoRef?.current?.classList?.remove("hidden")
      headerLogoRef?.current?.classList?.remove("hidden")
      // create image
      // backgroundImageRef is the canvas of background - created by react-image-crop
      // titleRef is the ref of div that contains the title html string
      //
      // we are converting the titleRef div to canvas then printing it on
      // background canvas and then converting it to blob to upload it.

      if (titleRef.current && backgroundImageRef.current) {
        const ele = titleRef.current;
        const textCanvas = await html2canvas(ele, {
          scale: 1,
        });
        if (backgroundImageRef.current) {
          // backgroundImageRef.current.height = 640;
          // backgroundImageRef.current.width = 380;
          const ctx = backgroundImageRef.current.getContext("2d");
          ctx?.drawImage(textCanvas, 0, 0);
          // const data = imgCanvas.toDataURL("image/jpg");
          // return data;
          backgroundImageRef.current.toBlob((blob) => {
            if (blob) {
              // setIsCreatingNews(true);
              // updateAddNewsInfo("background", "");
              handlePostShareImage(blob ,newsImage);
            }
          },
          "image/jpeg",
          0.8);
        }
        else {
          deleteFileById(newsImageID);
          setIsCreatingNews(false);
        }
      }
    // }
  };


  const handlePostShareImage = async (newsBlob: Blob ,newsImage:string) => {
    try {
      const res = await uploadFile(newsBlob);
      if (res?.data) {
        const imageToShare = res?.data?.data?.id;
        handlePostNews(imageToShare ,newsImage)
      }
    }
    catch (error) {
      showToast({
        type: "error",
        title: "Something went wrong",
        message: "Could not upload Image"
      });
      deleteFileById(newsImage);
    }
  }

  const handlePostNews = async (imageToShare:string,newsImage:string) => {
    try {
      let payloadBody: TypePostAddNews = {
        name: newsTitle,
        title: newsTitle,
        image: image,
        url: newsUrl,
        description: newsHeadline,
        urlToImage: newsImage,
        status: "pending",
        language: +language,
        provider_id: Number(provider),
        categories: {
          update: [],
          delete: [],
          create: categories?.map((item) => (
            {
              news_id: "+",
              category_id: {
                id: Number(item)
              }
            }
          ),)
        },
        carousel_image: {
          "create":carouselImage,
          "update": [],
          "delete": []
        },
        news_type: newsType,
        imageToShare: imageToShare
      };
      const postImageResponse = await draftNews(payloadBody).catch((err)=>{
      deleteFileById(newsImage)
      deleteFileById(imageToShare)
      showToast({
        type: "error",
        title: "Something went wrong",
        message: "Could not upload News"
      });
      })
      if (postImageResponse?.data) {
        console.log("postImagedata id : ", postImageResponse?.data?.data.id)
        UploadNewsTimelineStatus({
          "news": postImageResponse?.data?.data?.id,
          "comment": "",
          "status": "created"
        })
        showToast({
          type: "success",
          message: t("News Added Successfully"),
        });
        clearContext();
        localStorage.removeItem("providerImage")
        navigate("/newsList");
      }
    } 
    catch (error) {
      showToast({
        type: "error",
        title: "Something went wrong",
        message: "Could not upload News"
      });
    }
    setIsCreatingNews(false);
  };


  return {

    backgroundImageRef,
    titleRef,
    handleSave,
    handleCancel,
    isCreatingNews,
    setIsClickedMore,
    isClickedMore,
    targetRef,
    scrollableRef
  }
}



