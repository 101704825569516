import { useTranslation } from "react-i18next";
import { BsLockFill } from "react-icons/bs";

type ListViewProps = {
  label: string;
  description: JSX.Element;
  noBorder?: Boolean;
};

const ListView = ({ label, description, noBorder }: ListViewProps) => {
  return (
    <div className={`list-view  ${noBorder ? 'border-none' : ''}`}>
        <div className="label">{label}</div>
        <div className="desc">{description}</div>
    </div>
  );
};

export default ListView;
