import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BsSearch } from "react-icons/bs";
import {
  Container,
  Modal,
  Select,
  Drawer,
  TextInput,
  ScrollArea,
  Checkbox,
  Tabs,
  Button,
  Pagination,
} from "@mantine/core";
import { ImageSection } from "../../components/ImageSection";
import CreatorNewsDetail from "../../components/CreatorNewsDetail";
import { AvailableImageLoader } from "../../components/AvailableImageLoader";

import { LoginContext } from "../../utils/context/AppContext";
import NewsItem from "../../components/NewsItem";
import { useTranslation } from "react-i18next";
import useCreatorNews from "./hooks/useCreatorNews";
import ButtonGroup from "../../components/ButtonGroup";
import phoneOverlay from "../../assets/phone-overlay2.png";
import NewsDetailDrawer from "./component/Drawer/NewsDetailDrawer";
import { BiSearchAlt } from "react-icons/bi";

type Props = {
  data: {
    fileURL: string;
    tags?: string[];
    categories?: string[];
    created_by: {
      first_name: string;
      id: string;
      last_name: string;
    };
    description: string;
    id: string;
    image: any;
    name: string;
    provider_id: any;
    publishedAt: string;
    status: string;
    title: string;
    updated_by: string;
    url: string;
    urlToImage: string;
  };
  handleClick: () => void;
};

export default function CreatorNews({ data, handleClick }: Props) {

  const { t } = useTranslation();
  const navigate = useNavigate();

  const { user } = useContext(LoginContext);

  const {
    selectedLanguage,
    allLanguage,
    badgeCount,
    allImages,
    opened,
    setOpened,
    checkedValue,
    setCheckedValue,
    handelAllTitle,
    searchValue,
    setSearchValue,
    allImageTitle,
    imagesLoading,
    filteredData,
    selectedNews,
    selectedFilter,
    setSelectedFilter,
    setSelectedIndex,
    handleNewsSearchBaseOnNewsTitle,
    handleFetchNewsCountBasedOnStatus,
    handleFetchNews,
    getLanguageFromLocal,
    handleFetchLanguage,
    imageModal,
    setImageModal,
    filterQuery,
    setFilterQuery,
    handleFilterNewsBasedOnNewsTitle,
    page,
    setPage
  } = useCreatorNews();

  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
  useEffect(() => {
    if (isFirstLoad) {
      setIsFirstLoad(false);
    } else {
      handleFetchLanguage();
      getLanguageFromLocal();
      handleFetchNews(selectedFilter?.toLowerCase(), 1);
      handleFetchNewsCountBasedOnStatus();
    }
  }, [user, isFirstLoad, checkedValue, selectedLanguage]);

  useEffect(() => {
    handleNewsSearchBaseOnNewsTitle();
  }, [searchValue]);

  useEffect(() => {
    setSearchValue("");
  }, [selectedFilter]);

  const handleDrawerClose = () => {
    // handleStatusUpdate();
    setOpened(false);
    handleFetchNews(selectedFilter?.toLowerCase(), 1);
    handleFetchNewsCountBasedOnStatus();
    setSelectedIndex(-1);
  };

  const handleClose = () => {
    setOpened(false);
    setSelectedIndex(-1);
  };

  return (
    <Container fluid className="py-10">
      <div className="mb-8 flex items-center justify-between">
        <div className="">
          <h1 className="">{t("News List")}</h1>
          <p className="opacity-60">{t("List of news created by you")}</p>
        </div>

        <div className="flex justify-end">
          <Select
            placeholder=" ( eg. English ) "
            radius="md"
            // size="md"
            nothingFound="Nothing found"
            value={String(selectedLanguage)}
            data={allLanguage?.map((each) => {
              return { value: String(each?.id), label: each?.language };
            })}
            onChange={(e) => {
              // setSelectedLanguage(Number(e));
              localStorage.setItem("newsLanguage", String(e));
              getLanguageFromLocal();
            }}
          />
        </div>
      </div>
      <Tabs color="teal" defaultValue="first">
        <ButtonGroup
          label=""
          options={["All", "Pending", "Approved", "Rejected"]}
          allNewsCount={badgeCount}
          onClick={(i: any) => {
            setSelectedFilter(i);
            setSelectedIndex(-1);
            setPage({
              currentPage: 0,
              totalAvailablePages: 0
            });
            handleFetchNews(i?.toLowerCase(), 1, true);
          }}
        />
        <div className="flex gap-3 p-3">
          <p className="opacity-60">
          {`page: ${page?.currentPage}/${page?.totalAvailablePages}`}
          </p>
          <Checkbox
            label="News created by me"
            checked={checkedValue}
            onChange={(event) => setCheckedValue(event.currentTarget.checked)}
          />
        </div>

        <div className="flex flex-1 flex-row mt-6">
          <div className="flex-1">
            {/* <Select
            placeholder="Search by news title ( eg: Ajit P.. )"
            searchable
            clearable
            radius={"md"}
            // size={"lg"}
            icon={<BsSearch />}
            onDropdownOpen={() => handelAllTitle()}
            shadow={"shadow-xl"}
            value={searchValue}
            onChange={(e) => {
              setSearchValue(String(e));
            }}
            nothingFound={t("No options")}
            data={allImageTitle}
          /> */}

            <TextInput
              radius={"md"}
              placeholder="Search by news title ( eg: Ajit P.. )"
              value={filterQuery}
              onChange={(e) => {
                setFilterQuery(e.target.value);
              }}
              icon={
                <div className="mr-3 flex">
                  <BiSearchAlt />
                </div>
              }
            />
          </div>
        </div>

        {imagesLoading ? (
          <AvailableImageLoader />
        ) : filterQuery?.length > 1 ? (
          <div className="news-listing-container  mt-12 ">
            {filteredData?.map((item, index) => (
              <NewsItem
                onClick={() => {
                  setOpened(true);
                  setSelectedIndex(index);
                  handleClick();
                  // navigate(`/news/${item?.id}`);
                }}
                openDetailModal={() => {
                  navigate(`/newsList/editNews/${item?.id}`);
                }}
                key={item?.id}
                data={item}
                user={user}
                status={item?.status}
                createdBy={item?.created_by}
              />
            ))}
          </div>
        ) : (
          <>
            <div className="news-listing-container  mt-12 ">
              {allImages?.map((item, index) => (
                <NewsItem
                  onClick={() => {
                    setOpened(true);
                    setSelectedIndex(index);
                    // navigate(`/news/${item?.id}`);
                  }}
                  openDetailModal={() => {
                    navigate(`/newsList/editNews/${item?.id}`);
                    // console.log("openDetailModal clicked");
                    // setImageModal(true);
                    // setSelectedIndex(index);
                  }}
                  key={item?.id}
                  data={item}
                  user={user}
                  status={item?.status}
                  createdBy={item?.created_by?.id}
                />
              ))}
            </div>
            <div className="w-full flex justify-center mt-6">
              <Pagination
                total={page.totalAvailablePages}
                siblings={2}
                boundaries={2}
                defaultValue={page.currentPage}
                styles={(theme) => ({
                  control: {
                    '&[data-active]': {
                      backgroundImage: theme.fn.gradient({ from: 'red', to: 'yellow' }),
                      border: 0,
                    },
                  },
                })}
                onChange={(value) => handleFetchNews(selectedFilter.toLowerCase(), value)}
              />
              {/* <Button
                  className="bg-blue-600"
                  loading={imagesLoading}
                  onClick={() => handleFetchNews(selectedFilter.toLowerCase())}
                >
                  {t("Fetch More... Page:")} {page.currentPage} {"/"} {page.totalAvailablePages}
                </Button> */}
            </div>
          </>
        )}
      </Tabs>

      {/* <Modal
        opened={imageModal}
        onClose={() => setImageModal(false)}
        centered
        size={860}
        transition={"pop"}
        transitionDuration={500}
        transitionTimingFunction="ease"
        // overlayBlur={6}
        withCloseButton={false}
      >
        <CreatorNewsDetail
          data={selectedNews}
          handleClick={() => {
            setImageModal(false);
            handleFetchNews(selectedFilter.toLowerCase(), selectedLanguage);
          }}
        />
      </Modal> */}

      <Drawer
        opened={opened}
        onClose={() => setOpened(false)}
        // title="News Details"
        padding='xs'
        size='xl'
        position="right"
        withCloseButton={false}
      >
        <NewsDetailDrawer
          handleClose={() => handleClose()}
          selectedNews={selectedNews}
          handleClick={() => handleDrawerClose()}
        />
      </Drawer>
    </Container>
  );
}
