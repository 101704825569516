import React, { FC, PropsWithChildren, useRef, useState } from "react";
import { ICarouselImage, TypeCreateNews } from "../types";
import { useNavigate } from "react-router-dom";

type Props = {};

type TypeEditNewsContext = {
  newsTitle: string;
  backgroundUrl: string;
  image: string;
  newsHeadline: string;
  textPlacement: "top" | "middle" | "bottom";
  textColor: string;
  newsUrl: string;
  scaleRef: any;
  headerLogoRef :any;
  headerProviderLogoRef :any;
  guidelineRef: any;
  CanvasViewRef: any;
  provider: string;
  language: number;
  status: string;
  categories: string[];
  newsType: "carousel_news" | "news"
  carouselImage: string[]
  carouselExistingImage: string[]
  carouselImageToCreate: string[]
  carouselImageToDelete: string[]
  imageToShare:string
  updateEditNewsInfo: (type: TypeCreateNews, value: any) => void;
  clearContext: () => void;
};

export const EditNewsContext = React.createContext<TypeEditNewsContext>({
  backgroundUrl: "",
  newsHeadline: "",
  image: "",
  textPlacement: "top",
  CanvasViewRef: null,
  newsUrl: "",
  categories: [],
  scaleRef: null,
  guidelineRef: null,
  headerLogoRef:null,
  headerProviderLogoRef:null,
  provider: "",
  language: -1,
  textColor: "",
  newsTitle: "",
  status: "",
  newsType:"news",
  imageToShare:"",
  carouselImage:[] ,
  carouselExistingImage:[],
  carouselImageToDelete:[] ,
  carouselImageToCreate:[] ,
  updateEditNewsInfo: (type: TypeCreateNews, value: any) => { },
  clearContext: () => { },
});

const NewsContextWrapper: FC<PropsWithChildren<Props>> = ({ children }) => {
  const navigate = useNavigate();

  const [backgroundUrl, setBackgroundUrl] = useState<string>("");
  const [image, setImage] = useState<string>("");
  const [imageToShare, setImageToShare] = useState<string>("");
  const [carouselImage, setCarouselImage] = useState<string[]>([])
  const [carouselExistingImage, setCarouselExistingImage] = useState<string[]>([])
  const [carouselImageToDelete, setCarouselImageToDelete] = useState<string[]>([])
  const [carouselImageToCreate, setCarouselImageToCreate] = useState<string[]>([])

  const [newsType, setNewsType] = useState<"carousel_news" | "news">("news")
  const [newsTitle, setNewsTitle] = useState<string>("");
  const [newsHeadline, setNewsHeadline] = useState<string>("");
  const [textColor, setTextColor] = useState<string>("");
  const [textPlacement, setTextPlacement] = React.useState<
    "top" | "middle" | "bottom"
  >("top");
  const [newsUrl, setNewsUrl] = React.useState<string>("");
  const [categories, setCategories] = useState<string[]>([]);
  const [provider, setProvider] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [language, setLanguage] = useState<number>(-1);

  const scaleRef = useRef(null);
  const guidelineRef = useRef(null);
  const CanvasViewRef = useRef(null);
  const headerLogoRef = useRef(null);
  const headerProviderLogoRef = useRef(null);


  const updateEditNewsInfo = (type: TypeCreateNews, value: any) => {
    switch (type) {
      case "background":
        setBackgroundUrl(value);
        break;
      case "headline":
        setNewsHeadline(value);
        break;
      case "placement":
        setTextPlacement(value as "top" | "middle" | "bottom");
        break;
      case "url":
        setNewsUrl(value);
        break;
      case "provider":
        setProvider(value);
        break;
      case "language":
        setLanguage(+value);
        break;
      case "categories":
        setCategories(value.split(","));
        break;
      case "title":
        setNewsTitle(value);
        break;
      case "textColor":
        setTextColor(value);
        break;
      case "newsImage":
        setImage(value);
        break;
      case "status":
        setStatus(value);
        break;
      case "newsType":
        setNewsType(value);
        break;
      case "carouselImage":
        setCarouselImage(value);
        break;
      case "carouselExistingImage":
        setCarouselExistingImage(value);
        break;
      case "carouselImageToDelete":
        setCarouselImageToDelete(value);
        break;
      case "carouselImageToCreate":
        setCarouselImageToCreate(value);
        break;
      case "imageToShare":
        setImageToShare(value);
        break;
    }
  };
  const clearContext = () => {
    setBackgroundUrl("");
    setNewsTitle("");
    setNewsHeadline("");
    setTextPlacement("top");
    setNewsUrl("");
    setCategories([]);
    setProvider("");
    setCarouselImage([])
    setNewsType("news");
    setLanguage(-1);
    setCarouselImage([])
    setCarouselExistingImage([])
    setCarouselImageToCreate([])
    setCarouselImageToDelete([])
    navigate("/newsList");
  };
  return (
    <EditNewsContext.Provider
      value={{
        backgroundUrl,
        image,
        newsHeadline,
        newsUrl,
        textPlacement,
        categories,
        provider,
        language,
        newsTitle,
        scaleRef,
        status,
        guidelineRef,
        CanvasViewRef,
        headerLogoRef,
        headerProviderLogoRef,
        textColor,
        updateEditNewsInfo,
        clearContext,
        newsType,
        carouselImage,
        carouselExistingImage,
        carouselImageToCreate,
        carouselImageToDelete,
        imageToShare
      }}
    >
      {children}
    </EditNewsContext.Provider>
  );
};

export default NewsContextWrapper;
