import { Badge, Button } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { IBadgeCount } from "../utils/types";
import { AiFillGold } from "react-icons/ai";

type Props = {
  options: string[];
  onClick: (option: string) => void;
  direction?: "row" | "column";
  label?: string;
  allNewsCount?: IBadgeCount;
  activeButtonColor?: string;
};

const ButtonGroup = ({
  direction,
  onClick,
  allNewsCount,
  options,
  label,
  activeButtonColor,
}: Props) => {
  const { t } = useTranslation();
  const [activeBtn, setActiveBtn] = React.useState<number>(0);
  return (
    <div
      className={`flex flex-${
        direction === "column" ? "col" : "row"
      }  p-1 bg-slate-200 gap-1 max-w-max rounded-lg shadow-inner`}
    >
      {label && <p>{label}</p>}
      {options.map((option, index) => (
        <div
          className={` rounded-md flex items-center px-6 py-1.5 font-semibold cursor-pointer transition-all ${
            activeBtn === index
              ? "bg-white text-blue-600 shadow-lg  "
              : "text-slate-500 hover:text-slate-700 hover:bg-white hover:bg-opacity-40"
          }  ${index === 0 ? "" : ""}`}
          key={option}
          onClick={() => {
            setActiveBtn(index);
            onClick(option);
          }}
        >
          {option === "Pending" ? (
            <div className="flex items-center gap-2">
              <p> {option}</p>{" "}
              <Badge className="text-xs p-1.5" variant="filled">
                {allNewsCount?.pendingCount}
              </Badge>
            </div>
          ) : option === "Approved" ? (
            <div className="flex items-center gap-2">
              <p> {option}</p>{" "}
              <Badge className="text-xs p-1.5" variant="filled">
                {allNewsCount?.approvedCount}
              </Badge>
            </div>
          ) : option === "Rejected" ? (
            <div className="flex items-center gap-2">
              <p> {option}</p>{" "}
              <Badge className="text-xs p-1.5" variant="filled">
                {allNewsCount?.rejectedCount}
              </Badge>
            </div>
          ) : option === "All" ? (
            <div className="flex items-center gap-2">
              <p> {option}</p>{" "}
              <Badge className="text-xs p-1.5" variant="filled">
                {allNewsCount?.allNewsCount}
              </Badge>
            </div>
          ) : (
            <div className="flex items-center capitalize gap-2">
            <p>{option}</p>{" "}
            <AiFillGold size={20}/>
          </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default ButtonGroup;
