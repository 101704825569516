import React from "react";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
const language = ["en", "hindi", "punjabi"];
i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        lng: "", // if you're using a language detector, do not define the lng option
        fallbackLng: "en",
        interpolation: {
            escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        },
        resources: {
            hindi: {
                translation: {
                    "Create News Item": "समाचार बनाएं",
                    "pending": "लंबित",
                    "approved": "स्वीकृत",
                    "rejected": "अस्वीकृत",
                    "All": 'सब', "Pending": "लंबित", "Approved": "स्वीकृत", "Rejected": "अस्वीकृत",
                    "Step 1.": "स्टेप 1.",
                    "Select Background Image": "पृष्ठभूमि छवि का चयन करें",
                    "Please select a background image from the repository":
                        "कृपया रिपॉजिटरी से एक पृष्ठभूमि छवि चुनें",
                    "Step 2.": "स्टेप 2.",
                    "Headline & Placement": "शीर्षक और प्लेसमेंट",
                    "Enter news headlines and select placement":
                        "समाचार शीर्षक दर्ज करें और प्लेसमेंट चुनें",
                    "News Title": "समाचार शीर्षक",
                    "This is for internal purposes, can be same as the News headline.":
                        "यह आंतरिक उद्देश्यों के लिए है, समाचार शीर्षक के समान हो सकता है।",
                    "News Headline": "समाचार शीर्षक",
                    "Add Headline": "शीर्षक जोड़ें",
                    "Select Text Placement": "टेक्स्ट प्लेसमेंट चुनें",
                    "Top": "ऊपर",
                    "Middle": "मध्यम",
                    "Bottom": "नीचे",
                    "Step 3.": "स्टेप 3.",
                    "Other Details": "अन्य विवरण",
                    "Select": "चयन करें",
                    "Enter link to the news and select category":
                        "समाचार का लिंक दर्ज करें और श्रेणी चुनें",
                    "Link to News Article": "समाचार लेख का लिंक",
                    "Select Category": "श्रेणी चुनना",
                    "You can select multiple categories":
                        "श्रेणी का चयन करेंआप कई श्रेणियों का चयन कर सकते हैं",
                    "items found": "वस्तु मिली",
                    "Select Background": "पृष्ठभूमि का चयन करें",
                    "Please select a background to proceed.": "कृपया आगे बढ़ने के लिए एक पृष्ठभूमि चुनें।",
                    "List of news created by you": "आपके द्वारा बनाई गई खबरों की सूची",
                    "News List": "समाचार सूची",
                    "Found": "मिलीं ",
                    "images": "तस्विर",
                    "No options": 'कोई विकल्प नहीं',
                    "Image Repository": "तस्विर भंडार",
                    "Upload Image": "तस्विर अपलोड करें",
                    "Select a file to upload": "अपलोड करने के लिए फ़ाइल का चयन करें",
                    "Select a Tag": "एक टैग चुनें",
                    "Selected Tags:": "चयनित टैग :",
                    "No Tags Added": "कोई टैग नहीं जोड़ा गया",
                    "Clear": "साफ़",
                    "Edit Image Tags": "तस्विर टैग संपादित करें",
                    "Add more than one tags, this will make it easy to find the correct image": "एक से अधिक टैग जोड़ें, इससे सही तस्विर खोजने में आसानी होगी ",
                    "Add New tag": "नया टैग जोड़ें",
                    "New Tags": "नए टैग",
                    "Uploaded by": " अपलोड करने वाले :",
                    "on": "दिनांक :",
                    "Tags": "टैग",
                    "Cancel": "रद्द करें",
                    "Save": "सेव करें",
                    "Please select image to upload and enter relevant tags. You can add more than one tag.": "कृपया अपलोड करने के लिए तस्विर का चयन करें और प्रासंगिक टैग दर्ज करें। आप एक से अधिक टैग जोड़ सकते हैं।",
                    "Browse file to upload": "अपलोड करने के लिए फ़ाइल ब्राउज़ करें",
                    "You can only upload one file at a time. Supports JPG and PNG": "आप एक समय में केवल एक फ़ाइल अपलोड कर सकते हैं। JPG और PNG का समर्थन करता है",
                    "Search here (eg: cricket, virat kohli etc...": "यहां खोजें (जैसे: क्रिकेट, विराट कोहली आदि...",
                    "News Details": "समाचार विवरण",
                    "Link to news article": "समाचार लेख का लिंक",
                    "Title": "शीर्षक",
                    "Published At": "  प्रकाशित दिनांक",
                    "News Article Link": "समाचार लेख लिंक",
                    "Close": "बंद करें ",
                    "Enter tag name (ex: anime)": "टैग नाम दर्ज करें (उदा: एनीमे)",
                    "Admin Panel": "व्यवस्थापक पैनल",
                    "Select Language": "भाषा चुने",
                    "News list": "समाचार सूची",
                    "Create News": "समाचार बनाएं",
                    "creator": "रचनाकार",
                    "editor": "संपादक",
                    "uploader": 'अपलोडर',

                    // logOut or logIn
                    "Are you sure you want to logout?": 'क्या आप लॉग आउट करना चाहते हैं ?',
                    "Logout": "लॉग आउट",
                    "Password": "पासवर्ड",
                    "Log In": "लॉग इन करें",
                    "Username / Email Address": "उपयोगकर्ता का नाम या ईमेल पता",
                    "Enter username or email address": "उपयोगकर्ता नाम या ईमेल पता दर्ज करें",
                    "Login with your credentials below.": "नीचे अपनी साख के साथ लॉगिन करें।",

                    // showTost
                    "You Can Not Edit This Image!": "आप इस छवि को संपादित नहीं कर सकते!",
                    "This image was uploaded by some other Graphic Designer": "यह छवि किसी अन्य ग्राफिक डिजाइनर द्वारा अपलोड की गई थी",
                    "Not able to remove last tag!": "अंतिम टैग हटाने में सक्षम नहीं!",
                    "Please firstly add another tag to remove it!": "कृपया इसे हटाने के लिए पहले एक और टैग जोड़ें!",
                    "News is": "ऐप के लिए समाचार",
                    "for the app.": "है",
                    "Cannot approve news": "समाचार स्वीकृत नहीं कर सकता",
                    "Try again later.": "बाद में पुन: प्रयास।",
                    "Something Went Wrong!": "कुछ गड़बड़ हो गया!",
                    "Could not fetch tags": "टैग नहीं लाए जा सके",
                    "News is approved for the app.": "समाचार ऐप के लिए स्वीकृत है।",
                    "Could not fetch image list": "छवि सूची नहीं ला सका",
                    "Could not create new tags! Try again later.": "नए टैग नहीं बनाए जा सके! बाद में पुन: प्रयास करें।",
                    "Successfully uploaded image.": "सफलतापूर्वक अपलोड की गई छवि।",
                    "Message sent": "मैसेज बेजा गया",
                    "message send successfully": "संदेश सफलतापूर्वक भेजा गया",
                    "message sending Failed!": "संदेश भेजने में विफल!",
                    "Feedback error": "प्रतिक्रिया त्रुटि",
                    "Could not fetch feedback of this news": "इस खबर की प्रतिक्रिया नहीं मिल सकी",
                    "Could not fetch news details!": "समाचार विवरण नहीं लाया जा सका!",
                    "No Tags were Found!": "कोई टैग नहीं मिला!",
                    "Please select atleast one tag!": "कृपया कम से कम एक टैग चुनें!",
                    "Tags can not be empty. Please select at least one tag.": "टैग खाली नहीं हो सकते। कृपया कम से कम एक टैग चुनें।",
                    "Tags have been updated successfully!": "टैग सफलतापूर्वक अपडेट कर दिए गए हैं!",
                    "Could not update tags.": "टैग अपडेट नहीं किए जा सके।",
                    "Could not fetch available images.": "उपलब्ध चित्र नहीं ला सके।",
                    "Could not authenticate user.": "उपयोगकर्ता को प्रमाणित नहीं किया जा सका।",
                    "News Added Successfully": "समाचार सफलतापूर्वक जोड़ा गया",

                    //errors
                    "Background image is required": "पृष्ठभूमि छवि आवश्यक है",
                    "News title is required": "समाचार शीर्षक आवश्यक है",
                    "News headline is required": "समाचार शीर्षक आवश्यक है",
                    "News url is required": "समाचार url आवश्यक है",
                    "Add atleast one category": 'कम से कम एक श्रेणी जोड़ें',
                    "Please correctly set news image": "कृपया सही ढंग से समाचार छवि सेट करें",
                    "Error Adding Image": "छवि जोड़ने में त्रुटि"

                },
            },
            punjabi: {
                translation: {
                    "Create News Item": "ਇਸ ਫਾਈਲ ਨੂੰ ਰੱਦ ਕਰੋ",
                    "pending": "ਬਕਾਇਆ",
                    "approved": "ਨੂੰ ਮਨਜ਼ੂਰੀ ਦਿੱਤੀ",
                    "rejected": "ਰੱਦ ਕਰ ਦਿੱਤਾ",
                    "All": 'ਸਾਰੇ', "Pending": "ਬਕਾਇਆ", "Approved": "ਨੂੰ ਮਨਜ਼ੂਰੀ ਦਿੱਤੀ", "Rejected": "ਰੱਦ ਕਰ ਦਿੱਤਾ",
                    "Step 1.": "ਕਦਮ 1.",
                    "Select Background Image": "ਬੈਕਗ੍ਰਾਊਂਡ ਚਿੱਤਰ ਚੁਣੋ",
                    "Please select a background image from the repository":
                        "ਕਿਰਪਾ ਕਰਕੇ ਰਿਪੋਜ਼ਟਰੀ ਤੋਂ ਇੱਕ ਬੈਕਗਰਾਊਂਡ ਚਿੱਤਰ ਚੁਣੋ",
                    "Step 2.": "ਕਦਮ 2.",
                    "Headline & Placement": "ਸਿਰਲੇਖ ਅਤੇ ਪਲੇਸਮੈਂਟ",
                    "Enter news headlines and select placement":
                        "ਖਬਰਾਂ ਦੀਆਂ ਸੁਰਖੀਆਂ ਦਰਜ ਕਰੋ ਅਤੇ ਪਲੇਸਮੈਂਟ ਚੁਣੋ",
                    "News Title": "ਖ਼ਬਰਾਂ ਦਾ ਸਿਰਲੇਖ",
                    "This is for internal purposes, can be same as the News headline.":
                        "ਇਹ ਅੰਦਰੂਨੀ ਉਦੇਸ਼ਾਂ ਲਈ ਹੈ, ਖਬਰਾਂ ਦੀ ਸੁਰਖੀ ਵਾਂਗ ਹੀ ਹੋ ਸਕਦਾ ਹੈ।",
                    "News Headline": "ਨਿਊਜ਼ ਹੈੱਡਲਾਈਨ",
                    "Add Headline": "ਸਿਰਲੇਖ ਸ਼ਾਮਲ ਕਰੋ",
                    "Select Text Placement": "ਟੈਕਸਟ ਪਲੇਸਮੈਂਟ ਚੁਣੋ",
                    "Top": "ਸਿਖਰ",
                    "Middle": "ਕੇਂਦਰ",
                    "Bottom": "ਥੱਲੇ",
                    "Step 3.": "ਕਦਮ 3.",
                    "Other Details": "ਹੋਰ ਵੇਰਵੇ",
                    "Enter link to the news and select category":
                        "ਖ਼ਬਰਾਂ ਦਾ ਲਿੰਕ ਦਰਜ ਕਰੋ ਅਤੇ ਸ਼੍ਰੇਣੀ ਚੁਣੋ",
                    "Link to News Article": "ਨਿਊਜ਼ ਆਰਟੀਕਲ ਲਈ ਲਿੰਕ",
                    "Select Category": "ਸ਼੍ਰੇਣੀ ਚੁਣੋ",
                    "You can select multiple categories":
                        "ਤੁਸੀਂ ਕਈ ਸ਼੍ਰੇਣੀਆਂ ਚੁਣ ਸਕਦੇ ਹੋ",
                    "items found": "ਆਈਟਮਾਂ ਲੱਭੀਆਂ",
                    "Select Background": "ਬੈਕਗ੍ਰਾਊਂਡ ਚੁਣੋ",
                    "Please select a background to proceed.": "ਕਿਰਪਾ ਕਰਕੇ ਅੱਗੇ ਵਧਣ ਲਈ ਇੱਕ ਪਿਛੋਕੜ ਚੁਣੋ।",
                    "List of news created by you": "ਤੁਹਾਡੇ ਦੁਆਰਾ ਬਣਾਈ ਗਈ ਖਬਰਾਂ ਦੀ ਸੂਚੀ",
                    "News List": "ਖ਼ਬਰਾਂ ਦੀ ਸੂਚੀ",
                    "Found": "ਮਿਲਿਆ",
                    "images": "ਚਿੱਤਰ",
                    "No options": 'ਕੋਈ ਵਿਕਲਪ ਨਹੀਂ',
                    "Image Repository": "ਚਿੱਤਰ ਭੰਡਾਰ",
                    "Upload Image": "ਚਿੱਤਰ ਅੱਪਲੋਡ ਕਰੋ",
                    "Select a file to upload": "ਅੱਪਲੋਡ ਕਰਨ ਲਈ ਇੱਕ ਫ਼ਾਈਲ ਚੁਣੋ",
                    "Select a Tag": "ਇੱਕ ਟੈਗ ਚੁਣੋ",
                    "Selected Tags:": "ਚੁਣੇ ਗਏ ਟੈਗਸ :",
                    "No Tags Added": "ਕੋਈ ਟੈਗ ਸ਼ਾਮਲ ਨਹੀਂ ਕੀਤੇ ਗਏ",
                    "Clear": "ਸਾਫ਼",
                    "Edit Image Tags": "ਚਿੱਤਰ ਟੈਗਸ ਨੂੰ ਸੰਪਾਦਿਤ ਕਰੋ",
                    "Add more than one tags, this will make it easy to find the correct image": "ਇੱਕ ਤੋਂ ਵੱਧ ਟੈਗ ਜੋੜੋ, ਇਸ ਨਾਲ ਸਹੀ ਚਿੱਤਰ ਲੱਭਣਾ ਆਸਾਨ ਹੋ ਜਾਵੇਗਾ",
                    "Add New tag": "ਨਵਾਂ ਟੈਗ ਸ਼ਾਮਲ ਕਰੋ",
                    "New Tags": "ਨਵੇਂ ਟੈਗਸ",
                    "Uploaded by": " ਅੱਪਲੋਡਰ :",
                    "on": "ਤਾਰੀਖ਼ :",
                    "Tags": "ਟੈਗ",
                    "Cancel": "ਰੱਦ ਕਰੋ",
                    "Save": "ਸੰਭਾਲੋ",
                    "Please select image to upload and enter relevant tags. You can add more than one tag.": "ਕਿਰਪਾ ਕਰਕੇ ਅੱਪਲੋਡ ਕਰਨ ਲਈ ਚਿੱਤਰ ਚੁਣੋ ਅਤੇ ਸੰਬੰਧਿਤ ਟੈਗ ਦਾਖਲ ਕਰੋ। ਤੁਸੀਂ ਇੱਕ ਤੋਂ ਵੱਧ ਟੈਗ ਜੋੜ ਸਕਦੇ ਹੋ।",
                    "Browse file to upload": "ਅੱਪਲੋਡ ਕਰਨ ਲਈ ਫ਼ਾਈਲ ਬ੍ਰਾਊਜ਼ ਕਰੋ",
                    "You can only upload one file at a time. Supports JPG and PNG": "ਤੁਸੀਂ ਇੱਕ ਸਮੇਂ ਵਿੱਚ ਸਿਰਫ਼ ਇੱਕ ਫ਼ਾਈਲ ਅੱਪਲੋਡ ਕਰ ਸਕਦੇ ਹੋ। JPG ਅਤੇ PNG ਦਾ ਸਮਰਥਨ ਕਰਦਾ ਹੈ",
                    "Search here (eg: cricket, virat kohli etc...": "ਇੱਥੇ ਖੋਜ ਕਰੋ (ਜਿਵੇਂ: ਕ੍ਰਿਕਟ, ਵਿਰਾਟ ਕੋਹਲੀ ਆਦਿ...",
                    "News Details": "ਖਬਰਾਂ ਦੇ ਵੇਰਵੇ",
                    "Link to news article": "समाचार लेख का लिंक",
                    "Title": "ਸਿਰਲੇਖ",
                    "Published At": "  'ਤੇ ਪ੍ਰਕਾਸ਼ਿਤ",
                    "News Article Link": "ਨਿਊਜ਼ ਆਰਟੀਕਲ ਲਿੰਕ",
                    "Close": "ਬੰਦ ਕਰੋ ",
                    "Enter tag name (ex: anime)": "ਟੈਗ ਦਾ ਨਾਮ ਦਰਜ ਕਰੋ (ਉਦਾਹਰਨ: ਐਨੀਮੇ)",
                    "Admin Panel": "ਐਡਮਿਨ ਪੈਨਲ",
                    "Select Language": "ਭਾਸ਼ਾ ਚੁਣੋ",
                    "News list": "ਖ਼ਬਰਾਂ ਦੀ ਸੂਚੀ",
                    "Create News": "ਖਬਰ ਬਣਾਓ",
                    "creator": "ਸਿਰਜਣਹਾਰ",
                    "editor": "ਸੰਪਾਦਕ",
                    "uploader": 'ਅੱਪਲੋਡਰ',

                    // logOut logIn
                    "Are you sure you want to logout?": 'ਕੀ ਤੁਸੀਂ ਯਕੀਨੀ ਤੌਰ ਤੇ ਲੌਗਆਊਟ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ?',
                    "Logout": "ਲਾੱਗ ਆਊਟ, ਬਾਹਰ ਆਉਣਾ",
                    "Password": "ਪਾਸਵਰਡ",
                    "Log In": "ਲਾਗਿਨ",
                    "Username / Email Address": "ਉਪਭੋਗਤਾ ਨਾਮ / ਈਮੇਲ ਪਤਾ",
                    "Enter username or email address": "ਉਪਭੋਗਤਾ ਨਾਮ ਜਾਂ ਈਮੇਲ ਪਤਾ ਦਰਜ ਕਰੋ",
                    "Login with your credentials below.": "ਹੇਠਾਂ ਆਪਣੇ ਪ੍ਰਮਾਣ ਪੱਤਰਾਂ ਨਾਲ ਲੌਗਇਨ ਕਰੋ।",

                    // showTost
                    "You Can Not Edit This Image!": "ਤੁਸੀਂ ਇਸ ਚਿੱਤਰ ਨੂੰ ਸੰਪਾਦਿਤ ਨਹੀਂ ਕਰ ਸਕਦੇ ਹੋ!",
                    "This image was uploaded by some other Graphic Designer": "ਇਹ ਚਿੱਤਰ ਕਿਸੇ ਹੋਰ ਗ੍ਰਾਫਿਕ ਡਿਜ਼ਾਈਨਰ ਦੁਆਰਾ ਅਪਲੋਡ ਕੀਤਾ ਗਿਆ ਸੀ",
                    "Not able to remove last tag!": "ਆਖਰੀ ਟੈਗ ਨੂੰ ਹਟਾਉਣ ਦੇ ਯੋਗ ਨਹੀਂ!",
                    "Please firstly add another tag to remove it!": "ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਹਟਾਉਣ ਲਈ ਪਹਿਲਾਂ ਇੱਕ ਹੋਰ ਟੈਗ ਜੋੜੋ!",
                    "News is": "ਐਪ ਲਈ ਖਬਰ",
                    "for the app.": "ਹੈ",
                    "Cannot approve news": "ਖਬਰਾਂ ਨੂੰ ਮਨਜ਼ੂਰੀ ਨਹੀਂ ਦੇ ਸਕਦਾ",
                    "Try again later.": "ਬਾਅਦ ਵਿੱਚ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ।",
                    "Something Went Wrong!": "ਕੁਝ ਗਲਤ ਹੋ ਗਿਆ!",
                    "Could not fetch tags": "ਟੈਗ ਪ੍ਰਾਪਤ ਨਹੀਂ ਕੀਤੇ ਜਾ ਸਕੇ",
                    "News is approved for the app.": "ਐਪ ਲਈ ਖਬਰਾਂ ਨੂੰ ਮਨਜ਼ੂਰੀ ਦਿੱਤੀ ਗਈ ਹੈ।",
                    "Could not fetch image list": "ਚਿੱਤਰ ਸੂਚੀ ਪ੍ਰਾਪਤ ਨਹੀਂ ਕੀਤੀ ਜਾ ਸਕੀ",
                    "Could not create new tags! Try again later.": "ਨਵੇਂ ਟੈਗ ਨਹੀਂ ਬਣਾਏ ਜਾ ਸਕੇ! ਬਾਅਦ ਵਿੱਚ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ।",
                    "Successfully uploaded image.": "ਚਿੱਤਰ ਨੂੰ ਸਫਲਤਾਪੂਰਵਕ ਅੱਪਲੋਡ ਕੀਤਾ ਗਿਆ।",
                    "Message sent": "ਸੁਨੇਹਾ ਭੇਜਿਆ ਗਿਆ",
                    "message send successfully": "ਸੁਨੇਹਾ ਸਫਲਤਾਪੂਰਵਕ ਭੇਜਿਆ",
                    "message sending Failed!": "ਸੁਨੇਹਾ ਭੇਜਣਾ ਅਸਫਲ ਰਿਹਾ!",
                    "Feedback error": "ਫੀਡਬੈਕ ਗਲਤੀ",
                    "Could not fetch feedback of this news": "ਇਸ ਖਬਰ ਦਾ ਫੀਡਬੈਕ ਪ੍ਰਾਪਤ ਨਹੀਂ ਕੀਤਾ ਜਾ ਸਕਿਆ",
                    "Could not fetch news details!": "ਖ਼ਬਰਾਂ ਦੇ ਵੇਰਵੇ ਪ੍ਰਾਪਤ ਨਹੀਂ ਕੀਤੇ ਜਾ ਸਕੇ!",
                    "No Tags were Found!": "ਕੋਈ ਟੈਗ ਨਹੀਂ ਮਿਲੇ!",
                    "Please select atleast one tag!": "ਕਿਰਪਾ ਕਰਕੇ ਘੱਟੋ-ਘੱਟ ਇੱਕ ਟੈਗ ਚੁਣੋ!",
                    "Tags can not be empty. Please select at least one tag.": "ਟੈਗਸ ਖਾਲੀ ਨਹੀਂ ਹੋ ਸਕਦੇ ਹਨ। ਕਿਰਪਾ ਕਰਕੇ ਘੱਟੋ-ਘੱਟ ਇੱਕ ਟੈਗ ਚੁਣੋ।",
                    "Tags have been updated successfully!": "ਟੈਗਸ ਨੂੰ ਸਫਲਤਾਪੂਰਵਕ ਅੱਪਡੇਟ ਕੀਤਾ ਗਿਆ ਹੈ!",
                    "Could not update tags.": "ਟੈਗ ਅੱਪਡੇਟ ਨਹੀਂ ਕੀਤੇ ਜਾ ਸਕੇ।",
                    "Could not fetch available images.": "ਉਪਲਬਧ ਚਿੱਤਰ ਪ੍ਰਾਪਤ ਨਹੀਂ ਕੀਤੇ ਜਾ ਸਕੇ।",
                    "Could not authenticate user.": "ਉਪਭੋਗਤਾ ਨੂੰ ਪ੍ਰਮਾਣਿਤ ਨਹੀਂ ਕੀਤਾ ਜਾ ਸਕਿਆ।",
                    "News Added Successfully": "ਖ਼ਬਰਾਂ ਸਫਲਤਾਪੂਰਵਕ ਸ਼ਾਮਲ ਕੀਤੀਆਂ ਗਈਆਂ",

                    //errors
                    "Background image is required": "ਬੈਕਗ੍ਰਾਊਂਡ ਚਿੱਤਰ ਲੋੜੀਂਦਾ ਹੈ",
                    "News title is required": "ਖ਼ਬਰਾਂ ਦਾ ਸਿਰਲੇਖ ਲੋੜੀਂਦਾ ਹੈ",
                    "News headline is required": "ਖ਼ਬਰਾਂ ਦੀ ਸੁਰਖੀ ਦੀ ਲੋੜ ਹੈ",
                    "News url is required": "ਨਿਊਜ਼ url ਦੀ ਲੋੜ ਹੈ",
                    "Add atleast one category": 'ਘੱਟੋ-ਘੱਟ ਇੱਕ ਸ਼੍ਰੇਣੀ ਸ਼ਾਮਲ ਕਰੋ',
                    "Please correctly set news image": "ਕਿਰਪਾ ਕਰਕੇ ਖਬਰ ਚਿੱਤਰ ਨੂੰ ਸਹੀ ਢੰਗ ਨਾਲ ਸੈੱਟ ਕਰੋ",
                    "Error Adding Image": "ਚਿੱਤਰ ਜੋੜਨ ਵਿੱਚ ਗਲਤੀ"

                },
            },
        },
    });
