import { useTranslation } from "react-i18next";
import { BsLockFill } from "react-icons/bs";

type NewsItemProps = {
  label: string;
  icon: JSX.Element;
  isActive?: Boolean | false;
  isDisabled?: Boolean | false;
  handleOnClick: () => void;
};

const NewsItem = ({
  label,
  icon,
  isActive,
  isDisabled,
  handleOnClick,
}: NewsItemProps) => {
  const { t } = useTranslation();
  return (
    <div
      className={`nav-item ${
        isActive ? " active" : isDisabled ? " disabled" : ""
      }`}
      onClick={() => handleOnClick()}
    >
      <div className="icon">{icon}</div>

      <div className="label">{t(label)}</div>
      {isDisabled ? (
        <div>
          <BsLockFill />
        </div>
      ) : null}
    </div>
  );
};

export default NewsItem;
