import React, { FC, PropsWithChildren, useRef, useState } from "react";
import { ICarouselImage, TypeCreateNews } from "../types";
import { deleteFileById } from "../utility";
import { useNavigate } from "react-router-dom";

type Props = {};

type TypeAddNewsContext = {
  newsTitle: string;
  backgroundUrl: string;
  image: string;
  newsHeadline: string;
  textPlacement: "top" | "middle" | "bottom";
  textColor: string;
  newsUrl: string;
  categories: string[];
  scaleRef: any;
  CanvasViewRef: any;
  headerLogoRef: any;
  headerProviderLogoRef: any;
  guidelineRef: any;
  provider: string;
  language: number;
  updateAddNewsInfo: (type: TypeCreateNews, value: string |any[]) => void;
  clearContext: () => void;
  newsType ? : "carousel_news"|"news"
  carouselImage : ICarouselImage
};

export const AddNewsContext = React.createContext<TypeAddNewsContext>({
  backgroundUrl: "",
  image: "",
  newsHeadline: "",
  textPlacement: "top",
  newsUrl: "",
  categories: [],
  scaleRef: null,
  guidelineRef: null,
  headerLogoRef: null,
  headerProviderLogoRef : null,
  CanvasViewRef: null,
  provider: "",
  language: -1,
  textColor: "",
  newsTitle: "",
  updateAddNewsInfo: (type: TypeCreateNews, value: string | string[]) => {},
  clearContext: () => {},
  newsType:"news",
  carouselImage:[],
});

const NewsContextWrapper: FC<PropsWithChildren<Props>> = ({ children }) => {
  
  const navigate = useNavigate();
  const [backgroundUrl, setBackgroundUrl] = useState<string>("");
  const [image, setImage] = useState<string>("");
  const [carouselImage ,setCarouselImage]=useState<ICarouselImage>([])
  const [newsType,setNewsType]=useState<"carousel_news"|"news">("news")
  const [newsTitle, setNewsTitle] = useState<string>("");
  const [newsHeadline, setNewsHeadline] = useState<string>("");
  const [textColor, setTextColor] = useState<string>("");
  const [textPlacement, setTextPlacement] = React.useState<
    "top" | "middle" | "bottom"
  >("top");
  const [newsUrl, setNewsUrl] = React.useState<string>("");
  const [categories, setCategories] = useState<string[]>([]);
  const [provider, setProvider] = useState<string>("");
  const [language, setLanguage] = useState<number>(-1);
  const scaleRef = useRef(null);
  const guidelineRef = useRef(null);
  const CanvasViewRef = useRef(null);
  const headerLogoRef = useRef(null);
  const headerProviderLogoRef = useRef(null);

  // console.log("carouselImage URls",carouselImage)

  const updateAddNewsInfo = (type: TypeCreateNews, value: any) => {
    switch (type) {
      case "background":
        setBackgroundUrl(value);
        break;
      case "headline":
        setNewsHeadline(value);
        break;
      case "placement":
        setTextPlacement(value as "top" | "middle" | "bottom");
        break;
      case "url":
        setNewsUrl(value);
        break;
      case "provider":
        setProvider(value);
        break;
      case "language":
        // console.log("update Language", value);
        setLanguage(+value);
        break;
      case "categories":
        setCategories(value.split(","));
        break;
      case "title":
        setNewsTitle(value);
        break;
      case "textColor":
        setTextColor(value);
        break;
      case "newsImage":
        setImage(value);
        // console.log("Url of Image", value);
        break;
      case "newsType":
        setNewsType(value);
        // console.log("Url of Image", value);
        break;
      case "carouselImage":
        setCarouselImage(value);
        // console.log("Url of Image", value);
        break;
    }
  };
  const clearContext = () => {
    setBackgroundUrl("");
    setImage("");
    setNewsTitle("");
    setNewsHeadline("");
    setTextPlacement("top");
    setNewsUrl("");
    setCategories([]);
    setProvider("");
    setCarouselImage([])
    setNewsType("news");

    setLanguage(-1);
    
    navigate("/newsList");
    // console.log(
    //   "Clear Context: ",
    //   backgroundUrl.length,
    //   newsTitle.length,
    //   newsHeadline.length,
    //   newsUrl.length,
    //   categories.length,
    //   provider.length,
    //   language,
    // );4
  };

  return (
    <AddNewsContext.Provider
      value={{
        backgroundUrl,
        image,
        newsHeadline,
        newsUrl,
        textPlacement,
        categories,
        provider,
        language,
        newsTitle,
        scaleRef,
        headerLogoRef,
        headerProviderLogoRef,
        CanvasViewRef,
        guidelineRef,
        textColor,
        updateAddNewsInfo,
        clearContext,
        newsType,
        carouselImage
      }}
    >
      {children}
    </AddNewsContext.Provider>
  );
};

export default NewsContextWrapper;
