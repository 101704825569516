import { INewsById } from './../../../utils/types';
import { getNewsByCreatorId, getNewsByNewsID, PatchNews } from '../../../utils/api/api';
import { title } from 'process';
import html2canvas from "html2canvas";
import { createElement, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { create } from 'domain';
import { ICategory, ILanguage, InewsDetail, IProvider, TypePostAddNews } from '../../../utils/types';
import { draftNews, getAllCategories, getAllLanguage, getAllProvider, uploadFile, UploadNewsTimelineStatus } from '../../../utils/api/api';
import { deleteFileById, showToast } from '../../../utils/utility';
import { EditNewsContext } from '../../../utils/context/EditNewsContext';
import { FILE_URL } from '../../../utils/constants';
import { useNavigate } from 'react-router-dom';
import { useScrollIntoView } from '@mantine/hooks';

const showError = (error: string) => {
  showToast({
    message: error,
    type: "error",
  });
};

const useEditNews = () => {
  const [allCategories, setAllCategories] = useState<ICategory[]>([]);
  const [allProvider, setAllProvider] = useState<IProvider[]>([]);
  const [allLanguage, setAllLanguage] = useState<ILanguage[]>([]);
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
  const [editNewsId, setEditNewsId] = useState<number>(-1);
  const [oldNewsData,setOldNewsData] = useState<{background: string
    imageToShare: string 
    newsImage: string} | undefined>( undefined)
  const [existingCategories, setExistingCategories] = useState<string[]>()
  const [existingCarouselObject, setExistingCarouselObject] = useState<{
    "id": number
    "news_id": number
    "directus_files_id": string
}[]>([])
  const [isCreatingNews, setIsCreatingNews] = useState<boolean>(false);
  const [isClickedCarousel, setIsClickedCarousel] = useState<boolean>(false)
  let titleRef = useRef<HTMLDivElement>(null);
  let backgroundImageRef = useRef<HTMLCanvasElement>(null);
  
  const { scrollIntoView, targetRef, scrollableRef } = useScrollIntoView<
    HTMLDivElement,
    HTMLDivElement
  >();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    clearContext,
    backgroundUrl,
    image,
    newsTitle,
    newsHeadline,
    language,
    provider,
    newsUrl,
    scaleRef,
    headerLogoRef,
    headerProviderLogoRef,
    newsType,
    guidelineRef,
    CanvasViewRef,
    categories,
    imageToShare,
    carouselImage,
    carouselExistingImage,
    carouselImageToCreate,
    carouselImageToDelete,
    updateEditNewsInfo,
  } = useContext(EditNewsContext);

  useEffect(() => {
    if (isFirstLoad) {
      setIsFirstLoad(false);
    } else {
      handleFetchCategory();
      handelFetchProvider();
      handleFetchLanguage();
    }
  }, [isFirstLoad]);

  useEffect(() => {
    if (newsType === "carousel_news") {
      scrollIntoView()
    }
  }, [newsType])
 
  function isUrlValid(str:string) {
    const pattern = new RegExp(
      '^(https?:\\/\\/)?'
    );
    return pattern.test(str);
   }
  // HANDLER FUNCTIONS
  const validateNewsFields = () => {
    let isValid = true;
    if (backgroundUrl.length === 0) {
      showError(t("Background image is required"));
      isValid = false;
    } else if (newsTitle.length === 0) {
      showError(t("News title is required"));
      isValid = false;
    } else if (newsHeadline.length === 0) {
      showError("News headline is required");
      isValid = false;
    } else if (newsUrl.length === 0) {
      showError("News url is required");
      isValid = false;
    }else if (!isUrlValid(newsUrl)) {
      showError("Please provide valid News url");
      isValid = false;
    } else if (categories.length === 0 || categories.includes('')) {
      showError("Add atleast one category");
      isValid = false;
    } else if (language === -1) {
      showError("Firlstly add Language");
      isValid = false;
    } else if (!provider) {
      showError("News provider is require");
      isValid = false;
    }

    return isValid;
  };

  // API CALLS
  const handleGetNewsDetailByParams = async (id: number) => {
    setEditNewsId(id)
    await getNewsByNewsID(id).then((res) => {
      const categ: string[] = []
      const categoryId: string[] = []
      const response: INewsById = res?.data?.data[0];
      const carouselImage: string[] = []
      const existingCarouselObject:any[] = []

      if (response?.status === "approved") {
        showToast({
          type: "warning",
          title: "News is already approved!",
          message: "Could not edit news while it's already approved."
        });
        setTimeout(() => {
          navigate("/newsList")
        }, 2000);
      }

      if (response.image?.id) {
        response?.categories.forEach((e: { category_id: string; }) => categ.push((e.category_id)));
        response?.categories.forEach((e: { id: string; }) => categoryId.push((e.id)));
        setExistingCategories(categoryId)
        response?.carousel_image.forEach((e:any) => carouselImage.push((e?.directus_files_id)));
        response?.carousel_image.forEach((e: { id: string; }) => existingCarouselObject.push((e)));
        setExistingCarouselObject(existingCarouselObject)
        updateEditNewsInfo("carouselImage", carouselImage);
        updateEditNewsInfo('carouselExistingImage', carouselImage);
        updateEditNewsInfo("title", response?.title || "");
        updateEditNewsInfo("language", String(response?.language?.id));
        updateEditNewsInfo("background", response?.image?.id);
        // updateEditNewsInfo("background", `${FILE_URL}${response?.image?.filename_disk}`);
        updateEditNewsInfo("url", response?.url)
        updateEditNewsInfo("headline", response?.description);
        updateEditNewsInfo("provider", String(response?.provider_id?.id))
        updateEditNewsInfo("newsImage", response?.urlToImage?.id);
        updateEditNewsInfo("categories", String(categ));
        updateEditNewsInfo("status", response?.status);
        updateEditNewsInfo("newsType", response?.news_type ||"news");
        updateEditNewsInfo("imageToShare", response?.imageToShare?.id);
        setOldNewsData({
          background: response?.image?.id ,
          imageToShare: response?.imageToShare?.id,
          newsImage: response?.urlToImage?.id
        })
      } else {
        showToast({
          type: "warning",
          title: "News can not be edited!",
          message: "Could not find the previously uploaded image. It seems like that image has been removed."
        });
        setTimeout(() => {
          navigate("/newsList")
        }, 2000);
      }
    })
      .catch((err) => {
        showToast({
          type: 'error',
          title: "Something Went Wrong",
          message: " Could not fetch News data of selected News "
        })
        console.log("Error in get data by Newsid ", err)
      })
  };

   
  const deleteOldImage =async()=>{
    // oldNewsData && await deleteFileById(oldNewsData?.background)
    oldNewsData && await deleteFileById(oldNewsData?.imageToShare)
    oldNewsData && await deleteFileById(oldNewsData?.newsImage) 
  if(!!carouselImageToDelete.length){
    carouselImageToDelete.map(async e=>await deleteFileById(e))}
  }
  const handleFetchCategory = () => {
    getAllCategories().then(
      (response) => {
        setAllCategories(response.data?.data || []);
      },
      (error) => {
        console.log(error?.response);
      }
    );
  };

  const handelFetchProvider = () => {
    getAllProvider().then(
      (response) => { setAllProvider(response.data?.data || []); },
      (error) => {
        showToast({
          type: "warning",
          title: "Fetch provider failed!",
          message: "could not fetch provider data"
        })
      }
    )
  };

  const handleFetchLanguage = () => {

    getAllLanguage().then(
      (response) => {
        setAllLanguage(response.data?.data || []);
      },
      (error) => {
        showToast({
          type: "warning",
          title: "Fetch Language failed!",
          message: "could not fetch Language data"
        })
      }
    )
  };

  const handleCancel = () => {
    updateEditNewsInfo("background", "");
    clearContext();
    navigate("/newsList");
  };

  const handleSave = async () => {
    const isValid = validateNewsFields();
    if (isValid) {
      scaleRef.current.classList.remove("scale-50");
      guidelineRef.current.classList.remove("safearea-guidelines");
      CanvasViewRef.current.classList.remove("canvas-preview-reszie");

      // create image
      // backgroundImageRef is the canvas of background - created by react-image-crop
      // titleRef is the ref of div that contains the title html string
      //
      // we are converting the titleRef div to canvas then printing it on
      // background canvas and then converting it to blob to upload it.

      if (titleRef.current && backgroundImageRef.current) {
        const ele = titleRef.current;
        const textCanvas = await html2canvas(ele, {
          scale: 1,
        });
        if (backgroundImageRef.current) {
          // backgroundImageRef.current.height = 640;
          // backgroundImageRef.current.width = 380;
          const ctx = backgroundImageRef.current.getContext("2d");
          ctx?.drawImage(textCanvas, 0, 0);
          // const data = imgCanvas.toDataURL("image/jpeg");
          // return data;
          backgroundImageRef.current.toBlob((blob) => {
            if (blob) {
              // setIsCreatingNews(true);
              // updateAddNewsInfo("background", "");
              // console.log("news blob",blob)
              handlePostImage(blob);
            }
          },
          "image/jpeg",
           0.7);
        }
        else {
          // setIsCreatingNews(false);
        }
      }
    }
  };

  const handlePostImage = async (newsImage: Blob) => {
    try {
      const res = await uploadFile(newsImage);
      if (res?.data) {
        const newsImage = res?.data?.data?.id
        // setNewsImageID(newsImage)
        handleImageToShare(newsImage)
      }
    }
    catch (error) {
      showToast({
        type: "error",
        title: "Something went wrong",
        message: "Cound not upload Image"
      });
      // setIsCreatingNews(false)
    }
  }

  const handleImageToShare = async (newsImage:string) => {
    // const isValid = validateNewsFields();
    // if (isValid) {
      // scaleRef.current.classList.remove("scale-50");
      // guidelineRef.current.classList.remove("safearea-guidelines");
      // CanvasViewRef.current.classList.remove("canvas-preview-reszie");
      headerProviderLogoRef?.current?.classList?.remove("hidden")
      headerLogoRef?.current?.classList?.remove("hidden")
      // create image
      // backgroundImageRef is the canvas of background - created by react-image-crop
      // titleRef is the ref of div that contains the title html string
      //
      // we are converting the titleRef div to canvas then printing it on
      // background canvas and then converting it to blob to upload it.
       
      if (titleRef.current && backgroundImageRef.current) {
        const ele = titleRef.current;
        const textCanvas = await html2canvas(ele, {
          scale: 1,
        });
        if (backgroundImageRef.current) {
          // backgroundImageRef.current.height = 640;
          // backgroundImageRef.current.width = 380;
          const ctx = backgroundImageRef.current.getContext("2d");
          ctx?.drawImage(textCanvas, 0, 0);
          // const data = imgCanvas.toDataURL("image/jpeg");
          // return data;
          backgroundImageRef.current.toBlob((blob) => {
            if (blob) {
              // setIsCreatingNews(true);
              // updateAddNewsInfo("background", "");
              // console.log(blob)
              handlePostShareImage(blob ,newsImage);
            }
          },
          "image/jpeg",
          0.8);
        }
        else {
          deleteFileById(newsImage);
          // setIsCreatingNews(false);
        }
      }
    }
    // }
  // };


  const handlePostShareImage = async (newsBlob: Blob ,newsImage:string) => {
    try {
      const res = await uploadFile(newsBlob);
      if (res?.data) {
        const newImageToShare = res?.data?.data?.id;
        handlePostNews(newImageToShare ,newsImage)
      }
    }
    catch (error) {
      showToast({
        type: "error",
        title: "Something went wrong",
        message: "Could not upload Image"
      });
      deleteFileById(newsImage);
    }
  }

  const handlePostNews = async (newImageToShare:string , newsImage:string) => {
    try {
        let body: TypePostAddNews = {
          name: newsTitle,
          title: newsTitle,
          url: newsUrl,
          image: backgroundUrl,
          description: newsHeadline,
          urlToImage: newsImage, 
          status: "pending",
          language: +language,
          provider_id: Number(provider),
          news_type: newsType,
          imageToShare: newImageToShare,
          categories: {
            update: [],
            delete: existingCategories,
            create: categories?.map((item: any) => (
              {
                news_id: editNewsId,
                category_id: {
                  id: Number(item)
                }
              }
            ),

            )
          },
          carousel_image: {
            "create": carouselImageToCreate.map((e)=>(
              {
                "news_id":editNewsId ,
                "directus_files_id": {
                    "id": e
                }
              }
            )) ,
            "update": [],
            "delete": carouselImageToDelete.map((e)=>{
                let deleteArray = existingCarouselObject.filter(items => items?.directus_files_id == e)
                return deleteArray.map(e=>e?.id)
          }).flat()
          },
        };

        const postImageResponse = await PatchNews(editNewsId, body);
        if (postImageResponse?.data) {
             deleteOldImage()
             UploadNewsTimelineStatus({
            "news": postImageResponse?.data?.data?.id,
            "comment": "",
            "status": "edited"
             })
          showToast({
            type: "success",
            message: t("News Edited Successfully"),
          });
          clearContext();
          localStorage.removeItem("providerImage")
          navigate("/newsList");
        }
      } catch (error) {
      showToast({
        type: "error",
        title: "Something went wrong",
        message: "Cound not upload Image"
      });
    }
    setIsCreatingNews(false);
  };

  return {
    allCategories,
    allProvider,
    allLanguage,
    backgroundImageRef,
    titleRef,
    handleSave,
    handleCancel,
    handleGetNewsDetailByParams,
    isCreatingNews,
    isClickedCarousel,
    setIsClickedCarousel,
    targetRef,
    scrollableRef
  };
};

export default useEditNews;
