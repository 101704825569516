import { useContext, useEffect, useState } from "react";
import NewsContextWrapper, {
  EditNewsContext,
} from "../../utils/context/EditNewsContext";
import AddNewsWithoutFrame from "../../components/AddNewsWithoutFrame";
import { useParams } from "react-router-dom";
import useEditNews from "./hooks/useEditNews";
import { Image } from "@mantine/core";
import { API_URL, FILE_URL } from "../../utils/constants";
import EditNewsItem from "./component/EditNewsItem";
import EditNewsPreview from "./component/EditNewsPreview";
import CarouselImageUploader from "../../components/CarouselImage/CarouselImageUploader";
import CarouselImageEditor from "../../components/CarouselImage/CarouselImageEditor";

type Props = {};

const EditNewsWithContext = () => {
  const params = useParams();
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
  const {
    allCategories,
    allProvider,
    allLanguage,
    backgroundImageRef,
    titleRef,
    targetRef,
    scrollableRef,
    handleSave,
    handleCancel,
    isCreatingNews,
    handleGetNewsDetailByParams,
    isClickedCarousel,
    setIsClickedCarousel,
  } = useEditNews();
  const {
    newsType
  } = useContext(EditNewsContext);

  useEffect(() => {
    if (isFirstLoad) {
      setIsFirstLoad(false);
    } else {
      handleGetNewsDetailByParams(Number(params?.id));
    }
  }, [isFirstLoad]);
  return (
    <div className=" flex lg:flex-row h-full flex-col ">
      <EditNewsItem
        allCategories={allCategories}
        allProvider={allProvider}
        allLanguage={allLanguage}
        onSave={() => {
          handleSave();
        }}
        onCancel={() => {
          handleCancel();
        }}
        checkedCarousel={(e)=>setIsClickedCarousel(e)}
        isCreatingNews={isCreatingNews}
      />
      {/* {backgroundUrl && isEditBackground ? (
        <div className="flex items-center justify-around flex-wrap  w-full gap-3 ">
          <img
            src={`${API_URL}assets/${backgroundUrl}`}
            alt=""
            className="  border-4 rounded-xl border-black w-[400px] h-[700px] "
          />
        </div>
      ) : ( */}
       {/* {
      !isCreatingNews && 
       <div className='absolute flex bg-slate-400 opacity-70'>
            Loading
       </div> 
      } */}
        <div ref={scrollableRef} className={`w-full overflow-y-scroll h-screen`}>
      <EditNewsPreview
        backgroundImageRef={backgroundImageRef}
        titleRef={titleRef}
        isCreatingNews={isCreatingNews}
      />
      <div
          ref={targetRef}
          className={`${newsType !=="carousel_news" ? "hidden" : ""}`}
        >
          <CarouselImageEditor
          />
        </div>
      {/* )} */}
      {/* <AddNewsFrameWithCrop
        titleRef={titleRef}
        backgroundImageRef={backgroundImageRef}
      /> */}
    </div>
    </div>
  );
};
const EditNews = (props: Props) => {
  return (
    <NewsContextWrapper>
      <EditNewsWithContext />
    </NewsContextWrapper>
  );
};

export default EditNews;
