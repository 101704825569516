import { Divider, Input, TextInput } from "@mantine/core";
import React, { useState } from "react";

type Props = {
  value: string;
  onChange: (value: string) => void;
  type?: "text" | "number" | "email" | "password";
};
export default function InputWithIcon({ onChange, value, type }: Props) {
  return (
    <div className=" ">
      {/* <div className="text bg-slate-100 font-medium px-3 flex items-center">http://</div> */}
      {/* <Divider size="xs" orientation="vertical" /> */}
      <div className="">
        <TextInput
          // variant="unstyled"
          type={type || "text"}
          placeholder="www.news.com/some-news"
          // radius={'sm'}
          size="md"
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
          }}
        />
      </div>
    </div>
  );
}
