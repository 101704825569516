import { showNotification } from "@mantine/notifications";
import { IRoute, ROUTES } from "../routes/routes";
import { deleteImage } from "./api/api";

export const showToast = (data: {
  message: string;
  title?: string;
  type?: "error" | "success" | "warning";
  time?: number;
  icon?: JSX.Element;
  className?: string;
  isLoading?: boolean;
  nonClosable?: boolean;
}) => {
  showNotification({
    title: data?.title,
    message: data?.message,
    color:
      data?.type === "error"
        ? "red"
        : data?.type === "success"
          ? "green"
          : data?.type === "warning"
            ? "yellow"
            : "blue",
    autoClose: data?.time ? data?.time : 4000,
    icon: data?.icon,
    className: data?.className,
    loading: data?.isLoading,
    // disallowClose: data?.nonClosable,
  });
};

export const handleFormatUploadedImage = (images: any) => {
  let formattedImagesData = images?.map((i: any) => ({
    url: i?.filename_disk,
    tags: i?.tags,
    fileName: i?.title,
    uploadedOn: i?.uploaded_on,
    uploadedBy: i?.uploaded_by,
    id: i?.id,
    imageNewsUrl : i?.image_news_url
  }));
  return formattedImagesData;
};

export const handleFormatgetImageByCreator = (images: any) => {
  let formattedImagesData = images?.map((i: any) => ({
    url: i?.urlToImage,
    tags: i?.tags,
    fileName: i?.title,
    uploadedOn: i?.publishedAt,
    uploadedBy: i?.created_by,
    id: i?.id,
    categories: i?.categories,
  }));
  return formattedImagesData;
};

export const matchRoute = (path: string): IRoute => {
  const route = ROUTES.ALL_ROUTES;
  return route.find((route) => route.path === path) as IRoute;
};

export const deleteFileById =  (id:string) =>{
  console.log("image id to delete",id)
  let isFail: boolean = false;
    deleteImage(id).then((res)=>{ 
  console.log("delete image with id : ",id)       
  }).catch((err)=>{
    if(!isFail){  
      deleteImage(id)
      isFail=true;
  } 
  })
}