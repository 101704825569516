import React, {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { createContext } from "react";
import { useNavigate } from "react-router-dom";
import { TypeUser } from "../types";

type Props = {};

type TypeLoginContext = {
  isLoggedIn: boolean;
  token: string;
  user?: TypeUser;
  refreshToken?: string;
  handleLoginSuccess: (
    token: string,
    user: TypeUser,
    language: string,
    refreshToken?: string
  ) => void;
  selectedLanguage: string;
  setSelectedLanguage: Dispatch<SetStateAction<string>>;
  handleLogout: () => void;
};

export const LoginContext = createContext<TypeLoginContext>({
  isLoggedIn: false,
  token: "",
  user: undefined,
  refreshToken: "",
  selectedLanguage: "en",
  setSelectedLanguage: () => {},
  handleLoginSuccess: () => {},
  handleLogout: () => {},
});

const AppContext: FC<PropsWithChildren<Props>> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [token, setToken] = useState<string>("");
  const [refreshToken, setRefreshToken] = useState<string>();
  const [user, setUser] = useState<TypeUser | undefined>();
  const [selectedLanguage, setSelectedLanguage] = useState<string>("en");
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("user") || "{}") as TypeUser;
    const refreshToken = localStorage.getItem("refreshToken");
    const language = localStorage.getItem("language") || "";
    if (token && user.role) {
      handleLoginSuccess(token, user, language, refreshToken || "");
    }
  }, []);

  const handleLoginSuccess = useCallback(
    (
      token: string,
      user: TypeUser,
      language: string,
      refreshToken?: string
    ) => {
      setToken(token);
      setUser(user);
      setRefreshToken(refreshToken);
      localStorage.setItem("token", token);
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("refreshToken", refreshToken || "");
      localStorage.setItem("language", language || "en");
      setIsLoggedIn(true);
    },
    []
  );
  const handleLogout = () => {
    setToken("");
    localStorage.removeItem("token");
    setUser(undefined);
    localStorage.removeItem("user");
    setRefreshToken("");
    localStorage.removeItem("refreshToken");
    setIsLoggedIn(false);
    localStorage.removeItem("language");
    setSelectedLanguage("en");
    navigate("/login");
  };
  return (
    <LoginContext.Provider
      value={{
        isLoggedIn,
        handleLoginSuccess,
        handleLogout,
        token,
        user,
        selectedLanguage,
        setSelectedLanguage,
        refreshToken,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};

export default AppContext;
