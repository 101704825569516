import { Grid, Skeleton } from "@mantine/core";

export const AvailableImageLoader = () => {
  return (
    <>
      <Grid className="my-3">
        <Grid.Col span={3}>
          <Skeleton radius={"md"} height={200} />
        </Grid.Col>
        <Grid.Col span={3}>
          <Skeleton radius={"md"} height={200} />
        </Grid.Col>
        <Grid.Col span={3}>
          <Skeleton radius={"md"} height={200} />
        </Grid.Col>
        <Grid.Col span={3}>
          <Skeleton radius={"md"} height={200} />
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col span={3}>
          <Skeleton radius={"md"} height={200} />
        </Grid.Col>
        <Grid.Col span={3}>
          <Skeleton radius={"md"} height={200} />
        </Grid.Col>
        <Grid.Col span={3}>
          <Skeleton radius={"md"} height={200} />
        </Grid.Col>
        <Grid.Col span={3}>
          <Skeleton radius={"md"} height={200} />
        </Grid.Col>
      </Grid>
    </>
  );
};
