import React from "react";
import { Button, ButtonProps } from '@mantine/core';
import './EButton.scss';

interface IProps {
  color?: string;
  // children: React.ReactNode;
}

export function EButton(props: any) {
  return <Button radius={'md'} size="md" className="bg-blue-400" {...props} />;
}
