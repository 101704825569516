import { useEffect, useContext, useState, useRef, forwardRef } from "react";
import {
  Button,
  Divider,
  MultiSelect,
  ScrollArea,
  TextInput,
  Badge,
  Text,
  Select,
  Timeline,
  Group,
  Avatar,
  Checkbox,
} from "@mantine/core";
import InputWithIcon from "./InputWithIcon";
// import Stepper from "../../../components/Stepper/Stepper";
import { BsArrowRight, BsInfoCircleFill } from "react-icons/bs";
import { EditNewsContext } from "../../../utils/context/EditNewsContext";
// import { RichTextEditor } from "@mantine/rte";
import googleFonts from "../../../googleFonts.json";
import {
  ICategory,
  ILanguage,
  INewsById,
  IProvider,
  ISelectedBgImageFromModal,
} from "../../../utils/types";
import TextSkeleton from "../../../components/TextSkeleton";
import ColorPicker from "../../../components/ColorP/ColorPicker";
import BgImageModal from "../../AddNews/component/BgImageModal";
import { API_URL, FILE_URL } from "../../../utils/constants";
import { Editor } from "@tinymce/tinymce-react";
import { useTranslation } from "react-i18next";
import { showToast } from "../../../utils/utility";
import "./CreateNewsItemStyle.scss";
import { getNewsByNewsID } from "../../../utils/api/api";
const CreateHtmlFromString = ({ HTMLString }: { HTMLString: string }) => {
  return <div dangerouslySetInnerHTML={{ __html: HTMLString }} />;
};

type Props = {
  allCategories: ICategory[];
  allProvider: IProvider[];
  allLanguage: ILanguage[];
  onSave: () => void;
  onCancel: () => void;
  isCreatingNews: boolean;
  checkedCarousel: (e:boolean) => void;
};

interface ItemProps {
  image: string;
  label: string;
  description: string;
}

export default function EditNewsItem({
  allCategories,
  allProvider,
  allLanguage,
  isCreatingNews,
  checkedCarousel,
  onSave,
  onCancel,
}: Props) {
  const {
    updateEditNewsInfo,
    backgroundUrl,
    newsHeadline,
    textPlacement,
    newsUrl,
    newsTitle,
    newsType,
    carouselImageToCreate,
    carouselExistingImage,
    language,
    status,
    categories,
    provider,
  } = useContext(EditNewsContext);

  const [showBGSelectModal, setShowBgSelectModal] = useState<boolean>(false);
  const [isAnyChangeDone,setIsAnyChangeDone]= useState<boolean>(false);
  const [selectedFont, setSelectedFont] = useState<string>("");
  const [headLine, setHeadLine] = useState<string>("");
  const { t, i18n } = useTranslation();

  const editorRef = useRef<any>(null);
  const tinyMCsKey = "z916qx35xowgituqr10rqdchcga4haw8rergzeyht97asnz0";

  const [editedText, setEditedText] = useState<string>("");
  const [isCarouselChecked,setIsCarouselChecked] =useState<boolean>(false);

  const log = () => {
    // if (!isEditBackground) {
    //   showToast({
    //     type: "warning",
    //     message: "Please select a background to add Headline",
    //   });
    //   return;
    // } else {
    let HTMLString = editorRef.current?.getContent();
    setEditedText(HTMLString);
    updateEditNewsInfo("headline", HTMLString);
  };
  useEffect(() => {
    let updatedValue = headLine;
    if (selectedFont !== "") {
      updatedValue = headLine.replace(
        "<p",
        `<p style="font-family:${selectedFont};"`
      );
    }
    updateEditNewsInfo("headline", updatedValue);
  }, [headLine, selectedFont]);

  useEffect(() => {
    setEditedText(newsHeadline);
  }, [newsHeadline]);
  
  useEffect(()=>{
    updateEditNewsInfo('newsType', isCarouselChecked ? 'carousel_news':'news')
     checkedCarousel(isCarouselChecked)
  },[isCarouselChecked])

  // const handlegetNewsDetailByParams = async (id: number) => {
  //   await getNewsByNewsID(id)
  //     .then((res) => {
  //       const NewsData = res?.data?.data[0];
  //       console.log("HandleGetNewsById", NewsData);
  //     })
  //     .catch((err) => {
  //       showToast({
  //         type: "error",
  //         title: "Something Went Wrong",
  //         message: " Could not fetch News data of selected News ",
  //       });
  //     });
  // };

  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ image, label, description, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          <Avatar src={`${FILE_URL + image}`} />
          <div>
            <Text size="sm">{label}</Text>
            <Text size="xs" color="dimmed">
              {description}
            </Text>
          </div>
        </Group>
      </div>
    )
  );
  return (
    <div className="h-screen flex flex-col lg:w-[680px] w-full  bg-white -ml-10 border-r">
      <div className="flex justify-between w-full h-16 items-center px-4 py-6 shadow-lg">
        <p className=" font-bold text-xl">{isCarouselChecked ? "Create Carousel News" : t("Edit News Item")}</p>
         <Badge variant="light" color="info" className="round-md">
          {status}
        </Badge>
      </div>
      {/* <Divider /> */}

      <div className="p-4 pb-8 flex-1 overflow-y-scroll overflow-hidden">
        {/* <div className="p-2 bg-blue-100 border border-orange-200 rounded-lg my-4 flex items-center gap-2 text-sm">
          <BsInfoCircleFill  className="text-orange-500"/>
          Please follow the 3 steps below to create news item.
        </div> */}

        <Timeline active={0} bulletSize={20} lineWidth={3}>
          <Timeline.Item title={t("Step 1.")} className="pb-4">
            <h4>{t("Select Background Image")}</h4>
            <p className="opacity-60 mb-6">
              {t("Please select a background image from the repository")}
            </p>
            <Button
              fullWidth
              variant="light"
              size="md"
              className="bg-blue-50 text-blue-600"
              onClick={() => {
                setShowBgSelectModal(true)
                setIsAnyChangeDone(true)
              }}
            >
              {t("Select Background Image")}
            </Button>
          </Timeline.Item>
          <Timeline.Item title={t("Step 2.")} className="pb-8">
            <h4>Select Language</h4>
            <Select
              placeholder="English, Hindi, Punjabi"
              radius="md"
              size="md"
              nothingFound="Nothing found"
              value={String(language)}
              data={allLanguage?.map((each) => {
                return { value: String(each?.id), label: each?.language };
              })}
              onChange={(e) => {
                updateEditNewsInfo("language", e || "");
                setIsAnyChangeDone(true)
              }}
            />
          </Timeline.Item>
          <Timeline.Item title={t("Step 3.")} className="pb-8">
            <h4>{t("Headline & Placement")}</h4>
            <p className="opacity-60 mb-6">
              {t("Enter news headlines and select placement")}
            </p>
            <>
              <p className="form-label"> {t("News Title")}</p>
              <TextInput
                placeholder={t("Enter news title")}
                radius="md"
                size="md"
                value={newsTitle}
                onChange={(e) => {
                  updateEditNewsInfo("title", e.target.value);
                  setIsAnyChangeDone(true)
                }}
              />
              <div className="text-xs font-medium bg-orange-50 text-orange-500 rounded-md p-2 mt-2">
                {t(
                  "This is for internal purposes, can be same as the News headline."
                )}
              </div>

              {/* <p className="form-label"> {t("News Headline")}</p> */}
              <Editor
                apiKey={tinyMCsKey}
                onInit={(evt, editor) => (editorRef.current = editor)}
                toolbar="bold italic underline fontfamily fontsize forecolor lineheight alignleft aligncenter alignright alignjustify"
                initialValue={String(newsHeadline)}
                init={{
                  height: 350,
                  menubar: false,
                  plugins: [
                    "advlist",
                    "autolink",
                    "lists",
                    "image",
                    "charmap",
                    "preview",
                    "anchor",
                    "searchreplace",
                    "visualblocks",
                    "code",
                    "fullscreen",
                    "insertdatetime",
                    "media",
                    "table",
                    "code",
                    "help",
                    "wordcount",
                  ],
                  // toolbar: 'bold | italic | underline code | fontfamily | fontsize | forecolor',
                  // toolbar: 'undo redo | blocks | ' +
                  //     'bold italic forecolor | alignleft aligncenter ' +
                  //     'alignright alignjustify | bullist numlist outdent indent | ' +
                  //     'removeformat | help',
                  // @import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');
                  // @import url('https://fonts.googleapis.com/css?family=Warnes&display=swap');
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif,Lato; font-size:14px }",
                  font_size_formats: `14pt 18pt 20pt 24pt 30pt 31pt 32pt 33pt 34pt 35pt 36pt 48pt 54pt 64pt`,
                  line_height_formats: `1 1.1 1.2 1.4 1.6 2`,
                  font_family_formats:
                    "Arial=arial,helvetica,sans-serif; Roboto=roboto; Montserrat =montserrat; Poppins=poppins; Lato=lato; Open Sans =open sans; Source Sans Pro=source sans pro; Oswald =oswald;  Raleway =raleway; Inter =inter; Ubuntu =ubuntu; Nunito=nunito; Merriweather=merriweather; Playfair Display=playfair display; Lora=lora; Quicksand=quicksand; Mulish=mulish; PT Serif=pt serif; Noto Serif=noto serif; Josefin Sans=josefin sans ; PT Sans Caption=pt sans caption ; Spectral=spectral; Alata=alata; Orbitron=orbitron; ",
                  font_formats:
                    "Arial Black=arial black,avant garde; Lato =lato; Roboto=roboto;",
                  content_css: "/CreateNewsItemStyle.scss",
                }}
              />
              <Button
                className="bg-blue-600 my-2"
                rightIcon={<BsArrowRight />}
                fullWidth
                onClick={() => {
                  log()
                  setIsAnyChangeDone(true)
                }}
              >
                {t("Add Headline")}
              </Button>

              {/* <p className="form-label">{t("Select Text Placement")}</p>
              <div className="flex mt-2">
                {["Top", "Middle", "Bottom"].map((e) => (
                  <TextSkeleton
                    key={e}
                    position={e}
                    isSelected={textPlacement === e.toLowerCase()}
                    onSelect={() =>
                      updateEditNewsInfo("placement", e.toLowerCase())
                    }
                  />
                ))}
              </div> */}
            </>
          </Timeline.Item>

          <Timeline.Item title={t("Step 4.")} className="pb-8">
            <h4>{t("Other Details")}</h4>
            <p className="opacity-60 mb-6">
              {t("Enter link to the news and select category")}
            </p>

            <>
              <p className="form-label">Select News Provider</p>
              <Select
                placeholder="Select Provider"
                value={provider}
                data={allProvider?.map((each) => {
                  return {
                    value: String(each?.id),
                    label: each?.name,
                    image: each?.icon?.filename_disk,
                  };
                })}
                // value={provider}
                searchable
                size="md"
                maxDropdownHeight={400}
                itemComponent={SelectItem}
                nothingFound="Nobody here"
                onChange={(e) => {
                  updateEditNewsInfo("provider", e || "");
                  setIsAnyChangeDone(true)
                }}
              />
              <p className="form-label">{t("Link to News Article")}</p>
              <InputWithIcon
                value={newsUrl}
                onChange={(value) => {
                  updateEditNewsInfo("url", value);
                  setIsAnyChangeDone(true)
                }}
              />
              <div className="mt-4">
                <p className="form-label">{t("Select Category")}</p>
                <MultiSelect
                  description={t("You can select multiple categories")}
                  inputWrapperOrder={["input", "description"]}
                  data={allCategories?.map((each) => {
                    return { value: String(each?.id), label: each?.name };
                  })}
                  placeholder={t("Select")}
                  searchable
                  size="md"
                  value={categories}
                  dropdownPosition="top"
                  nothingFound="Nothing found"
                  // className="mt-2"
                  onChange={(e) => {
                    updateEditNewsInfo("categories", e.join());
                    setIsAnyChangeDone(true)
                  }}
                />

                 <Checkbox
                  className='mt-3 flex pt-4'
                  checked={ newsType === "carousel_news" ?  true :false}
                  onChange={()=>{
                    if(newsType==="carousel_news" && !!carouselExistingImage.length){
                      showToast({
                        type:"warning",
                        title: "Not Allow !",
                        message: "Carousel News Can't be change to simple news "
                      });
                    }
                    else{
                      updateEditNewsInfo("newsType", newsType === "news" ? 'carousel_news' :"news")}
                      setIsAnyChangeDone(true)
                    }
                    }
                  label="Do you want to make a carousel news"
                />
              </div>
            </>
          </Timeline.Item>
        </Timeline>

        {/* <div className="text-blue-600 font-bold">Step 1. </div>
        <h4>Select Background Image</h4>
        <p className="opacity-60">
          Select background image from the repository
        </p>
        <div className=" mt-4">
          <Button
            fullWidth
            variant="light"
            size="md"
            className="bg-blue-50 text-blue-600"
            onClick={() => setShowBgSelectModal(true)}
          >
            Select Background Image
          </Button>
        </div> */}
      </div>

      <div className=" h-16  w-full flex flex-col justify-center">
        <Divider className="mt-0 sticky " />
        <div className="flex p-3 bottom-0 gap-2">
          <Button
            variant="light"
            size="md"
            className="ml-auto"
            onClick={onCancel}
          >
            {t("Cancel")}
          </Button>
          <Button 
          size="md" 
          className="bg-primary"
          onClick={onSave}
          disabled={!isAnyChangeDone}
          >
            {t("Save")}
          </Button>
        </div>
      </div>

      <BgImageModal
        isOpen={showBGSelectModal}
        onClose={() => setShowBgSelectModal(false)}
        onSelect={(imgData: ISelectedBgImageFromModal) => {
          updateEditNewsInfo("background", imgData?.imgId);
          updateEditNewsInfo("newsImage", imgData?.imgId);
          updateEditNewsInfo("url", imgData?.newsUrlByImage ? imgData?.newsUrlByImage : "");
          setShowBgSelectModal(false);
        }}
      />
    </div>
  );
}
