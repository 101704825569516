import React, { useContext, useEffect, useState } from "react";
import {
  Navbar as MantineNavBar,
  Image,
  NavLink,
  Modal,
  Button,
  Select,
} from "@mantine/core";
import logo from "../assets/logo.png";
import {
  BsFileEarmarkDiff,
  BsImage,
  BsImageFill,
  BsImages,
  BsLockFill,
  BsNewspaper,
  BsPlusCircleFill,
} from "react-icons/bs";
// import { Link, NavLink } from "react-router-dom";
import useAuth from "../utils/hooks/useAuth";
import { FiImage, FiLogOut, FiUpload } from "react-icons/fi";
import { LoginContext } from "../utils/context/AppContext";
import NavItem from "./NavItem";
import { GrLanguage } from "react-icons/gr";
import { IRoute, ROUTES } from "../routes/routes";
import { useLocation, useNavigate } from "react-router-dom";
import { matchRoute } from "../utils/utility";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { color } from "html2canvas/dist/types/css/types/color";

const navLinks = [
  {
    path: "/new",
    icon: <BsFileEarmarkDiff size={18} className="nav-item--icon" />,
    role: "creator",
  },

  {
    path: "/newsList",
    icon: <BsNewspaper size={18} className="nav-item--icon" />,
    role: "creator",
  },
  {
    path: "/news",
    icon: <BsNewspaper size={18} className="nav-item--icon" />,
    role: "editor",
  },
  {
    path: "/uploaded-image",
    icon: <FiImage size={18} className="nav-item-icon" />,
    role: "uploader",
  },
];

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useAuth();
  const { user, selectedLanguage, setSelectedLanguage } =
    useContext(LoginContext);
  const [activeRoute, setActiveRoute] = useState<IRoute>({} as IRoute);
  const [logOutModalOpened, setLogOutModalOpened] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    setActiveRoute(matchRoute(location.pathname));
  }, [location]);
  useEffect(() => {
    i18next.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  const setLanguagevalue = (lang: string) => {
    setSelectedLanguage(lang);
    localStorage.setItem("language", lang);
  };
  return (
    <React.Fragment>
      <MantineNavBar
        className="bg-blue-700 top-0 h-full text-white"
        width={{ base: 320 }}
        withBorder={false}
        p="md"
      >
        <MantineNavBar.Section className="">
          <>
            <h3>Headlines App</h3>
            <p className="font-medium opacity-60">{t("Admin Panel")}</p>
          </>
        </MantineNavBar.Section>
        <MantineNavBar.Section grow>
          <div className="user">
            <div className="avatar">
              {user?.first_name?.charAt(0).toUpperCase()}
            </div>
            <div className="flex-1">
              <p className="font-bold -mb-1 capitalize">
                {`${user?.first_name} ${user?.last_name || ""}`}
              </p>
              <small className="opacity-60 font-medium capitalize">
                {t(user?.role || "")}
              </small>
            </div>
            <button onClick={() => setLogOutModalOpened(true)}>
              <FiLogOut size={20} />
            </button>
          </div>
          <div className="nav-container">
            {ROUTES.ALL_ROUTES.map((each, index) =>
              each.icon ? (
                <NavItem
                  key={index + "route"}
                  label={each.label}
                  isDisabled={!each.roleAllowed?.includes(user?.role || "")}
                  icon={<each.icon size={20} />}
                  isActive={activeRoute?.path === each.path}
                  handleOnClick={() => {
                    each.roleAllowed?.includes(user?.role || "") &&
                      navigate(each.path);
                  }}
                />
              ) : (
                <></>
              )
            )}
            {/* <NavItem
            label={"Image Repository"}
            icon={<BsImages size={20} />}
            isActive
            onClick={function (): void {
              throw new Error("Function not implemented.");
            }}
          />
          <NavItem
            label={"News Items"}
            icon={<BsNewspaper size={20} />}
            isDisabled
            onClick={function (): void {
              throw new Error("Function not implemented.");
            }}
          />
          <NavItem
            label={"Create News"}
            icon={<BsPlusCircleFill size={20} />}
            isDisabled
            onClick={function (): void {
              throw new Error("Function not implemented.");
            }}
          /> */}
          </div>
        </MantineNavBar.Section>
        <MantineNavBar.Section>
          <>
            {/* <Select
              className=" text-center mb-6 w-36 justify-center "
              // label={t("Select Language")}
              radius="sm"
              placeholder=" . Pick one"
              rightSection={<GrLanguage className="text-black " size={25} />}
              rightSectionWidth={30}
              styles={{ label: { color: "#ffff11" } }}
              value={selectedLanguage}
              data={["en", "hindi", "punjabi"]}
              onChange={(value) => {
                console.log(value);
                setLanguagevalue(String(value));
              }}
            /> */}

            <div className="font-medium opacity-60">
              <small>{t("Admin Panel")} v1.0 </small>
            </div>
          </>
        </MantineNavBar.Section>
        {/* <div className="h-full flex flex-col items-center justify-between py-6">
        <Image src={logo} width={32} height={32} />
        <div className="nav">
          {navLinks
            .filter((a) => a.role === user?.role)
            .map((a) => (
              <NavLink
                to={a.path}
                className={({ isActive }) =>
                  (isActive ? "active" : "") + " nav-item"
                }
              >
                {a.icon}
              </NavLink>
            ))}
        </div>
        <button onClick={() => logout()}>
          <div className="user">
            <h1>R</h1>
          </div>
        </button>
      </div> */}
      </MantineNavBar>

      <Modal
        opened={logOutModalOpened}
        onClose={() => setLogOutModalOpened(false)}
        size={"xs"}
        centered
        withCloseButton={false}
      >
        <div className="">
          <div className="">
            {/* <div className="w-16 h-16 bg-red-50 inline-flex items-center justify-center rounded-full p-4">
              <FiLogOut size={48} className="text-red-500" />
            </div> */}
            <div>
              <h3 className="mb-3">{t("Logout")}</h3>
              <p className="opacity-70">
                {t("Are you sure you want to logout?")}
              </p>
            </div>
          </div>
          <div className="mt-6 border-t pt-3 flex justify-end">
            <Button
              size="md"
              className="bg-blue-700 "
              onClick={() => {
                logout();
                setLogOutModalOpened(false);
              }}
            >
              {t("Logout")}
            </Button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default Navbar;
