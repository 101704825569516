import { useEffect } from 'react';
import React, { useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { changeStatus, getAllCategories, getAllLanguage, getBadgedataCount, getAllNews, getNewsTags, getNewsByCreatorId, getBadgedataCountForCreatorID } from '../../../utils/api/api';
import { LoginContext } from '../../../utils/context/AppContext';
import { SelectedNewsContext } from '../../../utils/context/SelectedNewsContext';
import { IBadgeCount, ILanguage, InewsDetail, ISelectedNews, ITags } from '../../../utils/types';
import { showToast } from '../../../utils/utility';

export default function useCreatorNews() {

    const { t } = useTranslation();
    const { user } = useContext(LoginContext);
    const { handleSetSelecetdNews } = useContext(SelectedNewsContext);

    const [comment, setComment] = useState<string[]>([])
    const [opened, setOpened] = useState(false);
    const [checkedValue, setCheckedValue] = useState<boolean>(false);
    const [imageModal, setImageModal] = useState<boolean>(false);
    const [textArea, setTextArea] = useState<string>("")
    const [allImages, setAllImages] = useState<any[]>([]);
    const [allNewsByCreator, setAllNewsByCreator] = useState<any[]>([]);
    const [filterQuery, setFilterQuery] = useState<string>("");
    // const [selectedNewsByTitle, setSelectedNewsByTitle] = useState<InewsDetail[]>( []  );
    const [filteredData, setFilteredData] = useState<InewsDetail[]>([]);
    const [isFiltered, setIsFiltered] = useState<boolean>(false);
    const [imagesLoading, setImagesLoading] = useState<boolean>(false);
    const [selectedIndex, setSelectedIndex] = useState<number>(-1);
    const [selectedLanguage, setSelectedLanguage] = useState<number>(1);
    const [allLanguage, setAllLanguage] = useState<ILanguage[]>([]);
    const [badgeCount, setBadgeCount] = useState<IBadgeCount>({
        allNewsCount: 0,
        pendingCount: 0,
        approvedCount: 0,
        rejectedCount: 0,
    });
    const [availableTags, setAvailableTags] = useState<ITags[]>([]);
    const [allImageTitle, setAllImageTitle] = useState<string[]>([]);
    const [searchValue, setSearchValue] = useState<string>("");

    const [selectedFilter, setSelectedFilter] = useState<
        "All" | "Pending" | "Approved" | "Rejected"
    >("All");
    const [approvedOrReject, setApprovedOrReject] = useState<string>("");

    const LIMIT = 100;
    const [page, setPage] = useState<{
        totalAvailablePages: number;
        currentPage: number;
    }>({
        totalAvailablePages: 0,
        currentPage: 1
    });


    useEffect(() => {
        handleFilterNewsBasedOnNewsTitle()
    }, [filterQuery,])



    // HANDLER FUNCTIONS
    const handleFilterNewsBasedOnNewsTitle = () => {
        if (filterQuery.length >= 2) {
            setIsFiltered(true);
            const filtered = allImages?.filter(each => each.name?.toLowerCase()?.includes(filterQuery?.toLowerCase()));
            // console.log("Filtered schools: ", filtered, " Query: ", filterQuery);
            setFilteredData(filtered);
        }
    };

    const selectedNews: ISelectedNews = useMemo(() => {
        if (filteredData.length > 0) {
            return filteredData[selectedIndex];
        } else return allImages[selectedIndex];
    }, [filteredData, selectedIndex, selectedFilter]);

    // const handleImageSearchBasedOnSelectedTags = (tagList: string[]) => {
    //   let query = tagList;
    //   let filtered = allImages?.filter((img) => {
    //     let tg = img.tags;
    //     // ?.map((t) => t.toLowerCase());
    //     let n = tg?.filter((t: any) =>
    //       query.some((q) => q.toLowerCase() === t.toLowerCase())
    //     );
    //     return n?.length && img;
    //   });
    //   setFilteredData(filtered);
    // };

    const handleNewsSearchBaseOnNewsTitle = () => {
        let filterTitle = allImages?.filter((e) => e.title === searchValue);
        setFilteredData(filterTitle);
    };

    const getLanguageFromLocal = () => {
        const localStoredLanguage = localStorage.getItem("newsLanguage");
        setSelectedLanguage(Number(localStoredLanguage));
    };

    const handelAllTitle = async () => {
        setAllImageTitle(allImages.map((e) => e.title));
    };

    const handleResponseForNewsList = (
        data: any,
        selectedPage: number,
        hasFilterChanged?: boolean
    ) => {
        // format and set data
        const newsList = data?.data || [];
        // const oldNewsList = hasFilterChanged ? [] : JSON.parse(JSON.stringify(allImages));
        // const combinedNewsList = [...oldNewsList, ...newsList];
        // setAllImages(combinedNewsList || []);
        setAllImages(newsList);

        // set current page, increment by 1
        setPage(prev => ({ ...prev, currentPage: selectedPage ?? prev.currentPage + 1 }));

        // calculate and set total numbe rof available pages
        const totalPages = Math.ceil((data?.meta?.filter_count || 1) / LIMIT);
        setPage(prev => ({ ...prev, totalAvailablePages: totalPages }));
    };

    // API CALLS
    const handleFetchTags = () => {
        getNewsTags().then(
            (response) => {
                // console.log("Response for tags: ", response.data?.data);
                setAvailableTags(response.data?.data);
            },
            (error) => {
                console.log("Error for tags fetch: ", error?.response);
                showToast({
                    type: "warning",
                    title: t("Something Went Wrong!"),
                    message: t("Could not fetch tags"),
                });
            }
        );
    };

    const handleFetchAllCategories = () => {
        getAllCategories().then(
            (response) => {
                // console.log("Response for tags: ", response.data?.data);
                // setAvailableTags(response.data?.data);
            },
            (error) => {
                console.log("Error for tags fetch: ", error?.response);
                showToast({
                    type: "error",
                    title: t("Something Went Wrong!"),
                    message: t("Could not fetch tags"),
                });
            }
        );
    };

    const handleFetchLanguage = () => {
        getAllLanguage().then(
            (response) => {
                setAllLanguage(response.data?.data || []);
            },
            (error) => {
                showToast({
                    type: "warning",
                    title: "Fetch Language failed!",
                    message: "could not fetch Language data",
                });
            }
        );
    };

    const handleFetchNews = async (
        status: string,
        selectedPage: number,
        hasFilterChanged?: boolean
    ) => {

        try {

            setImagesLoading(true);
            if (user) {
                if (!checkedValue) {
                    const id = user.id;
                    const localStoredLanguage = localStorage.getItem("newsLanguage");
                    const res = await getAllNews(
                        localStoredLanguage ? Number(localStoredLanguage) : selectedLanguage,
                        status,
                        LIMIT,
                        hasFilterChanged ? 1 : selectedPage ? selectedPage : page.currentPage
                    );
                    // console.log("status",status,"language",selectedLanguage)
                    if (res?.data) {
                        handleResponseForNewsList(res.data, selectedPage, hasFilterChanged);
                    };
                } else {
                    const id = user.id;
                    const localStoredLanguage = localStorage.getItem("newsLanguage");
                    const res = await getNewsByCreatorId(
                        localStoredLanguage ? Number(localStoredLanguage) : selectedLanguage,
                        status,
                        id,
                        LIMIT,
                        hasFilterChanged ? 1 : selectedPage ? selectedPage : page.currentPage
                    );
                    // console.log("status",status,"language",selectedLanguage)
                    if (res?.data) {
                        handleResponseForNewsList(res.data, selectedPage, hasFilterChanged);
                    };
                };
            };
            setImagesLoading(false);

        } catch (error) {
            showToast({
                message: t("Error ."),
                type: "success",
            });
            console.log("error getting images", error);
        };

    };
    // const handleFetchNewsByCreatorId = async (status: string) => {
    //     try {
    //         setImagesLoading(true);
    //         if (user) {
    //             const id = user.id;
    //             const localStoredLanguage = localStorage.getItem("newsLanguage");
    //             const res = await getNewsByCreatorId(localStoredLanguage ? Number(localStoredLanguage) : selectedLanguage, status, id);
    //             // console.log("status",status,"language",selectedLanguage)
    //             if (res?.data) {
    //                 console.log("News by creator Id", res?.data?.data)
    //                 setAllNewsByCreator(res?.data?.data)
    //             }
    //         }
    //     } catch (error) {
    //         showToast({
    //             message: t("Error ."),
    //             type: "success",
    //         });
    //         console.log("error getting images", error);
    //     }
    //     setImagesLoading(false);
    // };

    const handleFetchNewsCountBasedOnStatus = async () => {
        try {
            if (user) {
                const id = user.id;
                if (!checkedValue) {
                    getBadgedataCount("all", selectedLanguage)
                        .then((res) => {
                            let data = res?.data?.data;
                            data.map((e: any) => {
                                setBadgeCount((prev) => ({ ...prev, allNewsCount: e.count }));
                            })

                        })

                    getBadgedataCount("pending", selectedLanguage)
                        .then((res) => {
                            let data = res?.data?.data;
                            data.map((e: any) => {
                                setBadgeCount((prev) => ({ ...prev, pendingCount: e.count }));
                            });
                        })

                    getBadgedataCount("approved", selectedLanguage)
                        .then((res) => {
                            let data = res?.data?.data;
                            data.map((e: any) => {
                                setBadgeCount((prev) => ({ ...prev, approvedCount: e.count }));
                            });
                        })

                    getBadgedataCount("rejected", selectedLanguage)
                        .then((res) => {
                            let data = res?.data?.data;
                            data.map((e: any) => {
                                setBadgeCount((prev) => ({ ...prev, rejectedCount: e.count }));
                            });
                        })
                }
                else {

                    getBadgedataCountForCreatorID("all", selectedLanguage, id)
                        .then((res) => {
                            let data = res?.data?.data;
                            data.map((e: any) => {
                                setBadgeCount((prev) => ({ ...prev, allNewsCount: e.count }));
                            })

                        })

                    getBadgedataCountForCreatorID("pending", selectedLanguage, id)
                        .then((res) => {
                            let data = res?.data?.data;
                            data.map((e: any) => {
                                setBadgeCount((prev) => ({ ...prev, pendingCount: e.count }));
                            });
                        })

                    getBadgedataCountForCreatorID("approved", selectedLanguage, id)
                        .then((res) => {
                            let data = res?.data?.data;
                            data.map((e: any) => {
                                setBadgeCount((prev) => ({ ...prev, approvedCount: e.count }));
                            });
                        })

                    getBadgedataCountForCreatorID("rejected", selectedLanguage, id)
                        .then((res) => {
                            let data = res?.data?.data;
                            data.map((e: any) => {
                                setBadgeCount((prev) => ({ ...prev, rejectedCount: e.count }));
                            });
                        })
                }
            }
        } catch (error) {
            console.log(error)
            showToast({
                message: "Could not fetch Count data",
                type: "error",
                title: "count error",
            });
        }

    };



    return {
        selectedLanguage,
        allLanguage,
        badgeCount,
        allImages,
        allNewsByCreator,
        handelAllTitle,
        filterQuery,
        setFilterQuery,
        searchValue,
        setSearchValue,
        allImageTitle,
        imagesLoading,
        filteredData,
        handleFilterNewsBasedOnNewsTitle,
        selectedNews,
        selectedFilter,
        setSelectedFilter,
        setSelectedIndex,
        handleNewsSearchBaseOnNewsTitle,
        handleFetchNewsCountBasedOnStatus,
        handleFetchNews,
        getLanguageFromLocal,
        handleFetchLanguage,
        opened,
        setOpened,
        checkedValue,
        setCheckedValue,
        imageModal,
        setImageModal,
        isFiltered,
        page,
        setPage
    };
}